@import '../../sass/colors.scss';

  .sliderContainer {
    margin: auto;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mainContainer {
    display: flex;
    align-items: center;

    .leftArrow {
      width: 48px;
      height: 48px;
      object-fit: contain;
      cursor: pointer;
      margin-right: 42px;
      
      >div {
        width: 40px;
        height: 30px;
        background-image: url('./asset/right_caret.svg');
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
        transform: rotate(180deg)
      }
    }

    .rightArrow {
      width: 48px;
      height: 48px;
      object-fit: contain;
      cursor: pointer;
      margin-left: 42px;

      >div {
        width: 40px;
        height: 30px;
        background-image: url('./asset/right_caret.svg');
        background-repeat: no-repeat;
        background-position: center;
        object-fit: contain;
      }
    }
  }

  .slider {
    width: 850px;
    display: flex;
    justify-content: center;
    
    .mainImage {
      width: 630px;
      height: 450px;
      object-fit: center;
      margin: auto;
    }
  }

  .thumbnailContainer {
    margin-top: 56px;
    margin-bottom: 56px;
    width: 580px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >img {
      margin-right: 16px;
      margin-bottom: 8px;
    }
  }

  .thumbnail {
    width: 80px;
    height: 80px;
    opacity: 0.5;
    cursor: pointer;
  }

  .selectedThumbnail {
    width: 80px;
    height: 80px;
    border: solid 2px $eg-white;
  }

  .indexNumber {
    margin-top: 24px;
    font-size: 20px;
    line-height: 1.2;
    color: $eg-white;
  }

