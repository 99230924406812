@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  background-color: $eg-white;
  height: 100%;
  .listContainer {
    margin: 64px auto;
    width: 630px;
    height: 100%;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.33;
      color: $eg-black;
    }

    .list {
      margin-top: 30px;

      .restaurantRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        width: 100%;
        border-bottom: 1px solid $eg-light-gray;
        font-size: 16px;

        .restaurantName {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          color: $eg-black;

          &>.totalStores {
            font-size: 14px;
            line-height: 1.5;
            color: $eg-dark-gray;
          }
        }

        &>.actionButton {
          border: 0px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 0.25px;
          color: $eg-dark-aqua;
        }
      }

      .spinner {
        margin-top: 30px;
      }
    }

    .search {
      margin-top: 30px;
    }

    .spinnerWrapper {
      margin-top: 40px;
    }
  }

  // responsiveness
  @include breakpoint-tablet {
    .listContainer {
      width: 90%;
      margin: 56px auto;
      padding-top: 48px;
    }
  }
}
