.eg-addons-image-preview {
  position: relative;
  border-radius: 4px;

  .delete {
    position: relative;
    z-index: 10;
    display: none;

    >div:nth-child(1) {
      position: absolute;
      top: 11px;
      left: 5px;
      right: 5px;
      height: 2px;
      transform: rotate(45deg);
      background-color: #fff;
    }

    >div:nth-child(2) {
      position: absolute;
      top: 11px;
      left: 5px;
      right: 5px;
      height: 2px;
      transform: rotate(-45deg);
      background-color: #fff;
    }
  }

  &:hover {
    cursor: pointer;

    .dark-background, .delete {
      display: block;
    }
  }

  .img {
    width: 24px;
    height: 24px;
    background-size: cover;
    border-radius: 4px;
  }

  .dark-background {
    display: none;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    &.uploading {
      display: block;
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
  }

  .loader {
    position: absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}
