.eg-addons-triggers-sms {
  display: flex;

  .item {
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #c1c9d1;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;

    &.image { background-image: url(./assets/picture.svg); }
    &.customfields { background-image: url(./assets/customfields.svg); }
  }
}

