.eg-button-group {
  & > .button {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    visibility: hidden;
  }
  & > .label {
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    background-color: $eg-pale-gray;
    color: $eg-gray;
    border: solid 2px $eg-pale-gray;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.2s ease-out;
  }
  & > .label:hover {
    color: $eg-black;
  }
  & > .label:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  & > .label:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & > .button:checked + .label {
    background-color: $eg-white;
    color: $eg-black;
    cursor: default;
  }

}
