@import '../../../../sass/colors';

.profileCardContainer {
  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: $eg-dark-gray;
    margin-top: 10px;
  }

  .addItemWrapper {
    padding: 16px;
    border: 1px dashed $eg-pastel-aqua;
    border-radius: 4px;
    padding-left: 16px;
    margin-top: 20px;
    cursor: pointer;
  }
}
