.eg-textarea-addon-trigger-customfields {
  position: relative;

  .menu {
    position: absolute;
    top: 10px;
    left: 10px;
    min-width: 120px;
    background-color: #fff;
    padding: 0;
    border: 1px solid #F0F3F7;
    border-radius: 4px;
    z-index: 100;

    >li {
      display: flex;
      align-items: center;
      list-style: none;
      height: 33px;
      padding: 0px 16px;
      font-size: 14px;

      &:hover {
        background-color: #f0f3f7;
        cursor: pointer;
      }
    }
  }
}
