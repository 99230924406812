.eg-oauth-callback {
  text-align: center;
  padding-top: 40px;

  .gbusiness {
    width: 80px;
    height: 80px;
    background-image: url(../../components/Integrations/assets/gbusiness.jpg);
    background-size: cover;
    margin: 0 auto;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
  }

  .description {
    margin-top: 20px;
  }
}
