.eg-status-bubble {
  display: inline-block;

  >.content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 4px 8px 4px 0;
    line-height: 21px;
    border-radius: 4px;

    .indicator {
      border-radius: 50%;
      top: 0;
      left: 0;
      width: 11px;
      height: 11px;
      margin: 0 10px;
    }

    &.active {
      background-color: #d5f2f0;

      .indicator {
        background-color: #3ab5ab;
      }
    }

    &.inactive {
      background-color: #fff2d1;

      .indicator {
        background-color: #ffde8b;
      }
    }
  }

}
