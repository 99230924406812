@import '../../../sass/mixins';

.eg-segment-create {
  .body {
    border-radius: 8px;

    label {
      color: #162533;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.25px;
    }

    margin: 28px 80px 24px;

    @include breakpoint-tablet {
      margin: 28px 10px 24px;
    }

    background-color: #fff;

    >.steps-container {
      border-bottom: 1px solid #e1e5e9;
      padding: 20px 48px;
    }

    // common styles for all steps
    .actions {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #e1e5e9;

      .action-button {
        margin: 16px 24px 24px 0;

        &.submit:enabled {
          background-color: #22998f;
        }
      }
    }

    .error-msg {
      color: #e10404;
      font-size: 14px;
      margin-top: 2px;
    }

    .error {
      input {
        border: 1px solid #e10404;
      }
    }

    input, select, textarea {
      font-family: Lato, sans-serif;
      &:focus {
        border: 1px solid #22998f;
      }
    }

    .textarea {
      border: 1px solid #e1e5e9;
      border-radius: 4px;
      padding: 12px;
      max-width: 515px;

      &.error {
        border: 1px solid #e10404;
      }

      textarea {
        border: none;
        height: 144px;
        resize: none;
        box-sizing: border-box;
        width: 100%;
      }
    }

    .step-body {
      // padding: 40px 48px 0;

      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
      }

      .subtitle {
        color: #162533;
        font-size: 16px;
        line-height: 26px;
        margin-top: 5px;
        margin-bottom: 24px;
      }
    }
  }
}
