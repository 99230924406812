.eg-phone-preview {
  width: 260px;
  height: 615px;

  .icons {
    background-color: #f4f4f4;
    height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 20px 0;
    font-weight: bold;
    font-size: 16px;

    >.time {
      margin-left: 5px;
      line-height: 0.8;
    }

    >.ios-top-icons {
      background-image: url(./assets/top-icons.svg);
      width: 57px;
      height: 11px;
    }
  }

  p {
    word-break: break-word;
  }
}
