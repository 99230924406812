@import '../settings/typography';

h2 {
  font-size: 32px;
  color: $eg-black;
  font-weight: 600;
  margin: 0;
}

h3 {
  font-size: 24px;
  color: $eg-black;
  margin: 0;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  color: $eg-dark-gray;
}

textarea {
  font-family: $font-family;
  font-size: 16px;
}
