@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.eg-dashboard-card {
  height: 218px;
  width: 360px;
  border-radius: 6px;
  padding: 28px;
  background-color: $eg-white;
  margin-right: 24px;
  margin-bottom: 24px;
  @include breakpoint-tablet {
   margin: 0px;
   margin-top: 16px;
   width: 100%;
  }

  >.title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: $eg-black;
    @include breakpoint-tablet {
      font-size: 18px;
      line-height: 28px;;
    }
  }

  .flex-hz {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  >.value {
    margin-top: 16px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0px;
    color: $eg-black;
  }

  .total-label {
    margin-top: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-align: left;
    text-transform: uppercase;
    color: $eg-black;
  }

  .total {
    margin-top: 10px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: $eg-black;
  }
}

