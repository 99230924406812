@import '../../../sass/colors.scss';

.eg-campaign-dashboard {

  >.loader {
    margin-top: 200px;
  }

  .body {
    margin: 28px 80px 24px;
    background-color: #fff;
  }

  >.content {
    margin: 28px 80px 24px;
    background-color: #fff;
    padding: 40px;
    min-height: 630px;

    .chart-loader {
      margin-top: 200px;
      margin-bottom: 180px;
    }
  }

  >.content >.top-row {
    display: flex;
    justify-content: space-between;
  }

  >.content >.top-row >.left-part {
    display: flex;
  }

  >.content >.top-row >.left-part >.title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    color: $eg-black;
    margin-right: 24px;
  }

  >.content >.top-row >.right-part >.action-button{
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: $eg-black;
    text-transform: uppercase;
    height: 32px;
    width: 168px;
    padding: 8px 0px;
  }

  >.content >.time {
    margin-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-dark-gray;
  }

  >.content >.stat-row {
    margin-top: 40px;
    display: flex;
  }

  >.content >.graph {
    background: #FFFFFF;
    border: 1px solid #E1E5E9;
    box-sizing: border-box;
    border-radius: 6px;
    min-height: 320px;
    margin-top: 32px;
  }

  >.content >.eg-city-platform-stat {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
}

.campaign-message-modal {
  max-width: 484px;

  .eg-campaign-message {
     .title {
      margin: 20px 24px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    .content {
      background: #BFECE8;
      border-radius: 0px 0px 8px 0px;
      padding: 44px 64px;
      display: flex;
      justify-content: center;
      align-items: center;
       .preview {
        background-color: #bfece8;

        .phone {
          margin: 0 auto;
          position: relative;
          width: 298px;
        }

        .container {
          margin: 0;
          padding: 0;
          overflow-y: scroll;
          display: block;
          height: 600px;

          .message-wrapper-img {
            border-radius: 12px;
            margin-left: 12px;
            margin-top: 17px;

            >img {
              border-radius: 12px;
            }
          }

          .message-wrapper {
            font-family: 'SF Pro Text';
            width: 300px;
            background-color: #e8e8e8;
            border-radius: 12px;
            padding: 15px;
            margin-left: 12px;
            margin-top: 17px;

            p {
              font-size: 16px;
              font-weight: 500;
              color: #000;
              line-height: 1.4;

              &.link {
                color: #2585c5;
                text-decoration: underline;
                overflow-wrap: anywhere;
              }
            }
          }
        }
      }
    }
  }
}
