@import '../../sass/colors';

/**
* override calendar css classes
*/

.fc {
  background-color: #fff;
  border: 1px solid $eg-pale-gray;
  padding: 28px 24px;

  table {
    font-size: 12px;
  }

  .fc-content {
    display: flex;

    .fc-title {
      margin-left: 10px;
    }
  }

  .fc-widget-content {
    // TODO: handle styles depending on the current view
    // border-top: 0 hidden;
  }

  .fc-head-container.fc-widget-header {
    border-bottom: 0 hidden;
  }

  .fc-timeGridDay-view .fc-head-container.fc-widget-header {
    border-bottom: 1px solid $eg-light-gray;
  }

  /***** arrow buttons *****/
  .fc-left {
    display: flex;

    .fc-icon {
      outline: none;
      &::before {
        background-image: url(./assets/caret-left.svg);
        width: 32px;
        height: 32px;
        display: block;
        background-repeat: no-repeat;
        margin-top: 4px;
        content: " ";
      }

      &.fc-icon-chevron-right::before {
        transform: rotate(180deg);
        margin-top: -4px;
      }

      &.fc-icon-chevron-left {
        margin-left: 20px;
      }
    }
  }

  .fc-button-primary {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: #c3c9d0;
    font-size: 20px;
    font-weight: 300;

    &:hover, &:focus, &:active {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  .fc-button-primary:not(:disabled):active:focus,
  .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
  }

  .fc-time-grid .fc-slats .fc-minor td {
    border-top-style: none;
  }

  /***** view-switch buttons *****/
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-timeGridDay-button {
    border: none;
    border-radius: 0;
    margin-right: 20px;
    text-transform: capitalize;
    font-size: 14px;
    color: $eg-gray;
    padding-bottom: 8px;

    &:active {
      color: $eg-gray;
    }

    &.fc-button-active {
      background-color: transparent;
      color: $eg-black;
      font-weight: 600;
      padding-bottom: 5px;
      border-bottom: 3px solid $eg-dark-red;
    }
  }

  .fc-day-header {
    text-align: left;
    color: $eg-black;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-bottom: 0 hidden;
    padding: 10px;
  }

  .fc-time-grid .fc-slats td {
    height: 24px;
  }

  .fc-week-daynumber {
    font-size: 16px;
  }

  .fc-timeGridWeek-view {
    .fc-day-header {
      padding: 10px 10px 22px;
    }
  }

  /***** day view *****/

  .fc-timeGridDay-view {
    .fc-day-header {
      vertical-align: middle;
      padding-left: 10px;
      text-align: left;
      height: 80px;
    }

    .fc-axis {
      width: 78px!important;
      border: none;
      text-align: center;
    }

    .fc-widget-content {
      border-top: 1px solid $eg-light-gray;
    }
  }
}

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: left;
  padding: 0 10px 10px;
}

.fc-ltr .fc-axis {
  // had to do this, since fullcalendar sets
  // a fixed width within an inline style :(
  width: 80px !important;
  text-align: center;
}
