@import '../../../sass/colors';

.eg-number-activity {
  padding: 10px 30px 30px;

  .calls {
    height: calc(100vh - 270px);
    border-bottom: 1px solid $eg-light-gray;
    overflow-y: scroll;
  }

  .emptyview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    .image {
      width: 56px;
      height: 56px;
      background-image: url(./assets/phone.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $eg-light-gray3;
      border-radius: 50%;
    }

    .title {
      margin-top: 29px;
      font-size: 20px;
      font-weight: bold;
    }

    .description {
      margin-top: 19px;
      color: $eg-dark-gray;
      font-size: 14px;
    }
  }

  .spinner {
    margin-top: 30px;
  }
}
