.eg-msg-new-node {
  display: flex;
  padding-left: 3px;

  >.icon {
    width: 36px;
    height: 36px;
    background-image: url(./assets/add.svg);
    background-repeat: no-repeat;
  }

  .btn-add {
    width: 131px;
    height: 32px;
    color: #3ab5ab;
    border: 1px solid #3ab5ab;
    font-size: 12px;
    font-weight: 700;
    padding: 9px 0;
    margin-left: 24px;
  }
}
