@import '../../sass/colors';
@import '../../sass/mixins';

.filterContainer {
  position: relative;

  .filterTrigger {
    display: flex;
    align-items: center;
    width: auto;
    height: 40px;
    border-radius: 4px;
    border: solid 1px $eg-light-gray;
    padding: 0 15px;
    font-size: 14px;
    background-color: $eg-white;
    cursor: pointer;

    &.active {
      border: solid 1px $eg-dark-aqua;
      background-color: $eg-dark-aqua;
      color: $eg-white;
      font-weight: 600;
    }
  }

  .greenBackground {
    border: solid 1px $eg-dark-aqua;
    background-color: $eg-dark-aqua;
    color: $eg-white;
    font-weight: 600;
  }

  .filterBody {
    display: none;
    position: absolute;
    min-width: 240px;
    box-shadow: 0 4px 16px 0 rgba(44, 44, 44, 0.15);
    background-color: $eg-white;
    margin-top: 16px;
    border-radius: 4px;
    z-index: 100;

    &.active {
      display: block;
    }

    .filterItem {
      display: flex;
      align-items: center;
      padding: 10px 16px;

      &.singleFilterItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
        background-color: $eg-fade;
      }

      .itemWrapper {
        margin-left: 10px;
      }
    }

    .filterFooter {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $eg-pale-gray;
      height: 40px;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 16px;

      .btnPrimary {
        color: $eg-green;

        &.applyDisabled {
          color: $eg-gray;
          cursor: auto;
        }
      }

      .btnPrimary, .btnSecondary {
        cursor: pointer;
      }
    }
  }

  @include breakpoint-mobile {
    position: unset;

    .filterBody {
      left: 5%;
      width: 90%;
    }
  }

}
