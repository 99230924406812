.eg-chart-group {
  display: grid;
  grid-template:
    'title datepicker' auto
    'labels filter' auto
    'chart chart' 300px / 1fr 1fr;
  border-radius: 4px;
  padding: 16px;
  border: solid 1px $eg-light-gray;
  margin: 20px;
  max-width: 1024px;
  max-height: 400px;
  & > .title { grid-area: title; }
  & > .datepicker { grid-area: datepicker; }
  & > .labels {
    grid-area: labels;
    font-size: 16px;
    font-weight: 600;
  }
  & > .filter {
    grid-area: filter;
    justify-self: end;
  }
  & > .chart {
    grid-area: chart;
  }
}
