.eg-section-header {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 24px 30px;
  box-shadow: 0 1px 0 0 #f2f2f2;
  justify-content: space-between;

  .title {
    font-size: 20px;
    font-weight: 600;
  }
}
