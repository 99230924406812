@import '../../sass/breakpoints';
@import '../../sass/colors';

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 384px;
  float: right;
  margin-top: 40px;

  .loginBtn {
    width: 100%;
  }

  @media (max-width: #{$breakpoint-mobile}) {
    flex-direction: column-reverse;
    width: 100%;
  }

  button {
    @media (max-width: #{$breakpoint-mobile}) {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.linkLink {
  top: 0;
  right: 0;
  text-decoration: none;
  font-size: 14px;
  z-index: 1;
  float: right;
  padding-top: 10px;
  color: #373835;
  font-weight: 600;
  text-decoration: underline;
}

.textField {
  margin-bottom: 20px;
}

.passwordWrapper {
  position: relative;

  .resetPasswordLink {
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    color: $eg-light-green;
    font-size: 14px;
    z-index: 1;
  }
}
