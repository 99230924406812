@import '../../../sass/colors';

.eg-number-settings {
  width: 496px;
  padding-top: 72px;
  margin: 0 auto;

  >.title {
    font-size: 20px;
    font-weight: 600;
  }

  >.description {
    font-size: 14px;
    color: $eg-dark-gray;
    margin-top: 16px;
  }

  >.fwd-number {
    height: 48px;
    display: flex;
    align-items: center;
    background-color: #f0f3f7;
    margin-top: 10px;
    font-size: 14px;
    border-radius: 4px;

    &::before {
      content: "+1";
      margin-right: 20px;
      margin-left: 16px;
    }
  }

  >.separator {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #eaeaea;
    margin: 24px 0;
  }
}
