@import '../../../../../sass/colors';
.eg-loyalty-signup-page {
  display: flex;

  &.hidden {
    display: none;
  }

  >.form-wrapper {
    padding: 40px 60px;
    width: 50%;

    >.message {
      background-color: #d5f2f0;
      padding: 17px;
      border-radius: 8px;
      margin: 40px 0 32px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;

      span {
        display: block;
        margin-top: 16px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        color: #34a39a;
        cursor: pointer;
      }
    }

    >.title {
      font-size: 24px;
      font-weight: bold;
    }

    >.form {
      position: relative;
      margin-top: 20px;

      .disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: #fff;
        opacity: 0.5;
      }

      &.disabled {
        input {
          background-color: #f8fafc;
          color: #6b7681;
          border: 1px solid #a4aeb6;
        }
      }

      >.logo {
        background-image: none;

        .btn-update {
          width: 104px;
          height: 40px;
          padding: 11px 0;
        }
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: 10px;
        display: block;
      }

      .textarea-wrapper {
        >div {
          padding: 0;
        }

        .textarea {
          height: 100px;
          background-color: #fff;
        }
      }

      .field {
        margin-bottom: 24px;
      }

      .error-msg {
        color: $eg-red;
      }

      .error {
        input {
          border: 1px solid $eg-red;
        }
      }
      
      textarea.error {
        border: 1px solid $eg-red;
      }
    }
  }

  >.preview {
    background-color: #bfece8;
    border-bottom-right-radius: 8px;
    padding-top: 40px;
    width: 50%;

    >.title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    >.phone {
      margin: 40px auto;
    }

    .iphone-font-mini {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
      color: #666;
    }

    .message-wrapper {
      font-family: 'SF Pro Text';
      width: 300px;
      background-color: #e8e8e8;
      border-radius: 12px;
      padding: 15px;
      margin-left: 12px;
      margin-top: 17px;

      p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 1.4;

        &.link {
          color: #2585c5;
          text-decoration: underline;
        }
      }
    }
  }
}
