@import '../../../../../../sass/colors.scss';

.zsf-review {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  >.rating-logo {
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 148px;
    height: auto;
    margin-bottom: 24px;
  }

  >.title {
    max-width: 350px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $eg-black;
  }

  >.subtitle {
    max-width: 350px;
    height: 96px;
    margin: 9px 39px 46px 24px;
    font-family: Lato;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $eg-dark-gray;
  }

  // StarRatings container class name
  >.star-ratings {
    margin-bottom: 64px;
  }

  >.submit {
    width: 328px;
    height: 48px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: #2bbfb3;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $eg-white;
    cursor: pointer;
  }

  >.disabled {
    opacity: 0.48;
    background-color: #55ccc2;
    cursor: unset;
  }

  >.powered-by {
    margin-top: 32px;
    font-size: 11px;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: $eg-dark-gray;
    >span {
      text-decoration: underline;
    }
  }
}
