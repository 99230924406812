@import '../../../sass/colors.scss';

.eg-url-shortner-modal {
  max-width: 560px;
  margin-top: 180px;
}

.eg-url-shortner {
  padding: 24px 0px;
  color: $eg-medium-black;

  >.title {
    padding: 0px 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E1E5E9;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }

  >.form {
    padding: 0px 24px;

    >.label {
      margin-top: 24px;
      margin-bottom: 4px;
      text-transform: uppercase;
      color: $eg-black;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.25px;
    }

    >.info {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      margin-bottom: 6px;
    }

    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      padding-top: 56px;
    .action-button {
      // margin: 16px 24px 24px 0;
      margin-left: 16px;
      text-transform: uppercase;

      &.submit {
        width: 292px;
      }

      &.submit:enabled {
        background-color: #22998f;
      }
    }
    }

  }

}
