.eg-msg-trigger-node {
  display: flex;
  justify-content: space-between;

  >.content {
    display: flex;

    >.side-icon {
      width: 44px;
      background-image: url(./assets/trigger.svg);
      background-repeat: no-repeat;
      background-position: center top;
      margin-right: 24px;

      >.vline {
        margin-top: 52px;
        width: 50%;
        height: 42px;
        border-right: 1px solid #c1c9d1;
      }
    }

    >.text {
      >.name {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        color: #162533;
      }

      >.description {
        font-size: 16px;
        color: #162533;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }

  >.action {
    >.btn-edit {
      width: 131px;
      height: 32px;
      color: #3ab5ab;
      border: 1px solid #3ab5ab;
      font-size: 12px;
      font-weight: 700;
      padding: 9px 0;
    }
  }
}
