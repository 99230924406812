.itemField {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;

  .itemContent {
    padding-right: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 170px;
    span {
      font-size: 12px;
      font-weight: 300;
      display: block;
      clear: both;
    }
  }
}

