@import '../../../sass/colors';
@import '../../../sass/main';

.eg-input {
  >.input {
    font-family: $font-family;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $eg-light-gray;
    padding-left: 16px;
    font-size: 16px;

    &::placeholder {
      color: $eg-light-gray3;
    }
  }
   .error-msg {
      color: #e85654;
      font-size: 12px;
    }
}
