@import '../../sass/mixins';

.eg-pagination-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @include breakpoint-mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .size {
    display: flex;
    font-size: 12px;
    align-items: center;

    .dropdown {
      margin-left: 10px;
      width: 94px;
      height: 32px;
      font-size: 12px;

      .eg-select__control {
        width: 121px;
        height: 32px;
      }

      .eg-select__placeholder, .eg-select__single-value {
        top: 14px;
        left: 10px;
        font-size: 12px;
      }

      .eg-select__indicator {
        padding: 3px 8px 8px 0;

        .ddindicator {
          width: 12px;
          height: 12px;
          background-size: cover;
        }
      }
    }
  }

  .sorting {
    margin-top: 30px;
    padding-left: 18px;
  }
}
