@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.eg-interaction {
  position: relative;

  .item {
    display: flex;
    align-items: center;
    width: 300px;
    height: 80px;

    &>.time-wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      margin-right: 18px;
      min-width: 90px;

      @include breakpoint-tablet {
        margin-left: 0px;
        margin-right: 16px;
      }
    }

    &>.time-wrapper >.date {
      color: #162533;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 21px;
      white-space: pre;
      text-align: right;
    }

    &>.time-wrapper >.time {
      font-size: 12px;
      color: #8c99a5;
      font-weight: normal;
      min-width: 60px;
      margin-top: 4px;
      text-align: right;
    }

    & > .logowrapper {
      position: relative;
      width: 50px;
      height: 50px;
      margin-top: 16px;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
    }

    & > .logowrapper > .logo {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: 66.66%;
      background-color: $eg-white;
      background-position: center;
      background-image: none;
      border-radius: 50%;
      z-index: 10;

      &.paytronix { background-image: url(../../../../asset/integration-sources/paytronix.svg); }
      &.square { background-image: url(../../../../asset/integration-sources/square.svg); }
      &.voice { background-image: url(../../../../asset/integration-sources/phone.svg); }
      &.clover { background-image: url(../../../../asset/integration-sources/clover.png); }
      &.zenreach { background-image: url(../../../../asset/integration-sources/zenreach.png); }
      &.doordash { background-image: url(../../../../asset/integration-sources/doordash.png); }
      &.revel { background-image: url(../../../../asset/integration-sources/revel.png); }
      &.zero_storefront { background-image: url(../../../../asset/zsf-logo.svg); }
      &.toast { background-image: url(../../../../asset/integration-sources/toast.png); }
      &.text-privy, &.text { background-image: url(../../../../asset/integration-sources/popup.svg); }
      &.olo { background-image: url(../../../../asset/integration-sources/olo.svg); }
      &.mailchimp { background-image: url(../../../../asset/integration-sources/mailchimp.png); }
    }

    & > .logowrapper::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: calc(100% + 32px);
      background-color: #c1c9d1;
    }

    .info {

      .title {
        white-space: pre;
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
        color: $eg-black;
      }

      .description {
        font-size: 12px;
        color: #8c99a5;
        text-transform: capitalize;
        margin-top: 5px;
      }
    }
  }
  .item:hover {
    & > .logowrapper >.logo{
      background-color: #F0F3F7;
    }
  }
}
