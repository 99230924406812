@import '../../../../../sass/colors';
@import '../../../../../sass/mixins';

.eg-shopify-connect {
  padding: 40px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .description {
    font-size: 12px;
    color: $eg-dark-gray;
    margin-bottom: 20px;

    span {
      font-style: italic;
    }
  }

  .submit {
    margin-top: 20px;
  }
}
