@import '../../sass/settings/color-palette';
@import '../../sass/tools/a11y';

$footer-vertical-padding: 20px;
$footer-horizontal-padding: 40px;
$item-vertical-padding: 8px;
$item-horizontal-padding: 16px;
$gap-between-links: 40px;

.eg-footer  {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  box-shadow: 0 0.5px 0 0 #d6d6d6, 0 -0.5px 0 0 #c6c7c8;
  padding: #{$footer-vertical-padding - $item-vertical-padding} 12px;
  background-color: $eg-white;

  // TODO: To be replaced with breakpoints mixin after its implementation.
  @media screen and (min-width: 1025px) {
    flex-direction: row;
    padding: #{$footer-vertical-padding - $item-vertical-padding} 40px;
  }

  & > .list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-items: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & > .list > .item + .item {
    margin-left: #{$gap-between-links - 2 * $item-horizontal-padding}
  }

  & > .list > .item > .link {
    display: inline-block;
    text-decoration: none;
    border-radius: 4px;
    padding: $item-vertical-padding $item-horizontal-padding;
    color: $eg-dark-gray;

    @include keyboard-focus {
      color: $eg-black;
      background-color: $eg-pale-gray;
    }
  }

  & > .list > .item > .link:hover {
    color: $eg-black;
  }

  & > .copyright {
    padding: $item-vertical-padding $item-horizontal-padding;
  }
}
