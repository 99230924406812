@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins';

.eg-segment-item {
  display: flex;
  align-items: center;
  padding: 16px 16px;
  min-height: 92px;
  border-bottom: 1px dashed #E1E5E9;
  cursor: pointer;

  &.manual {
    cursor: inherit;
  }

  &:hover {
    background-color: $eg-light-gray;
  }

  >.name {
    width: 40%;
    display: flex;
    align-items: center;
    padding-right: 16px;

    &.clickable {
      cursor: pointer;
    }

    >.icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background: $eg-pale-gray;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url(../../asset/group.svg);
        background-size: cover;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    >.details {
      max-width: 85%;
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0px;
        color: $eg-black;
        text-transform: capitalize;
      }

      >.info {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: $eg-dark-gray;

        span {
          text-transform: capitalize;
        }
      }
    }
  }


  >.options {
    justify-content: flex-end;
    flex-grow: 1;
    display: flex;
    margin-right: 34px;
    width: 25px;
  }


  >.subscriber {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
  }

  >.date-updated {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
  }

  >.date-added {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
  }
}
