.eg-phone-preview-header-browser {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  height: 78px;
  font-size: 15pt;
  font-weight: bold;
  border-bottom: 1px solid #ddd;

  .search-bar {
    width: 90%;
    margin: 0 auto;
    font-family: 'SF Pro Display';
    font-size: 18px;
    font-weight: normal;
    color: #808080;
    background-color: #e6e6e8;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 5px 0;
    height: 35px;
  }
}
