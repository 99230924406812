.eg-textarea-addon-trigger-image {
  .uploader-modal {
    max-width: 560px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 24px;
    border-bottom: 1px solid #e1e5e9;
  }

  .uploader-body {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    >.img {
      width: 88px;
      height: 88px;
      background-image: url(./assets/img-upload.svg);
      background-repeat: no-repeat;
    }

    >.strong {
      margin-top: 24px;
      font-size: 20px;
      font-weight: bold;
    }

    >.description {
      margin-top: 8px;
      font-size: 16px;
      color: #6b7681;
    }

    >.error {
      margin-top: 12px;
      color: #e10404;
    }

    >.button {
      margin: 24px 0 40px;
    }
  }
}
