@import '../../../sass/colors.scss';

.upload-container {
  margin: auto;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>.doc-logo{
    width: 111px;
    height: 111px;
    border-radius: 50%;
    background-color: $eg-pale-gray;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>.doc {
      width: 49px;
      height: 64px;
      background-image: url('../assets/doc.svg')
    }
  }

  &>.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;
    color: $eg-black;
  }

  &>.paragraph {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $eg-dark-gray;
  }

  &>.browse-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 100px;
    width: 144px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: $eg-white;
    background-color: $eg-aqua;
    cursor: pointer;
  }
}

