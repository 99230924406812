@import '../../sass/mixins';

.eg-back-link {
  margin-left: 80px;
  margin-top: 36px;

  .back-link {
    padding-left: 20px;
    background-image: url(./assets/back.svg);
    background-repeat: no-repeat;
    background-position: left center;
  }

  a {
    color: #22998f;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
  }

  @include breakpoint-tablet {
    margin-left: 20px;
  }
}
