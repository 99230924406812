@import '../../../sass/colors';

.container {
  padding: 10px 0;
  width: 100%;

  .label {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: 0.5px;
    color: $eg-black;
    margin-bottom: 9px;
    text-transform: uppercase;
  }

  textarea {
    padding: 5px 15px;
    width: 100%;
    height: 48px;
    min-height: 48px;
    background-color: #fafcfd;
    border-radius: 3px;
    border: 1px solid #dcdedf;
    padding: 12px;
    font-size: 14px;

    &.noResize {
      resize: none;
    }

    &.horizontalResize {
      resize: horizontal;
    }

    &.verticalResize {
      resize: vertical;
    }

    &:focus {
      border-color: $eg-aqua;
    }

    &.error {
      border-color: $eg-red;
      &:focus {
        border-color: $eg-red;
      }
    }
  }

  textarea::placeholder {
    color: #c6c7c8;
    font-weight: 300;
    line-height: 1.6;
  }

  .errorMessage {
    margin-top: 9px;
    color: $eg-red;
  }
}
