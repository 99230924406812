@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.container {
  background-color: #fff;
  color: $eg-black;
  padding: 24px 28px 28px;
  max-width: 288px;
  border: 1px solid $eg-pale-gray;
  border-radius: 3px;

  .title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

@include breakpoint-tablet {
  .container {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
  }
}
