@import '../../../sass/colors';

.eg-tabbar {
  height: 56px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  // border-bottom: 1px solid $eg-light-gray;

  .tab-container {
    padding-left: 31px;
  }

  .tab-item {
    &.tab-selected {
      border-bottom: 4px solid $eg-green;
    }
  }
}
