@import '../../sass/colors';

.addItemContainer {
  display: flex;
  font-size: 16px;
  color: $eg-medium-black;

  .plus {
    background-image: url(./assets/plus-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: $eg-green;
    margin-right: 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
}
