@import '../../sass/colors';
@import '../../sass/mixins';

.eg-stores-container {
  margin: 48px 80px;
  @include breakpoint-tablet {
    padding: 70px 16px;
  }

  &>.title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &>.title-row > h2 {
    color: $eg-black;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
  }

  &>.title-row >.button {
    width: 155px;
    height: 40px;
    border-radius: 4px;
    background-color: $eg-aqua;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $eg-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
  }

  &>.header-wrapper {
    padding: 0px 16px;
    margin-top: 60px;
  }

  &>.header-wrapper >.header-item {
    height: 16px;
    width: 25%;
    color: $eg-dark-gray;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.46px;
    line-height: 16px;
  }
}
