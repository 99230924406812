@import '../../../sass/colors';
@import '../../../sass/mixins';

.eg-export-customer{
  @include breakpoint-mobile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &>.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px $eg-pale-gray;
    background-color: $eg-white;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.25px;
    color: $eg-black;
    cursor: pointer;

    &>.logo {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      background-image: url('./asset/export.svg');
      object-fit: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .eg-export-background {
    display: flex;
    align-items: center;
  }

  .eg-export-madal {
    display: flex;
    width: 504px;
    height: 286px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(44, 44, 44, 0.15);
    border: solid 0.5px $eg-pale-gray;
    background-color: $eg-white;
    
    @include breakpoint-tablet {
      width: 90%;
      height: 50%;
    }    
  }

  .eg-export-madal >.content {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    width: 100%;
  }

  .eg-export-madal >.content >.title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: $eg-black;
    padding-left: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px $eg-pale-gray;
    margin-bottom: 16px;
  }

  .eg-export-madal >.content >.radio-button {
    align-items: center;
    margin-top: 16px;
  }
  .eg-export-madal >.content >.footer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .eg-export-madal >.content >.footer >.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 40px;
    margin-right: 24px;
    border-radius: 4px;
    background-color: #2bbfb3;
    color: $eg-white;
    cursor: pointer;
  }
}
