.container {
  position: relative;

  &:before {
    content: '+1';
    position: absolute;
    top: 22px;
    left: 10px;
    z-index: 10;
  }

  input {
    padding-left: 30px;
  }
}
