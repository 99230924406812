.eg-emoji-picker {
  position: relative;
  width: 24px;

  .trigger {
    cursor: pointer;
    width: 26px;
    height: 26px;
    background-image: url(./assets/smile.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #c1c9d1;
    border-radius: 4px;
  }

  .picker-wrapper {
    position: absolute;
    z-index: 10;
    top: -100px;
    left: 0;

    label {
      margin: 0;
    }
  }
}
