@import '../../sass/colors';

.container {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  padding: 15px;
  border-radius: 3px;

  &.msgWarning {
    background-color: #ffde8b;

    a {
      color: $eg-black;
      text-decoration: underline;
    }
  }

  &.msgSuccess {
    color: #fff;
    background-color: $eg-green;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &.msgAlert {
    background-color: $eg-light-gray;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
