@import '../../../../../sass/colors.scss';

.eg-segments-predicates-date {
  display: flex;
  margin: 0 8px;
  align-items: center;
  .date-input {
    margin-left: 8px;
    input {
      height: 48px;
    }
  }

  .between-and {
    margin: auto 0px;
    text-align: center;
  }
}
