@import '../../../../../../../sass/colors';

.eg-loyalty-customer-form {
  background-color: #f4f6f9;
  border-radius: 8px;
  padding: 23px 16px;

  >.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  > .form {
    width: 100%;
  }

  > .form >.row {
    margin-top: 17px;

    >label {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 8px;
      display: block;
      letter-spacing: 0.5px;
      line-height: 16px;
    }

    > div > input {
      position: relative;
      width: 100%;
      height: 48px;
      border: solid 1.2px $eg-light-gray;
      height: 48px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0);
      padding-left: 16px;
      font-size: 16px;
      line-height: 1.63;
      color: $eg-black;
      background-color: $eg-white;

      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $eg-gray;
      }
    }

    .react-datepicker-wrapper {
      width: 100%
    }

    > div .birthday {
      position: relative;
      width: 100%;
      height: 48px;
      border: solid 1.2px $eg-light-gray;
      height: 48px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0);
      padding-left: 16px;
      font-size: 16px;
      line-height: 1.63;
      color: $eg-black;
      background-color: $eg-white;

      &::placeholder {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $eg-gray;
      }
    }

    > div >.error-boundary {
      border: solid 1px #e56060;
    } 
  
    >.phone {
      > input {
        padding-left: 32px;
      }
    }

    >.error {
      font-size: 12px;
      line-height: 1.17;
      color: #e56060;
      margin-top: 6px;
    }

  }

  > .form >.actions {
    margin-top: 16px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    >.submit {
      border: none;
      width: 100%;
      height: 48px;
      border-radius: 4px;
      background-color: $eg-aqua;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: center;
      color: $eg-white;
      cursor: pointer;
    }
  }

  select{
    font-size: 16px;
    }
}
