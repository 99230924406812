@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  margin: 60px 75px;
  font-size: 14px;
  font-weight: 400;
  min-height: 400px;
  @include breakpoint-desktop-small {
    margin: 60px 40px;
  }

  .reviewsWrapper {
    margin-top: 56px;
  }

  .filtersWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    .reviewFilters {
      display: flex;
      align-items: center;
      > div {
        margin-right: 10px;
      }
      
      .storeFilter {
        height: 40px;
      }
    }

    .searchInput {
      margin-left: 8px;
      flex-grow: 1;
      max-width: 440px;
    }

    .clearAllFilters {
      margin-left: 12px;
      font-size: 12px;
      font-weight: 600;
      color: $eg-dark-gray;
      cursor: pointer;
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .pageSizeWrapper {
      display: flex;
      font-size: 12px;
      align-items: center;

      .pageSizesDropdown {
        margin-left: 10px;
        width: 94px;
        height: 32px;
        font-size: 12px;

        .dropdownSelect {
          height: 32px;
        }
      }
    }
  }

  .exportRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 20px;
    
    .totalReviews{
      color: #030A3F;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .sortingWrapper {
    padding: 20px 0px;
    padding-left: 18px;
    background-color: #fff;
  }
}

.generalEmptyView {
  width: 70%;
  margin: 70px auto;
}

.emptyView {
  width: 100%;
  height: 250px;
  border: 1px solid $eg-pale-gray;
  background-color: #fff;
  display: flex;
  align-items: center;

  .emptyViewContainer {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: 0 auto;;

    .emptyViewTitle {
      font-size: 20px;
      display: block;
      font-weight: 600;
      color: $eg-dark-gray;
      text-align: center;
    }

    p {
      display: block;
      color: $eg-dark-gray;
      font-size: 14px;
      font-weight: normal;
      text-align: center;
    }
  }
}

.spinner {
  margin-top: 200px;
}

.reviewSource {
  width: 25%;
  @include breakpoint-desktop-small {
    width: 20%;
  }
}

.reviewDate {
  width: 15%;
}

.reviewStore {
  width: 15%;
}

.reviewLocation {
  width: 15%;
}

.reviewUser {
  width: 17%;
}

.reviewRate {
  width: 20%;
}

.reviewStatus {
  width: 16%;
}

.reviewActions {
  width: 16%;
}

// responsiveness
@include breakpoint-tablet {
  .container {
    margin: 70px 15px;

    .filtersWrapper {
      width: fit-content;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;

      .reviewFilters {
        margin-top: 8px;
        flex-wrap: wrap;
        > div {
          margin-top: 8px;
        }
      }

      .searchInput {
        margin-left: 0px;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: stretch;
      }
    }

    .sortingWrapper {
      // TODO: check how to display this on mobile
      display: none;
    }

    .reviewsWrapper {
      margin-top: 10px;
      .reviewList{
        background-color: $eg-pale-gray;
      }
    }

    .paginationWrapper {
      flex-direction: column-reverse;
      align-items: center;
    }

    .pagination {
      justify-content: center;
    }
  }

  .generalEmptyView {
    margin-top: 200px;
  }

  .spinner {
    margin-top: 100px;
    margin-bottom: 100px;
    height: 100%;
  }  
}


@include breakpoint-mobile {
  .container {

    .filtersWrapper {
      width: 100%;

      .searchInput {
        max-width: none;
        width: 100%;
      }
    }
  }
}


.sortingArrow {
  margin-top: -3px;
}
