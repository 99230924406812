@import '../../../sass/colors';

.eg-number-details {
  display: flex;
  background-color: $eg-white;
  margin: 32px 80px;
  border-radius: 8px;

  >.numbers {
    width: 240px;
    height: 100vh;
    background-color: $eg-light-gray2;
  }

  .header-number {
    font-size: 16px;
    font-weight: 600;
    display: flex;

    >.status {
      margin-left: 18px;
    }
  }

  .section-wrapper {
    flex-grow: 1;
  }
}
