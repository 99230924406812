@import '../../../../sass/colors';

.storeMainInfoContainer {
  background-color: $eg-white;
  padding-top: 0; // 48px
  padding-bottom: 24px;
  border-radius: 4px;

  .storePicture {
    width: 152px;
    height: 152px;
    background-color: $eg-yellow;
    background-image: url(./assets/profile-placeholder.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .addPhoto{
      color: $eg-white;
      cursor: pointer;
      width: 112px;
      height: 32px;
      border-radius: 4px;
      background-color: $eg-aqua;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;

      &::before {
        width: 12px;
        height: 12px;
        background-image: url(./assets/plus-white.svg);
        background-repeat: no-repeat;
        margin-top: 2px;
        margin-right: 4px;
        content: "   ";
      }
    }
  }

  .storePicture:hover {
    .addPhoto{
      display: flex;
    }
  }

  .storeName {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 17px;
    border-bottom: 1px solid $eg-light-gray;
    padding-bottom: 24px;
    text-transform: capitalize;

    span {
      max-width: 250px;
      display: block;
      margin: 14px auto 0;
    }
  }

  .editableCard {
    padding: 48px 0 0;
  }

  .editButton {
    top: 24px;
  }

  .addItemsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 16px;
    border: 1px dashed $eg-pastel-aqua;
    border-radius: 4px;
    padding-left: 16px;
    margin: 20px auto 0;
    width: 90%;
    cursor: pointer;

    .addItem {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .storeInfoItems {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .itemRow {
      padding: 8px 24px 0 40px;
      background-repeat: no-repeat;
      background-position: left 11px;
      margin-left: 24px;
      margin-bottom: 10px;

      &.category { background-image: url(./assets/category.svg); }
      &.address { background-image: url(./assets/address.svg); }
      &.phone { background-image: url(./assets/phone.svg); }
    }
  }

  .addItemLink {
    color: $eg-aqua;
    
    &:hover {
      cursor: pointer;
    }
  }
}
