@import '../../sass/colors';
@import '../../sass/mixins';

.eg-customer-header {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 56px;
  box-shadow: 0 0.5px 0 0 rgba(193, 201, 209, 0.7);
  background-color: $eg-gray100;
  z-index: 90;

  .trigger {
    font-size: 14px;
    font-weight: 600;
    margin-right: 75px;
    margin-top: 15px;
    padding-left: 35px;
    padding-top: 2px;
    height: 26px;
    background-image: url(./assets/user-icon.svg);
    background-repeat: no-repeat;
    background-position: left;

    span {
      text-transform: uppercase;
      color: #fff;
      background-color: $eg-highlight-green;
      font-size: 11px;
      padding: 3px 6px;
      border-radius: 3px;
      margin-left: 12px;
    }
  }

  .eg-menu-items {
    .item {
      padding-left: 56px;
      background-repeat: no-repeat;
      background-position: 16px center;

      &.-settings {
        background-image: url(./assets/ic-account.svg);
      }

      &.-logout {
        background-image: url(./assets/ic-logout.svg);
      }
    }
  }
}

// responsiveness
@include breakpoint-tablet {
  .container {
    position: fixed;
    .userBtn {
      margin-right: 16px;
    }
  }
}
