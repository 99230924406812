@import '../../../sass/colors';

.container {
  padding: 0;

  .label {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: 0.5px;
    color: $eg-black;
    margin-bottom: 9px;
    text-transform: uppercase;
  }
}
