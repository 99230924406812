@import '../../../../sass/colors.scss';

.eg-platform-stat-container {
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #E1E5E9;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 320px;
  width: 48%;

  &>.title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    color: $eg-black;
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .eg-platform-stat {
    margin-bottom: 14px;

    >.top-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    >.top-row {
      >.platform {
        height: 15px;
        width: 17px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 10px;
        &.ios {
          background-image: url(./asset/ios.svg)
        }

        &.android {
          background-image: url(./asset/android.svg)
        }

        &.desktop {
          background-image: url(./asset/desktop.svg)
        }
      }
    }
    >.top-row >.label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
    }

    &>.percent-bar {
      width: 100%;
      height: 8px;
      border-radius: 4.5px;
      // background-color: $eg-light-gray;

      // &>div {
      //   height: 100%;
      // }
    }
  }
}