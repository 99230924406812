@import '../../../sass/colors';

div.DayPicker {
  min-width: 250px;
  font-size: 12px;
  font-weight: 300;
}

div.DayPicker-NavBar {
  display: flex;
}

span.DayPicker-NavButton {
  width: 24px;
  height: 24px;
  font-family: "Nunito Sans", sans-serif;
  background-image: url(./assets/caret.svg);
}

span.DayPicker-NavButton--prev {
  left: 10px;
}

span.DayPicker-NavButton--next {
  transform: rotate(180deg);
}

div.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
  text-align: center;
  margin-top: 5px;
}

div.DayPicker-Month {
  width: 250px;
  height: 210px;
}

div.DayPicker-Week:first-child .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  margin-top: -3px;
}

div.DayPicker-Weekdays {
  border-bottom: 10px solid transparent;
}

div.DayPicker-Day {
  width: 35px;
  height: 35px;
}

div.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  padding-top: 9px;
  position: absolute;
  background-color: $eg-green;
  color: $eg-light-gray;
}

div.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: $eg-light-gray;
}

div.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: $eg-green;
}

div.DayPickerInput {
  position: relative;
  width: 100%;
}

div.DayPickerInput input {
  width: 100%;
  padding-left: 10px;
  background-color: $eg-white;
  color: $eg-black;
  border: 1px solid #dcdedf;
  height: 40px;
  border-radius: 3px;
  font-size: 14px;
  min-width: 108px;
}

div.DayPickerInput input::placeholder {
  color: $eg-gray;
}
