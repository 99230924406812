@import '../../sass/colors';

.container {
  .input {
    border: 1px solid $eg-light-gray;
    width: 100%;
    background-image: url(./assets/search.svg);
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 40px;

    &::placeholder {
      color: $eg-gray;
    }
  }
}
