@import '../../../sass/colors';

.eg-create-number {
  display: flex;
  background-color: $eg-white;
  margin: 32px 80px;
  border-radius: 8px;

  >.numbers {
    width: 240px;
    height: 100vh;
    background-color: $eg-light-gray2;
  }

  >.section-wrapper {
    flex-grow: 1;

    >.body {
      margin: 88px auto 0;
      width: 440px;

      >.title {
        font-size: 24px;
        font-weight: 600;
      }

      >.subtitle {
        font-size: 14px;
        color: $eg-dark-gray;

        >a {
          color: $eg-green;
          text-decoration: none;
          margin-left: 5px;
        }
      }

      >.form {
        margin-top: 30px;

        >.row {
          margin-bottom: 24px;

          >.label {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
          }

          >.error {
            color: $eg-red;
            font-size: 14px;
          }
        }

        >.actions {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;

          .button {
            margin-left: 20px;

            &.-primary {
              background-color: $eg-green;
            }

            &.-secondary {
              background-color: $eg-light-gray2;
              border: none;
            }

            &.-disabled {
              background-color: #75cbc4;
            }
          }
        }
      }
    }
  }

  .modal {
    text-align: center;
    width: 352px;
    >.title {
      background-image: url(./assets/success.png);
      background-repeat: no-repeat;
      background-size: 56px;
      background-position: center top;
      padding-top: 65px;
      margin-top: 65px;
      height: 100px;
      font-size: 24px;
      font-weight: 600;
    }

    >.subtitle {
      font-size: 14px;
      color: #6b7681;
      margin-top: 10px;
    }

    >.phone {
      font-size: 24px;
      font-weight: 600;
      margin-top: 26px;
    }

    .button {
      width: 256px;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      margin: 24px auto 64px;

      &.-primary {
        background-color: $eg-green;
      }

      &.-secondary {
        background-color: $eg-light-gray2;
        border: none;
      }

      &.-disabled {
        background-color: #75cbc4;
      }
    }

    .modalclose {
      opacity: 0.3;
      font-size: 26px;
      right: 24px;
    }
  }
}
