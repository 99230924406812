@import '../../../sass/colors.scss';
@import '../../../sass/mixins.scss';

.eg-chain-info {
  min-width: 264px;
  max-width: 400px;
  background-color: $eg-white;
  border-radius: 6px;
  padding: 36px 28px;
  padding-top: 20px;
  margin-right: 24px;

  @include breakpoint-tablet {
    width: 100%;
    margin-right: 0px;
  }

  >.field-title {
    margin-top: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
  }

  >.field-subtitle {
    margin-top: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
  }

  .field-value {
    margin-top: 10px;
    width: 100%;
    height: 32px;
    padding: 6px 8px;
    background-color: $eg-fade;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    overflow: hidden;
  }

  .field-placeholder {
    margin-top: 10px;
    width: 100%;
    height: 32px;
    padding: 6px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: $eg-medium-gray;
    background-color: $eg-pale-gray;
    cursor: unset;
  }

  >.border  {
    margin: 16px 0px;
    border-bottom: 1px solid #E1E5E9;
  }

  >.qr-image {
    position: relative;
    margin-top: 10px;
    width: 100%;
    min-height: 128px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.qr-placeholder {
      background-color: $eg-pale-gray;
    }
  }

  >.qr-image >.qr-code {
    max-width: 172px;
    height: 124px;
  }

  >.qr-image >.download-logo {
    position: absolute;
    top: 0px;
    right: 8px;
    width: 18px;
    height: 18px;
    background-image: url('./asset/download.svg');
    background-repeat: no-repeat;
    cursor: pointer;

    &.no-download {
      background-image: url('./asset/no-download.svg');
      right: 6px;
      width: 22px;
      height: 22px;
      top: 4px;
      cursor: unset;
    }
  }
}
