@import '../../../../sass/colors';

.container {
  display: flex;
  height: 184px;
  overflow-y: auto;
  position: absolute;
  top: 40px;
  left: 0;
  flex-direction: column;
  box-shadow: 0 4px 16px 0 rgba(44, 44, 44, 0.15);
  border: solid 1px $eg-pale-gray;
  background-color: $eg-white;
  width: 112px;
  z-index: 10;

  .item {
    padding: 10px 16px;
    text-align: left;

    &:hover {
      background-color: $eg-pale-gray;
      cursor: pointer;
    }
  }
}
