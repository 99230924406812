@import '../../../sass/colors';

.eg-customer-interactions {
  min-width: 400px;
  padding: 24px;

  .eg-interaction:first-of-type .item:first-of-type .logowrapper::before {
    display: none;
  }
  .eg-interaction:last-of-type .item:last-of-type .logowrapper::after {
    display: none;
  }

  .eg-event-timeline {
    padding-bottom: 32px;
  }
}
