@import '../../../../../../../sass/colors';

.success-card {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.logo {
    background-image: url(./asset/baloon.svg);
    background-size: cover;
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  >.title {
    margin-top: 32px;
    width: 330px;
    height: 96px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $eg-black;

    span {
      text-transform: capitalize;
    }
  }

  >.info {
    margin-top: 12px;
    width: 280px;
    height: 48px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $eg-dark-gray;

    span {
      text-transform: capitalize;
    }
  }
}
