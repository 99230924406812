.eg-campaigns-create-setup {
  .step-body {
    padding: 40px 48px 0;
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .subtitle {
      color: #162533;
      font-size: 16px;
      line-height: 26px;
      margin-top: 5px;
      margin-bottom: 24px;
    }

    .form {
      max-width: 600px;
      height: 400px;

      >.row {
        margin-bottom: 24px;
      }

      label {
        margin-bottom: 8px;
        display: block;
      }
    }
  }
}
