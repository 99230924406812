@import '../../sass/colors';
@import '../../sass/mixins';

.eg-customer-snippet {
  display: flex;
  align-items: center;
  padding: 33px 24px;

  .avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: $eg-light-gray3;
    background-image: url(./assets/avatar.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .info {
    margin-left: 24px;
    font-size: 20px;
    font-weight: 600;
  }

  span {
    display: block;
    letter-spacing: 0.3px;
    font-size: 14px;
    font-weight: normal;
    color: $eg-dark-gray;
    margin-top: 8px;
  }

  // responsiveness
  @include breakpoint-mobile {
    margin-left: 0px;
  }
}
