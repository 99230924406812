@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.eg-toast-connect {
  padding: 64px 58px;
  display: flex;
  align-items: flex-end;
  @include breakpoint-mobile {
    padding: 60px 24px;
  }
  &.eg-toast-success{
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    >.success-description {
      margin-top: 16px;
      font-size: 16px;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: $eg-black;
    }
  }

  >.leftpart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 56px;
    width: 350px;
  }

  .logo {
    position: relative;
    width: 80px;
    height: 80px;
    background-image: url(../../assets/toast.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .info-title {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.333;
    color: $eg-black;
    display: flex;
    align-items: center;
  }

  >.leftpart >.info {
    width: 100%;
  }

  >.leftpart >.info  >.field {
    margin-top: 20px;

    .label {
      text-transform: uppercase;
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: 0.5px;
      color: $eg-medium-black;
      margin-bottom: 6px;
    }

    .error-msg {
      margin-top: 4px;
      color: $eg-red;
    }
  }

  .connect-btn {
      margin-top: 24px;
      width: 350px;
      height: 48px;
      color: $eg-white;
      background-color: $eg-aqua;
      text-transform: uppercase;
    }

  >.leftpart .info-title >.beta {
    width: 36px;
    height: 17px;
    margin-left: 10px;
    border-radius: 3px;
    background-color: $eg-dark-gray;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: $eg-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >.leftpart >.info >.loader {
    margin-top: 24px;
  }

  >.rightpart {
    max-width: 348px;
    height: 310px;
    padding-left: 56px;
    border-left: solid 1px $eg-light-gray;
    display: flex;
    flex-direction: column;

    >.info-logo {
      width: 40px;
      height: 40px;
      margin-bottom: 24px;
      padding: 8px;
      background-color: #d8f0ee;
      border-radius: 50%;

      >.logo {
        width: 24px;
        height: 24px;
        background-image: url(../../assets/info.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  
    >.title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      color: $eg-black;
    }

    >.info-point {
      margin-top: 8px;
      font-size: 14px;
      line-height: 1.5;
      color: $eg-black;
    }

    a {
      margin: 0px 4px;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      color: $eg-aqua;
    }
  }
}
