@import '../../../sass/colors.scss';

.eg-segment-content {

  .eg-segment-modal {
    margin-top: 180px;
  }

  .eg-segment-create-option {
    padding-bottom: 24px;
    .title {
      padding: 20px 24px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      color: $eg-black;
    }
  }
}
