@import '../../../sass/colors.scss';

.eg-radio-button {
  display: flex;
  margin-top: 32px;
  margin-left: 24px;
  cursor: pointer;

  &>.selectedLogo {
    margin-right: 24px;
    width: 30px;
    height: 30px;
    background-image: url('./asset/selected.svg');
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &>.unSelectedLogo {
    margin-right: 24px;
    width: 30px;
    height: 30px;
    background-image: url('./asset/unselected.svg');
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &>.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 16px;
    width: 85%;
  }

  &>.content >.title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: $eg-black;
  }

  &>.content >.description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.5;
    color: $eg-dark-gray;
    white-space:pre-wrap;
    margin-left: 2px;
  }
}

