@import '../../../../sass/colors.scss';

.eg-city-stat-container {
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #E1E5E9;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 320px;
  width: 48%;

  &>.title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    color: $eg-black;
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .eg-city-stat {
    margin-bottom: 14px;
    >.label {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    &>.percent-bar {
      width: 100%;
      height: 8px;
      border-radius: 4.5px;
      // background-color: $eg-light-gray;

      // &>div {
      //   height: 100%;
      // }
    }
  }
}