// Color Palette

$eg-black: #162533;
$eg-medium-black: #35414e;
$eg-dark-gray: #6b7681;
$eg-gray: #a4aeb6;
$eg-medium-gray: #c1c9d1;
$eg-light-gray: #e1e5e9;
$eg-pale-gray: #f0f3f7;
$eg-fade: #f8fafc;
$eg-white: #fff;

$eg-dark-red: #b82e2e;
$eg-red: #cc3333;
$eg-medium-red: #e56060;
$eg-pastel-red: #f99999;
$eg-light-red: #fbc8c8;

$eg-orange: #eb5c42;
$eg-medium-orange: #f98369;
$eg-pastel-orange: #feb6a3;
$eg-light-orange: #ffccbe;

$eg-dark-aqua: #34a39a;
$eg-aqua: #3ab5ab;
$eg-medium-aqua: #75cbc4;
$eg-pastel-aqua: #9ddad5;
$eg-light-aqua: #d8f0ee;
$eg-pale-aqua: #effaf9;

$eg-green: #53cc8e;
$eg-medium-green: #75d6a5;
$eg-light-green: #a9e6c7;

$eg-blue: #6cc4dc;
$eg-medium-blue: #8ed9e9;
$eg-light-blue: #bfe9f3;

$eg-yellow: #ffde8b;
$eg-light-yellow: #fff6c0;

// Custom Palette
$eg-spinner-backdrop: transparentize(#000, 0.5);
