@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  display: flex;
  justify-content: flex-end;

  .paginationContainer {
    list-style: none;
    padding: 0;
    display: flex;

    li {
      border: 1px solid $eg-pale-gray;
      width: 32px;
      height: 32px;
      padding-top: 5px;
      text-align: center;
      background-color: #fff;
      font-size: 12px;
      font-weight: 600;
      margin-right: 10px;
      border-radius: 3px;
      cursor: pointer;

      &.active {
        background-color: $eg-green;
        color: #fff;
      }

      &.previous {
        background-image: url(./assets/caret-left.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      &.next {
        background-image: url(./assets/caret-left.svg);
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(180deg);
      }

      a {
        width: 32px;
        height: 32px;
        display: block;
      }
    }
  }

  .controlBtn {
    margin-top: 14px;
    background-image: url(./assets/caret-left.svg), url(./assets/caret-left.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: 2px, 10px;
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid $eg-pale-gray;
    width: 32px;
    height: 32px;
    padding-top: 5px;
    text-align: center;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 3px;
    cursor: pointer;

    &.last {
      transform: rotate(180deg);
    }
  }
}

// responsiveness
@include breakpoint-mobile {
  .container {
    width: 100%;

    .paginationContainer {
      flex-wrap: wrap;

      li {
        margin-bottom: 10px;
      }
    }
  }
}
