@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.reviewItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  padding: 17px;
  color: $eg-black;
  border-bottom: 1px solid $eg-pale-gray;

  .mSection {
    display: flex;
    // flex-direction: column;
    width: 38%;
    @include breakpoint-desktop-small {
      width: 35%;
    }
  }

  .mRightSection {
    display: flex;
    width: 30%;
    @include breakpoint-desktop-small {
      width: 33%;
    }
  }

  &:hover {
    box-shadow: 4px 4px 5px 0px rgba(22, 37, 51, 0.15);
    border-left: 4px solid $eg-green;
    padding-left: 13px;
    cursor: pointer;
  }

  .reviewSource {
    width: 59%;
  }

  .reviewDate {
    width: 36%;
  }

  .reviewStore {
    width: 15%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: $eg-black;
    @include breakpoint-tablet {
      margin-top: 8px;
      width: 100%;
    }
  }

  .reviewLocation {
    width: 10%;
  }

  .reviewUser {
    width: 13%;
    @include breakpoint-desktop-small {
      width: 15%;
    }
    strong {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
    }
  }

  .reviewRate {
    width: 20%;

    .starsWrapper {
      font-size: 23px;
      letter-spacing: 4px;
    }


    .reviewRateComment {
      position: relative;
      line-height: 1.5;
      max-height: 35px; // line-height(rem) * max lines
      font-size: 12px;
      color: $eg-dark-gray;
      overflow: hidden;
      word-break: normal;
      padding-right: 8px;
      text-align: justify;

      &:before {
        content: "...";
        position: absolute;
        bottom: -1px;
        right: 0;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        background: white;
      }
    }
  }

  .reviewStatus {
    width: 50%;
    padding-top: 6px;
    @include breakpoint-tablet {
      width: 20%;
      margin-top: 20px;
      padding-left: 0;
    }
    @include breakpoint-tablet {
      width: 30%;
    }
  }

  .reviewActions {
    width: 35%;
    display: flex;
    flex-direction: column;
    @include breakpoint-tablet {
      flex-grow: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &>.responded {
      width: 118px;
      height: 32px;
      padding: 0px 8px;
      border-radius: 4px;
      border: solid 1px $eg-aqua;
      background-color: rgba(255, 255, 255, 0);
      color: $eg-dark-aqua;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      &::after{
        background-image: url('./asset/green-arrow.svg');
        width: 24px;
        height: 24px;
        object-fit: contain;
        background-repeat: no-repeat;
        content: " ";
      }
    }

    &>.notResponded {
      width: 118px;
      height: 32px;
      padding: 0px 8px;
      border-radius: 4px;
      background-color: $eg-aqua;
      color: $eg-white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      &::after{
        background-image: url('./asset/white-arrow.svg');
        width: 24px;
        height: 24px;
        object-fit: contain;
        background-repeat: no-repeat;
        content: " "
      }
    }

    &>.viewOn {
      margin-top: 8px;
      font-size: 12px;
      color: $eg-dark-gray;
      text-decoration: underline;
      white-space: nowrap;
      @include breakpoint-tablet {
        margin-top: 0px;
      }

      &:hover {
        text-decoration: none;
      }

      span {
        text-transform: capitalize;
      }
    }
  }
}

@include breakpoint-desktop-small {
  .reviewItem {
    .reviewRate {
      width: 20%;
    }

    .reviewActions {
      width: 35%;
      text-align: right;
    }
  }
}

@include breakpoint-tablet {
  .reviewItem {
    flex-direction: column;
    height: auto;
    margin-bottom: 8px;
    background-color: $eg-white;

    .mSection {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .mRightSection {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
    .reviewSource {
      width: 100%;
    }

    .reviewDate {
      width: 100%;
      text-align: right;
    }

    .reviewLocation {
      width: 100%;
    }

    .reviewUser {
      width: 100%;
      margin-top: 10px;
    }

    .reviewRate {
      width: 100%;

      .reviewRateComment {
        margin-top: 10px;
      }
    }

    .reviewActions {
      width: 100%;
      text-align: right;
      padding-top: 20px;
    }
  }
}
