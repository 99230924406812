@import '../../sass/colors';

.eg-call-logs {
  >.body {
    >.title {
      margin: 30px 74px;
    }

    position: relative;

    .totals {
      margin: 10px 74px;
      font-size: 16px;
      font-weight: bold;
    }

    .calls {
      margin: 20px 74px;
      padding: 0;
      background-color: $eg-white;
      border-radius: 8px;
    }

    >.filterbar {
      display: flex;
      justify-content: space-between;
      margin: 32px 74px 23px;
      padding-bottom: 23px;
      border-bottom: 1px solid $eg-light-gray;

      >.filters {
        display: flex;
        align-items: center;

        >.store {
          height: 40px;
        }

        >.clear {
          margin-left: 12px;
          font-size: 12px;
          font-weight: 600;
          color: $eg-dark-gray;
          cursor: pointer;
        }
      }
    }

    .filtersWrapper {

    }

    .emptyview {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 100px;

      .image {
        width: 56px;
        height: 56px;
        background-image: url(./assets/phone.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: $eg-light-gray3;
        border-radius: 50%;
      }

      .title {
        margin-top: 29px;
        font-size: 20px;
        font-weight: bold;
      }

      .description {
        margin-top: 19px;
        color: $eg-dark-gray;
        font-size: 14px;
      }
    }

    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: $eg-pale-gray;
      opacity: 0.5;
      display: block;
      padding-top: 20%;
    }

    .pagination {
      padding: 0px 98px 40px;
    }
  }
}
