/************************************
 **         APP WIDE MIXINS        **
 ************************************/
@import './colors.scss';

// BREAKPOINTS MIXINS

$breakpoint-desktop: 1440px;
$breakpoint-desktop-small: 1280px;
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-small: 480px;

@mixin breakpoint-desktop {
  @media screen and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin breakpoint-desktop-small {
  @media screen and (max-width: #{$breakpoint-desktop-small}) {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media screen and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin breakpoint-mobile {
  @media screen and (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin breakpoint-mobile-small {
  @media screen and (max-width: #{$breakpoint-mobile-small}) {
    @content;
  }
}

@mixin breakpoint-custom($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin breakpoint-vertical-min($minHeight) {
  @media screen and (min-height: $minHeight) {
    @content;
  }
}

@mixin breakpoint-vertical-max($maxHeight) {
  @media screen and (max-height: $maxHeight) {
    @content;
  }
}

// END BREAKPOINT MIXINS

@mixin primaryBtnBackgroundColor {
  background-image: -webkit-gradient(linear, left top, right top, from(#f15d2e), to(#ef4b4b));
  background-image: linear-gradient(90deg, #f15d2e, #ef4b4b);
  background-color: #f15d2c;
  color: white;
  &:hover:not([disabled]) {
    box-shadow: 0 4px 8px 0 rgba(241, 90, 50, .72);
  }
}

@mixin rates {
  .rate8 {
    color: $rate8;
  }
    
  .rate7 {
    color: $rate7;
  }
    
  .rate6 {
    color: $rate6;
  }
    
  .rate5 {
    color: $rate5;
  }
    
  .rate4 {
    color: $rate4;
  }
    
  .rate3 {
    color: $rate3;
  }
    
  .rate2 {
    color: $rate2;
  }
    
  .rate1 {
    color: $rate1;
  }
}

@mixin spinner {
  margin: auto;
  width: 25px;
  height: 25px;
  background-image: url('./assets/spinner.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {transform: rotate(360deg);}
}