@import '../../../../sass/colors.scss';

.eg-integration-card {
  position: relative;
  width: 240px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $eg-white;
  margin-right: 24px;
  cursor: pointer;

  &>.loader {
    position: absolute;
    top: 0px;
    right: 16px; 
    background-image: url('../../../../asset/loader.svg');
    width: 36px;
    height: 36px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
  }

  &>.status {
    position: absolute;
    top: 16px;
    right: 16px; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 16px;
    border-radius: 3px;
    background-color: $eg-green2;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.25px;
    color: $eg-white;
  }

  &>.wrapper {
    width: 240px;
    height: 136px;
    box-shadow: 0 1px 0 0 #f5f5f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
  }

  &>.wrapper >div {
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    margin: 0 auto;
  }

  &>.footer {
    padding: 8px 16px;
  }

  &>.footer >.row {
    display: flex;
    justify-content: space-between;
  }

  &>.footer >.row >.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $eg-black;
    display: flex;
    align-items: center;
  }

  &>.footer >.row >.title >.beta {
    width: 36px;
    height: 17px;
    display: block;
    margin-left: 6px;
    border-radius: 3px;
    background-color: $eg-dark-gray;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: $eg-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &>.footer >.row >.arrow {
    background-image: url('../asset/green_arrow.svg');
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    cursor: pointer;
  }

  &>.footer >.subtitle {
    font-size: 12px;
    line-height: 1.33;
    color: $eg-dark-gray;
  }
}
