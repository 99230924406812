@import '../../../../sass/mixins';
@import '../../../../sass/colors';

.eg-create-segment-design {
  .step-body {
    padding-top: 0;
    display: flex;

    .panel {
      padding-top: 40px;
      width: 100%;

      >.title {
        padding-left: 48px;
      }

      >.subtitle {
        padding-left: 48px;
      }
    }

    .predicate-group-container {
      margin: 20px 40px;

      >.and-divider-label {
        height: 20px;
        width: 35px;
        border-radius: 8px;
        background-color: #D5F2F0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .predicate-group {
      border: 1px solid $eg-light-gray;
      padding: 16px;
      border-radius: 8px;
    }

    .and-add {
      margin-left: 40px;
      margin-top: -4px;
      margin-bottom: 40px;
      width: 72px;
      height: 38px;
      background: #2BBFB3;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: #ffffff;
      cursor: pointer;

      &::before {
        content: ' ';
        width: 24px;
        height: 24px;
        background-image:  url('../../asset/add.svg');
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 7px;
        display: block;
      }

  }
  }
}
