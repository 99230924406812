@import '../../sass/colors';

.dropdownContainer {
  position: relative;
  background-color: #fff;
  border: 1px solid $eg-pale-gray;
  display: flex;
  align-items: center;
  cursor: pointer;

  .currentSelection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
  }

  .itemsWrapper {
    position: absolute;
    left: 0;
    top: 100%;;
    width: 100%;
    background-color: #fff;
    border: 1px solid $eg-pale-gray;

    >div {
      padding: 8px 10px;

      &:hover {
        background-color: $eg-pale-gray;
      }
    }

    &.hiddenItems {
      display: none;
    }
  }
}
