@import '../../../../../sass/colors';

.eg-rating-thankyou {
  display: flex;

  &.hidden {
    display: none;
  }

  .review-buttons {
    margin-top: 24px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: $eg-black;
    margin-bottom: 16px;
  }
  
  .social-media {
    display: flex;
    flex-direction: column;

    label {
      font-size: 16px;
      line-height: 1.5;
      color: $eg-black;
      text-transform: capitalize;
    }

    label input {
      display: none; /* Hide the default checkbox */
    }
    /* Style the artificial checkbox */
    label span {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 15px;
      height: 15px;
      display: block;
      border: 1px solid grey;
      display: inline-block;
      position: relative;
      border-radius: 2px;
      margin-right: 16px;
    }
    
    /* Style its checked state...with a ticked icon */
    [type=checkbox]:checked + span:before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      width: 24px;
      height: 20px;
      display: block;
      background-image: url(../../assets/checkbox.svg);
    }
  }

  >.form-wrapper {
    padding: 40px 73px;
    width: 50%;

    >.title {
      font-size: 24px;
      font-weight: bold;
    }

    .tabs-container {
      margin-top: 40px;
      border-bottom: none;
      padding-left: 0px;

      .tab-item {
        font-size: 14px;
        font-weight: 600;
      }
    }

    >.no-display {
      display: none;
    }
  
    >.form {
      margin-top: 20px;

      >.message {
        width: fit-content;
        background-color: #d5f2f0;
        padding: 17px;
        border-radius: 8px;
        margin: 40px 0 32px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
      }
      .label {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: 10px;
        display: block;
      }

      .textarea-wrapper {
        >div {
          padding: 0;
        }

        .textarea {
          height: 120px;
          background-color: #fff;
        }
      }

      .field {
        margin-bottom: 24px;
      }

      .error-msg {
        color: $eg-red;
      }

      .error {
        input {
          border: 1px solid $eg-red;
        }
      }
      
      textarea.error {
        border: 1px solid $eg-red;
      }
    }
  }

  >.preview {
    background-color: #bfece8;
    border-bottom-right-radius: 8px;
    padding-top: 40px;
    width: 50%;

    >.title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    >.phone {
      margin: 40px auto;
    }

    .iphone-font-mini {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
      color: #666;
    }

    .message-wrapper {
      font-family: 'SF Pro Text';
      width: 300px;
      background-color: #e8e8e8;
      border-radius: 12px;
      padding: 15px;
      margin-left: 12px;
      margin-top: 17px;

      p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 1.4;

        &.link {
          color: #2585c5;
          text-decoration: underline;
        }
      }
    }
  }
}
