@import '../../../sass/colors';
@import '../../../sass/mixins';

.eg-customer-list {
  //Perhaps we don't need this scrolling because we allready have a scroller on parent component.
  // overflow-y: scroll;
  width: 100%;
  border-radius: 4px;

  &>.loader {
    margin-top: 100px;
    margin-bottom: 200px;
  }

  .sort {
    padding-left: 24px;
    background-color: $eg-white;
    display: flex;

    > div {
      width: 33%;
      display: flex;
      justify-content: flex-start;
    }

    @include breakpoint-mobile {
      display: none;
    }

    .emails {
      min-width: 200px;
    }

    .phones {
      width: 124px;
    }

    .field {
      padding: 20px 0;
      padding-top: 16px;
      font-size: 11px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .indicators {
        height: 25px;
      }
    }
  }

  .body .row {
    display: grid;
    grid-template-columns: 0.9fr 1.2fr 1.1fr 0.7fr 0.5fr 0.6fr;
    background-color: $eg-white;
    margin-bottom: 4px;
    cursor: pointer;

    &:hover {
      background-color: $eg-pale-gray;
    }
  
    @include breakpoint-mobile {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-bottom: 16px;
    }

    >.cell {
      padding: 35px 0;
      font-size: 14px;
      display: flex;

      @include breakpoint-mobile {
        font-size: 12px;
        padding: 8px 0;

        &:before {
          content: attr(data-label);
          display: inline-block;
          margin-left: 20px;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
          color: $eg-dark-gray;
          width: 120px;
          margin-top: 3px;
        }
      }

      .value {
        width: 100%;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $eg-black;
        padding-right: 8px;
      }

      .user {
        display: flex;
        align-items: flex-start;
      }

      .avatar {
        width: 32px;
        height: 32px;
        background-image: url(./assets/avatar.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-color: $eg-light-gray2;
        border-radius: 50%;
        margin-right: 20px;
        margin-top: -3px;
      }

      .email {
        text-transform: lowercase;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      .address {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }

      .source {
        text-transform: capitalize;
      }

      &:nth-child(6n + 1) {
        padding: 35px 0 35px 16px;

        @include breakpoint-mobile {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
