// colors
$eg-black: #162533;
$eg-medium-black: #35414e;
$eg-white: #ffffff;
$eg-green: #3ab5ab;
$eg-green2: #53cc8e;
$eg-medium-green: #75d6a5;
$eg-light-moss-green: #afd663;
$eg-aqua: #3faca7;
$eg-dark-aqua: #34a39a;
$eg-pastel-aqua: #9ddad5;
$eg-light-green: #3faca7;
$eg-highlight-green: #00b6b6;

$eg-black: #2c2c2c;
$eg-dark-gray: #6b7681;
$eg-medium-gray: #6e6f6f;
$eg-gray: #a4aeb6;
$eg-body-gray: #eff3f7;
$eg-pale-gray: #f6f9fb;
$eg-light-gray: #e1e5e9;
$eg-light-gray2: #f1f3f7;
$eg-light-gray3: #c1c9d1;
$eg-blue: #2ea0dd;
$eg-dark-blue: #4285f4;
$eg-red: #ef4c4a;
$eg-dark-red: #cc3333;
$eg-red-orange: #eb5c42;
$eg-orange: #f15a32;
$eg-medium-orange: #f98369;
$eg-yellow: #ffde8b;
$eg-golden-yellow: #fac723;
$eg-pastel-red: #f99999;
$eg-yellowish-orange: #ffa319;
$eg-fade: #f4f6f9;

$eg-gray100: #fafcfd;
$eg-gray90: #dcdedf;
