@import '../../../sass/colors';

.eg-status-widget {
  width: 77px;
  height: 26px;
  border-radius: 12px;
  background-color: #b2ffce;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $eg-black;
  padding-top: 5px;

  &::before {
    content: " ";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #2dd46a;
    margin: 0 8px;
  }
}
