
@import '../../../../sass/colors.scss';

.eg-mainautomation-card {
  position: relative;
  margin-top: 40px;
  width: 364px;
  height: fit-content;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $eg-white;
  margin-right: 24px;
  cursor: pointer;

  // &>.loader {
  //   position: absolute;
  //   top: 0px;
  //   right: 16px; 
  //   background-image: url('../../../../asset/loader.svg');
  //   width: 36px;
  //   height: 36px;
  //   object-fit: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   object-fit: contain;
  // }

  // &>.status {
  //   position: absolute;
  //   top: 16px;
  //   right: 16px; 
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 80px;
  //   height: 16px;
  //   border-radius: 3px;
  //   background-color: $eg-green2;
  //   font-size: 11px;
  //   font-weight: 600;
  //   line-height: 1;
  //   letter-spacing: 0.25px;
  //   color: $eg-white;
  // }

  &>.wrapper {
    height: 192px;
    box-shadow: 0 1px 0 0 #f5f5f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
  }

  &>.wrapper >div {
    background-repeat: no-repeat;
    background-position: center;
    object-fit: contain;
    margin: 0 auto;
  }

  &>.wrapper >.review {
    background-image: url('./asset/review.svg');
    width: 120px;
    height: 120px;
  }

  &>.wrapper >.loyalty {
    background-image: url('./asset/loyalty.svg');
    width: 120px;
    height: 120px;
  }

  &>.footer {
    padding: 16px 20px 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &>.footer >.row {
    max-width: 216px;
    margin-right: 16px;
  }

  &>.footer >.row >.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: $eg-black;
  }

  &>.footer >.add {
    width: 88px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $eg-aqua;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: $eg-white;
    cursor: pointer;
    text-transform: uppercase;
  }

  &>.footer >.row >.subtitle {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: normal;
    color: $eg-dark-gray;
  }
}
