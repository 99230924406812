@import '../../sass/tools/animations';
@import '../../sass/tools/z-index';
@import '../../sass/settings/color-palette';

.eg-spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    $border-color: $eg-white;
    $border-color-translucent: transparentize($border-color, 0.5);
    width: 25px;
    height: 25px;
    background: transparent;
    border-radius: 50%;
    border: 3px solid $border-color;
    border-top-color: $border-color-translucent;
    border-left-color: $border-color-translucent;
    animation: spin 2s linear infinite;
    will-change: transform;
  }

  &.-with-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $eg-spinner-backdrop;
    z-index: z-index('spinner');
  }

  &.-local::before {
    $border-color: $eg-aqua;
    $border-color-translucent: transparentize($border-color, 0.5);
    border-color: $border-color;
    border-top-color: $border-color-translucent;
    border-left-color: $border-color-translucent;
  }
}
