@import '../../../../../sass/colors';
@import '../../../../../sass/mixins';


.container {
  padding: 80px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  position: relative;
  height: 80px;
  width: 80px;
  background-image: url('../assets/gmail-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.check{
  position: absolute;
  bottom: 4px;
  right: -12px;
  width: 32px;
  height: 32px;
  background: $eg-green;
  border-radius: 50%;
  border: 4px solid white;
  background-image: url('../assets/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

.infoTitle{
  margin-top: 40px;
  font-family: sofia-pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.333;
  letter-spacing: normal;
  color: $eg-black;
}

.userInfo {
  margin-top: 32px;
  border: 1px solid $eg-light-gray;
  border-radius: 4px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  grid-gap: 24px;
  width: 100%;
  height: fit-content;
  max-height: 80px;
}

.userDetails {
  display: flex;
  align-items: center;
}

.smallInfo {
  font-size: 12px;
  color: $eg-dark-gray;
}

.actionContainer {
  position: relative;
  display: flex;
  align-items: center;

  .popOverStyle {
    top: 48px;
    left: 0px;
  }
}

.dropDownMenu {
  background-image: url(../assets/dots.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $eg-light-gray;
  }

  &:active {
    box-shadow: 1px 1px 3px 0 $eg-light-gray inset;
  }
}

.infoDescription{
  margin-top: 32px;
  list-style: none;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  line-height: 1.625;
  color: $eg-black;
  padding: 0;
  display: grid;
  grid-gap: 16px;
}

.infoDescription > li {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  align-items: center;
}

.listIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d8f0ee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.iconSync {
  background-image: url('../assets/icon-sync.png');
}

.iconBoard {
  background-image: url('../assets/board.svg'); 
}

.iconSecure {
  background-image: url('../assets/icon-secure.png');
}

.boldText{
  font-weight: 600;
}

.gConnectButton{
  margin-top: 24px;
  width: fit-content;
  text-transform: uppercase;
}

.googleConnectError {
  color: $eg-dark-red;
  width: 350px;
  margin: 20px auto;
  }

  .eatgeekInfoContainer{
    display: flex;
    margin-top: 65px;
  }

  .lockLogo{
  margin: -28px 16px 0px 0px;
  }

  .eatgeekMessage{
  font-family: sofia-pro, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $eg-dark-gray;

  a{
   color: $eg-dark-gray;
  }
}

@include breakpoint-mobile {
  .userInfo {
    max-height: none;
  }
  .actionContainer {  
    .popOverStyle {
      left: -170px;
    }
  }
}
