@import '../../../../sass/colors';

.responseContainer {
  padding: 16px 24px;
  background-color: $eg-pale-gray;

  .responseHeader {
    display: flex;
    align-items: center;

    .responseName {
      font-weight: 600;
      font-size: 16px;
    }

    .responseInfo {
      color: $eg-dark-gray;
      font-size: 12px;
      margin-left: 13px;

      span.source {
        text-transform: capitalize;
      }

      .responseDate {
        margin-left: 10px;
      }
    }
  }

  .responseComment {
    margin-top: 8px;
  }
}

.responseForm {
  font-family: sofia-pro, sans-serif;
  margin-top: 24px;

  .textArea {
    height: 80px;
    color: $eg-black;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;

    ::placeholder {
      color: #A4AEB6;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  >.formBottomRow {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
  }

  >.formBottomRow >.replyInfo {
    color: #A4AEB6;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
  }

  >.formBottomRow >.post {
    // font-family: sofiaPro, sans-serif;
    height: 40px;
    width: 104px;
    border-radius: 4px;
    background-color: #3AB5AB;
    color: $eg-white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 21px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.loader {
  margin-top: 64px;
}
