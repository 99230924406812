@import '../../../../../sass/colors.scss';

.eg-segments-predicates-date {
  display: flex;
  margin: 0 8px;
  .number-input {
    margin-left: 8px;
    input {
      height: 48px;
      width: 90px;
    }
  }
}
