.eg-campaign-success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0px;
  background-color: #ffffff;
  .success-logo {
    width: 64px;
    height:64px;
    background: #2BBFB3;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      background-image: url(./asset/white.svg);
      background-size: cover;
      width: 40px;
      height: 30px;
      object-fit: contain;
    }
  }

    >.title {
      margin-top: 24px;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 0px;
    }

    .sublabel {
      margin-top: 24px;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
    }

    .content {
      margin-top: 16px;
      padding: 16px 20px;
      padding-top: 0px;
      width: 480px;
      background: #fff;
      border-radius: 8px;
      text-align: center;
    }

    .actions {
      margin-top: 42px;
    }
}

.eg-campaign-success-modal {
  max-width: 560px;
}
