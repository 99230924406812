
@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.eg-empty-view {
  background-color: $eg-white;
  width: 100%;
  height: 354px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $eg-dark-gray;

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }

  &>.go-back {
    margin-top: 16px;
    width: fit-content;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid $eg-dark-gray;
    border-radius: 4px;
    padding: 8px;
  }

  .description {
    margin-top: 15px;
    font-size: 14px;
    color: $eg-dark-gray;
    font-weight: normal;
  }
}
