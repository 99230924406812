.eg-campaign-test-message {
  .modal {
    max-width: 560px;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      padding: 20px 24px;
      border-bottom: 1px solid #e1e5e9;
    }

    .body {
      margin: 0;
      padding: 20px 24px;

      label {
        margin-bottom: 6px;
        display: block;
        color: #162533;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.25px;
      }

      .sublabel {
        font-size: 12px;
        line-height: 16px;
        color: #35414e;
        margin-bottom: 6px;
      }
    }

    .actions {
      margin-top: 42px;
    }
  }
}
