@import '../../../../../sass/colors';



h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: $eg-dark-gray;
  margin-top: 10px;
}

.linksContainer {
  padding: 24px;

  .linksFormContainer {

    .linksFormRow {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      span {
        font-size: 12px;
        font-weight: 600;
        color: $eg-dark-gray;
        text-transform: uppercase;
        width: 200px;
        height: 48px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0.5px;
        color: $eg-dark-gray;
        display: flex;
        align-items: center;
        padding-top: 12px;
      }

      textarea {
        width: 480px;
        height: 120px;
        border-radius: 3px;
        padding: 8px;
        background-color: $eg-white;
        font-size: 14px;
        line-height: 1.5;
        color: $eg-medium-black;
      }

      textarea::placeholder {
        font-size: 16px;
        line-height: 1.5;
        color: $eg-light-gray;
      }

      input {
        width: 480px;
        height: 40px;
        border-radius: 3px;
        padding: 8px;
        font-size: 14px;
        line-height: 1.5;
        color: $eg-medium-black;
      }

      input::placeholder {
        font-size: 16px;
        line-height: 1.5;
        color: $eg-light-gray;
      }
    }

    .phoneRow {
      display: flex;
      margin-top: 8px;

      > :first-child {
        width: 152px;
        font-size: 12px;
        font-weight: 600;
        color: $eg-dark-gray;
        text-transform: uppercase;
        height: 48px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0.5px;
        color: $eg-dark-gray;
        display: flex;
        align-items: center;
        margin-top: 4px;
      }

      .inputWrapper {
        margin-top: 2px;
      }

      input {
        width: 240px;
        height: 40px;
        border-radius: 3px;
        padding: 8px;
        padding-left: 36px;
        font-size: 14px;
        line-height: 1.5;
        color: $eg-medium-black;
      }

      input::placeholder {
        font-size: 16px;
        line-height: 1.5;
        color: $eg-light-gray;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    color: $eg-dark-gray;
    text-transform: uppercase;
    width: 200px;
    height: 48px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.5px;
    color: $eg-dark-gray;
    display: flex;
    align-items: center;
    padding-top: 12px;
  }


  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 55px;

    > button {
      margin-right: 8px;
      height: 40px;
      padding-top: 12px;
    }
  }

  .inputField {
    input {
      height: 40px;
    }
  }
}

.infoContainer {

  .row {
    display: flex;
    font-family: sofia-pro, sans-serif;
    margin-top: 16px;

    .label {
      min-width: 127px;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 0.5px;
      color: $eg-dark-gray;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-right: 26px;
    }

    .value {
        font-size: 14px;
        line-height: 1.5;
        color: $eg-black;
    }

    .addItemLink {
      color: #c1c9d1;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}
  
