@import '../../../sass/colors';
@import '../../../sass/mixins';


.container {
  .bckgnd {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .displayOnMobile {
    display: none;
  }

  .detailsContainer {
    position: fixed;
    z-index: 200;
    top: 0;
    right: -860px;
    width: 860px;
    min-height: 100vh;
    height: 100%;
    background-color: #fff;
    overflow-y: scroll;

    &.active {
      transition: right 0.5s linear;
      right: 0;
    }

    .detailsHeader {
      display: flex;
      justify-content: space-between;
      margin: 40px;

      .orderPlaced {
        color: $eg-gray;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
      }

      .close {
        width: 32px;
        height: 32px;
        background-image: url(./assets/close.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-top: -7px;
        cursor: pointer;
      }
    }

    .title {
      margin-left: 40px;
    }

    .detailsBody {
      display: flex;
      margin-top: 30px;
      margin-left: 40px;
      margin-bottom: 40px;
    }

    .pendingMsg {
      height: 37px;
      padding-top: 6px;
      margin-bottom: 20px;
      font-size: 14px;
    }
  }

  @include breakpoint-mobile {
    .displayOnDesktop{
      display: none;
    }

    .displayOnMobile {
      display: flex;
      width: 90%;
      margin: auto;
    }

    .detailsContainer {
      width: 100%;

      .detailsHeader {
        width: 90%;
        margin: 26px auto;
      }

      .title {
        width: 90%;
        margin-left: 0px;
        margin: auto;
      }

      .detailsBody {
        flex-direction: column-reverse;
        margin-left:0px;
        margin-right: 0px;
      }
    }
  }
}
