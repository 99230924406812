.eg-create-journey-review {
  .step-body {
    padding: 40px 48px;
    height: calc(100vh - 294px);
    overflow-y: scroll;

    .status-bbl {
      margin-left: 20px;
    }

    &.readonly {
      height: calc(100vh - 228px);
    }

    .alert {
      background-color: #ffde8b;
      padding: 10px 12px;
      font-size: 14px;
      line-height: 21px;
      border-radius: 4px;
    }

    >.trigger, >.review {
      padding: 40px 0 20px;
      display: flex;
      justify-content: space-between;

      >.loader {
        width: 100%;
        height: 103px;
      }

      >.details {
        >.title {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.25px;
          text-transform: uppercase;
          margin-bottom: 15px;
          margin-right: 20px;
        }

        .msgs-container {
          display: flex;
          flex-wrap: wrap;

          >div {
            margin-right: 15px;

            &:last-child {
              margin-right: 0;
            }
          }

          .msg-time {
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 15px;
            display: block;
          }
        }

        >.trigger {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-top: 20px;

          .trigger-id {
            margin-top: 16px;
            font-size: 16px;
            font-weight: normal;
          }
        }

        >.notes {
          font-size: 14px;
          line-height: 21px;
          margin-top: 6px;
          color: #162533;
        }
      }

      >.action {
        >.btn-edit {
          width: 131px;
          height: 32px;
          color: #3ab5ab;
          border: 1px solid #3ab5ab;
          font-size: 12px;
          font-weight: 700;
          padding: 9px 0;
        }
      }
    }

    >.review {
      border-top: 1px solid #c1c9d1;

      .msg-preview {
        position: relative;
        border: 1px solid #e1e5e9;
        border-radius: 8px;
        width: 243px;
        min-height: 200px;
        word-break: break-word;
        padding: 12px;
        font-size: 12px;
        line-height: 14px;
        color: #001224;
        margin-bottom: 20px;

        >.message {
          margin-bottom: 50px;
        }

        >.test-msg-action {
          position: absolute;
          left: 12px;
          right: 12px;
          bottom: 12px;

          >.preview-test-btn {
            width: 100%;
            height: 32px;
            font-size: 12px;
            color: #34a39a;
            border: 1px solid #34a39a;
            border-radius: 4px;
            padding: 9px;
          }
        }
      }
    }

  }
}
