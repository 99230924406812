@import '../../../../../../sass/mixins';

.eg-msg-sms-node {
  // hide arrows in number inputs
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  >.content {
    display: flex;
    max-width: 600px;

    >.side-icon {
      min-width: 44px;
      background-image: url(./assets/wait.svg);
      background-repeat: no-repeat;
      background-position: center top;
      margin-right: 24px;

      >.vline {
        margin-top: 52px;
        width: 50%;
        height: calc(100% - 52px);
        border-right: 1px solid #c1c9d1;
      }
    }

    >.text {
      position: relative;
      background-color: #f4f6f9;
      width: 100%;
      padding: 20px;
      border-radius: 4px;

      @include breakpoint-desktop-small {
        padding-top: 40px;
      }

      >.name {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        color: #162533;
        margin-bottom: 6px;
        display: block;
      }

      >.top-fields {
        width: 100%;
        display: flex;
        margin-bottom: 20px;

        .time {
          width: 73px;
        }

        .unit {
          width: 130px;
          margin-left: 8px;
        }

        .remove-btn {
          position: absolute;
          top: 25px;
          right: 25px;
        }
      }

      >.unsubscribe-msg-check {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #6b7681;

        label {
          color: #6b7681;
          margin: 10px;
          flex-grow: 1;
          text-transform: none;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer;
          display: flex;

          span {
            margin-left: 10px;
          }
        }

        // TODO: refactor checkbox component and remove this from here
        input {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
          cursor: pointer;

          &:checked {
            // + Selector looks for the adjacent sibling (the first element without anything in-between).
            + .checkmark {
              background-color: #3faca7;
              height: 20px;
              width: 20px;
              border: none;

              &:after {
                display: block;
              }
            }
          }
        }

        .checkmark {
          height: 20px;
          width: 20px;
          border: 2px solid #c6c7c8;
          border-radius: 3px;
          flex-shrink: 0;

          /* Style the checkmark/indicator */
          &:after {
            display: none;
            content: ' ';
            position: relative;
            left: 6px;
            top: 1px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
