@import '../../sass/mixins';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  
  .spinner {
    width: 25px;
    height: 25px;
    margin: auto;
    @include spinner;
  }
}
