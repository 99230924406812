@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins';

.eg-breakdown-row {
  display: flex;
  margin-top: 16px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: $eg-black;
  justify-content: space-between;

  &>.store {
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: $eg-black;
    @include breakpoint-desktop-small {
      width: 60%;
    }
  }
  
  &>.avg {
    width: 25%;
    padding-left: 6px;
    &>.star {
      color: $eg-yellowish-orange;
      font-size: 15px;
      margin-left: 5px;
    }
  }
  
  &>.review {
    width: 17%;
    text-align: right;
    @include breakpoint-desktop-small {
      width: 15%;
    }
  }  
}

