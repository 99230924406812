@import '../../sass/colors';
@import '../../sass/mixins';

.eg-customer-details {
  .link {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
  }

  .arrow {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    background-image: url('../../asset/arrow-back.svg');
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.25px;
    text-align: center;
    color: $eg-dark-gray;
    text-decoration: none;
  }

  .wrapper {
    width: 100%;
    display: flex;
    padding: 0 50px;
    margin: 60px auto;

    @include breakpoint-mobile {
      padding: 24px;
    }

    .left-panel {
      width: 70%;

      @include breakpoint-desktop-small {
        width: 100%;
      }

      .body {
        background-color: $eg-white;
        border-radius: 4px;
        margin-top: 20px;

        .tabs {
          padding-left: 24px;
          font-size: 14px;
          font-weight: 600;
          margin-top: 10px;
        }

        .interactions {
          display: flex;
          justify-content: space-between;

          @include breakpoint-mobile {
            flex-direction: column-reverse;
          }

          .details {
            min-height: 100%;
            border-left: 1px solid #e1e5e9;
            width: 264px;
            
            .section {
              padding: 24px;
              border-bottom: 1px solid #E9EBEE;
            }

            @include breakpoint-mobile {
              border-bottom: 1px solid #e1e5e9;
              border-left: none;
              width: 100%;
            }

            .title {
              text-transform: uppercase;
              color: #A4AEB6;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 0.5px;
              line-height: 16px;
            }

            .value {
              margin-top: 12px;
              color: $eg-black;
              font-size: 16px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 24px;
            }
          }
        }
      }

      .mobile-info {
        width: 100%;
        margin-left: 0;
      }
    }

    .right-panel {
      margin-top: 44px;

      @include breakpoint-desktop-small {
        display: none;
      }
    }

    .emptyview {
      margin-top: 130px;
      text-align: center;
      width: 100%;
      color: $eg-dark-gray;
      height: 200px;

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .description {
        font-size: 14px;
        font-weight: normal;
        margin-top: 16px;
      }
    }
  }

  .spinner {
    margin-top: 50px;
  }
}
