@import '../../sass/colors';
@import '../../sass/mixins';

.eg-segments {
  .content {
    position: relative;
    padding: 48px 80px;
    align-items: flex-start;

    >.top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }
    }

    >.tab {
      margin-top: 28px;
      border-bottom: none;
      height: unset;
    }

    >.tab >.item {
      height: 38px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      color: $eg-dark-gray;
      margin-right: 24px;
      text-transform: uppercase;
    }

    >.tab >.selected {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      color: $eg-black;
      border-bottom: 3px solid $eg-aqua;
    }
  }

  .pagination {
    span {
      margin-top: 16px;
    }
  }

}
