@import '../../../../../sass/colors.scss';

.eg-profile-complete {
  &>.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: $eg-black;
  }

  &>.percent-bar {
    margin-top: 8px;
    margin-bottom: 12px;
    width: 154px;
    height: 6px;
    border-radius: 4.5px;
    background-color: $eg-light-gray;
  }

  &>.percent-bar >.fill-green {
    height: 100%;
    background-color: $eg-medium-green;
  }

  &>.percent-bar >.fill-yellow {
    height: 100%;
    background-color: $eg-yellow;
  }

  &>.add-section {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $eg-aqua;
    cursor: pointer;
    text-decoration: none;

    >div {
      margin-top: 4px;
    }
  }
}
