@import '../../sass/colors';

.container {
  font-size: 25px;

  .starLight {
    width: auto;
    color: $eg-yellowish-orange;
  }

  .starDark {
    width: auto;
    color: $eg-light-gray;
  }
}

