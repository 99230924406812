@import '../../../sass/colors';

.eg-call-list {
  >.title {
    font-size: 16px;
    font-weight: 600;
  }

  >.body {
    // margin-top: 36px;
    width: 100%;

    >.headers {
      border-bottom: 1px solid $eg-light-gray;
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 2fr;
      padding: 18px 24px;

      .-header {
        color: $eg-gray;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    >.list {
      padding: 10px 24px;
      display: grid;
      grid-template-columns: 2fr 2fr 1fr 1fr 2fr;
      width: 100%;

      >.cell {

        &.-body {
          font-size: 14px;
          padding: 18px 0;
          color: $eg-dark-gray;
          border-bottom: 1px dashed #ccced8;

          >span {
            font-weight: 600;
            color: $eg-black;
            margin-right: 5px;
            margin-left: 5px;
          }

          >.answered {
            font-weight: 500;
            text-transform: capitalize;
            color: $eg-green;
          }

          >.missed, >.busy {
            font-weight: 500;
            text-transform: capitalize;
            color: $eg-red;
          }

          >.queued {
            font-weight: 500;
            text-transform: capitalize;
            color: $eg-golden-yellow;
          }

          >.in-progress {
            font-weight: 500;
            text-transform: capitalize;
            color: $eg-green;
          }
        }

        &.-last {
          border-bottom: none;
        }

        &.-state-answered {
          padding-left: 30px;
          background-image: url('./assets/phone-green.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }

        &.-state-missed, &.-state-busy {
          padding-left: 30px;
          background-image: url('./assets/phone-red.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }

        &.-state-queued {
          padding-left: 30px;
          background-image: url('./assets/phone-queued.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }

        &.-state-in-progress {
          padding-left: 30px;
          background-image: url('./assets/phone-in-progress.svg');
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
    }
  }
}
