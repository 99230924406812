.eg-list-item-options {
  position: relative;

  >.trigger {
    width: 16px;
    height: 4px;
    background-image: url(./assets/dots.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    height: 25px;
  }

  >.menu {
    position: absolute;
    top: 24px;
    right: -10px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 4px 16px rgba(44, 44, 44, 0.15);
    min-width: 200px;
    z-index: 10;
    
    >.item {
      padding: 12px 16px;

      &:hover {
        background-color: #f4f6f9;
      }
    }
  }
}
