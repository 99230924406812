.eg-campaign-schedule {
    width: 560px;

    .title {
      margin: 20px 24px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }

    .options {
      padding: 20px 24px;
      border-top: 1px solid #e1e5e9;
      display: flex;
      justify-content: center;

      div:last-child {
        margin-right: 0px;
      }
    }

    .options >.option {
      max-width: 248px;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      margin-right: 16px;
      padding-left: 12px;
      background: #FFFFFF;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      border: 1px solid #E1E5E9;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      .icon-container {
        width: 40px;
        height: 40px;
        background: #D5F2F0;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .immediate {
        background-image: url(./asset/now.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
      }

      .schedule {
        background-image: url(./asset/schedule.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
      }
    
      &.selected {
        border: 2px solid #22998F;
      }

      .sublabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
      }
    }

    .form {
      margin: 0px 24px;
      margin-bottom: 40px;
    }
  
    .form {
      .label {
        margin-bottom: 6px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.25px;
        text-align: left;
      }
    
    }

    .form >.date-container {
      >.date {
        input {
          height: 48px;
        }
      }
    }
    
    .form >.time-container { 
      display: flex;
      margin-top: 24px;
      justify-content: space-between;
      align-items: flex-end;

      > div {
        flex-grow: 1;
        margin-right: 16px;
      }

      :last-child {
        margin-right: 0px;
      }

      >.time {
        position: relative;
        cursor: pointer;
        white-space: pre;
      }
    }

    .sendnow-content {
      margin: 0px 24px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 40px;
  
      span {
        font-weight: 700;
      }

      .info {
        margin-top: 42px;
      }
    }
  
    .action-wrapper {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #e1e5e9;

      .action-button {
        margin: 16px 24px 24px 0;

        &.submit:enabled {
          background-color: #22998f;
        }
      }
    }

    .error-msg {
      color: #e10404;
      font-size: 14px;
      margin-top: 2px;
    }

    .error {
      input {
        border: 1px solid #e10404;
      }
    }

    .warning {
      color: #000;
      font-weight: 400;
    }
}

.schedule-modal {
  max-width: 562px;
}
