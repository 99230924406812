.eg-date-filter {
  .item {
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }
  }
}

