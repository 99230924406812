@import '../../sass/colors';

.container {
  background-color: $eg-white;
  padding: 65px;
  width: 100%;
  height: 100%;

  .resetPassWrapper {
    width: 520px;
    margin: 0 auto;
  }

  .formWrapper {
    margin-top: 30px;

    .textField {
      margin-top: 30px;
      label {
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 0.5px;
      }
    }

    .buttonContainer {
      .submitBtn {
        margin-top: 30px;
      }
      .backBtn {
        background-color: $eg-white;
        color: $eg-light-green;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }

}
