@import '../../../sass/colors';

.eg-number-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: $eg-white;
  border-right: 1px solid $eg-body-gray;
  border-top-left-radius: 8px;

  >.title {
    min-height: 72px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 24px;
  }

  >.list {
    >.item {
      height: 64px;
      padding-top: 15px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;

      .itemdesc {
        display: block;
        font-size: 12px;
        color: $eg-dark-gray;
        margin-left: 35px;
        font-weight: normal;
      }

      &:hover {
        cursor: pointer;
      }

      &::before {
        content: " ";
        width: 8px;
        height: 8px;
        background-color: #c9c9c9;
        display: inline-block;
        margin-left: 16px;
        margin-right: 10px;
        border-radius: 50%;
      }

      &.active {
        &::before {
          background-color: $eg-green;
        }
      }

      &.inactive {
        &::before {
          background-color: $eg-red;
        }
      }

      &.selected {
        background-color: $eg-light-gray2;
        border-left: 3px solid $eg-green;
      }
    }

    >.spinner {
      margin-top: 30px;
    }
  }

  .btn-new {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 24px;
    text-transform: uppercase;

    .button {
      padding-left: 20px;
      padding-right: 0;
      padding-top: 11px;
      background-color: transparent;
      border: 1px solid $eg-green;
      color: $eg-dark-aqua;
      font-size: 14px;
      background-image: url(./assets/add.svg);
      background-repeat: no-repeat;
      background-position: 16px center;
      height: 40px;
    }
  }
}
