@import '../../../../../sass/colors';

.operationDetailsContainer {
  padding: 24px;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: $eg-dark-gray;
    margin-top: 10px;
  }

  .formContainer {
    margin-top: 42px;

    .formWrapper {
      display: flex;

      .leftSection {
        text-transform: uppercase;
        width: 150px;
        font-size: 12px;
        font-weight: 600;
        color: $eg-dark-gray;
        padding-top: 5px;
      }
    }
  }

  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 55px;

    > button {
      margin-right: 8px;
      height: 40px;
      padding-top: 12px;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $eg-light-gray;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
