@import '../../../sass/colors';
@import '../../../sass/mixins.scss';

.eg-channel-list {
  margin-top: 64px;
  @include breakpoint-tablet {
    margin-top: 24px;
  }

  >.column-names {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    color: $eg-dark-gray;
    font-size: 12px;
    font-weight: bold;

    @include breakpoint-tablet {
      flex-direction: column;
    }

    >div {
      >div {
        // customize the arrows container
        &:nth-child(2) {
          height: 22px;
        }
      }

      @include breakpoint-tablet {
        width: 100%;
        padding-left: 24px;
      }
      align-items: center;
      @include breakpoint-tablet {
        font-size: 12px;
      }
    }
  }

  .title {
    width: 25%;
    padding-left: 24px;
    display: flex;
    justify-content: flex-start;
  }

   .spend {
    width: 18.75%;
    display: flex;
    justify-content: flex-start;
    @include breakpoint-tablet {
      padding-left: 20px;
      width: 25%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: $eg-black;
    }
  }
  .income {
    width: 18.75%;
    display: flex;
    justify-content: flex-start;
    @include breakpoint-tablet {
      width: 25%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: $eg-black;
    }
  }
  .avg-ticket {
    width: 18.75%;
    display: flex;
    justify-content: flex-start;
    @include breakpoint-tablet {
      width: 25%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: $eg-black;
    }
  }
  .roi {
    width: 18.75%;
    display: flex;
    justify-content: flex-start;
    @include breakpoint-tablet {
      width: 25%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: $eg-black;
    }
  }

  .mobile-label {
    display: none;
    @include breakpoint-tablet {
      display: flex;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #6B7681;
      margin-top: 14px;
      margin-bottom: 4px;
    }
  }

  .mobile-sort-title {
    display: none;
    @include breakpoint-tablet {
      display: flex;
      padding-left: 24px;
      margin-bottom: 12px;
      font-weight: bold;
    }
  }

  >.totals {
    display: flex;
    align-items: center;
    margin-top: 22px;
    background-color: $eg-white;
    width: 100%;
    height: 60px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 4px solid $eg-medium-black;
    font-weight: bold;
    font-size: 14px;
    @include breakpoint-tablet {
      flex-wrap: wrap;
      height: fit-content;
      padding: 16px 0px;
    }


    >div {
       @include breakpoint-tablet {
        font-size: 12px;
      }
    }

    >.title {
      padding-left: 26px;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      @include breakpoint-tablet {
        width: 100%;
      }
    }
  }

}
