@import '../../../../sass/colors.scss';

.eg-segment-option{
  padding: 20px 24px;
  display: flex;
  align-items: center;

  .logo-container {
    width: 40px;
    height: 40px;
    background: #F0F3F7;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    >.upload{
      background-image: url(../../asset/upload.svg);
      background-size: cover;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    >.dynamic{
      background-image: url(../../asset/dynamic.svg);
      background-size: cover;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  >.detail {
    flex-grow: 1;
  }

  >.detail >.name {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    color: $eg-black;
  }
  >.detail >.info {
    max-width: 456px;
    padding-right: 8px;
  }
  >.create-btn {
    border: 1px solid $eg-aqua;
    box-sizing: border-box;
    border-radius: 4px;
    background: $eg-white;
    color: $eg-aqua;
  }
}
