@import '../../../../../../sass/colors';

.container {
  display: flex;
  align-items: center;

  .closedCheck {
    display: flex;
    margin-left: 10px;
    cursor: pointer;

    &.closedDisabled {
      color: $eg-dark-gray;
      cursor: not-allowed;
    }

    .checkbox {
      margin-right: 5px;
      margin-top: 4px;
    }
  }
}
