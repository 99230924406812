@import '../../../../sass/colors';

.eg-attentive-connect {
  padding: 80px 64px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >.logo {
    position: relative;
    height: 72px;
    width: 72px;
    background-image: url(../assets/attentive.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    >.check {
      position: absolute;
      bottom: -12px;
      right: -12px;
      width: 32px;
      height: 32px;
      background: $eg-green;
      border-radius: 50%;
      border: 4px solid white;
      background-image: url(../../assets/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }
  }

  >.info {
    .description {
      margin-top: 32px;
      list-style: none;
      font-family: sofia-pro, sans-serif;
      font-size: 16px;
      line-height: 1.625;
      color: $eg-black;
      padding: 0;
      display: grid;
      grid-gap: 16px;

      >li {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 24px;
        align-items: center;
      }

      .list-icon {
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &.icon-sync {
          background-image: url('../../assets/icon-sync.png');
          background-size: cover;
          border-radius: 50%;
        }
      }
    }

    .action {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      .connect-btn {
        width: 100%;
        background-color: #0250f6;
        font-family: sofia-pro, sans-serif;
        text-transform: uppercase;
      }
    }
  }

  .info-title {
    margin-top: 32px;
    font-family: sofia-pro, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.333;
    letter-spacing: normal;
    color: $eg-black;
  }
}
