@import '../../../sass/colors';
@import '../../../sass/mixins';

.container {
  font-family: sofia-pro, sans-serif;

  .listItem {
    display: flex;
    background-color: #fff;
    margin-bottom: 5px;
    border: 1px solid #f0f3f7;
    padding-left: 30px;

    &:hover {
      background-color: $eg-pale-gray;
      cursor: pointer;
    }

    .address {
      width: 100%;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
  }
  
  .orderStatus {
    text-transform: capitalize;
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &.completed, &.all {
      &:before {
        background-color: $eg-green;
      }
    }

    &.pending {
      &:before {
        background-color: $eg-yellow;
      }
    }

    &.cancelled {
      &:before {
        background-color: $eg-gray;
      }
    }
  }

  .mobileWrapper{
    display: none;
  }

  // responsiveness
  @include breakpoint-mobile {
    .desktopWrapper{
      display: none;
    }

    .mobileWrapper {
      display: flex;
      flex-direction: column;

      .mobileListItem {
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        border: solid 1px $eg-pale-gray;
        background-color: $eg-white;
        margin-bottom: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $eg-black;
        .upperRow {
          display: flex;
          justify-content: space-between;
          padding: 12px 16px;
          padding-bottom: 8px;
          border-bottom: solid 1px $eg-pale-gray;

          .orderNumber {
            font-size: 12px;
          }
        }

        .lowerRow {
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;
          .logoWrapper {
            width: 32px;
            height: 32px;
            border-radius: 3px;
            background-color: $eg-pale-gray;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;

            .invoiceLogo {
              width: 24px;
              height: 24px;
              background-image: url('../assets/invoice.svg');
              object-fit: contain;
              opacity: 0.3;
            }
          }

          .eventDate {
            font-size: 14px;
          }
          .customerName {
            font-size: 12px;
          }
          .orderTotal {
            font-weight: 600;
          }
        }
      }
    }
  }

}

.row {
  display: flex;
}
