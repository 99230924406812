@import '../../sass/mixins';

.ModalContainer{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(22, 37, 51, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.Modal{
    width: 597px;
    padding: 45px;
    border-radius: 0px 24px 0px 24px;
    box-shadow: 0 4px 16px 0 rgba(44, 44, 44, 0.15);
    border: solid 0.5px #f0f3f7;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.closelImage{
    float: right;
    &:hover{
      cursor: pointer;
    }
  }


// responsiveness
@include breakpoint-mobile {
  .Modal {
    width: 90%;
  }
}
