@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins';

.eg-report-item {
  display: flex;
  align-items: center;
  height: 92px;
  border-bottom: 1px dashed #E1E5E9;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: $eg-light-gray;
  }

  >.name-container {
    width: 33%;
    display: flex;
    align-items: center;
    padding-left: 16px;

    >.icon-container {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background: $eg-pale-gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url(../../../../asset/file-icon.svg);
        background-size: cover;
        width: 16px;
        height: 20px;
        object-fit: contain;
      }
    }

    >.name {
      text-decoration: underline;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      color: #162525;
    }
  }

  >.descri {
    width: 33%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #162525;
  }

  >.date {
    width: 33%;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: #162525;
  }
}
