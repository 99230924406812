@import '../../../../../sass/colors.scss';

.container {
  .modal {
    min-height: 100%;
    width: 100%;
    min-width: fit-content;
    margin: 0px;
    border: 0px;
    background-color: rgba(22, 37, 51, 0.99);
    align-self: center;

    .closeStyle {
      width: 40px;
      height: 40px;
      color: $eg-white;
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 32px;
      left: 95%;
      opacity: 1;
    }
  }
}

