@import '../../sass/colors';

.container {
  display: flex;
  border-radius: 2px;
  width: 235px;
  height: 48px;
  cursor: pointer;

  &.primary {
    border: 1px solid $eg-dark-blue;

    .label {
      background-color: $eg-dark-blue;
    }
  }

  &.secondary {
    border: 1px solid #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;

    .label {
      color: $eg-medium-gray;
    }
  }

  .logo {
    width: 46px;
    height: 46px;
    background-image: url(./assets/google-logo.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .label {
    padding-top: 11px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }

  &.disabled {
    border: 1px solid rgba(0, 0, 0, 0.08);
    cursor: auto;

    .logo {
      background-image: url(./assets/google-logo-disabled.svg);
      background-color: rgba(0, 0, 0, 0.08);
    }

    .label {
      background-color: rgba(0, 0, 0, 0.08);
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
