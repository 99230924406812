@import '../../../sass/mixins.scss';

.eg-time-options {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 40px;
  left: -350px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: #FFFFFF;
  border: 0.5px solid #F0F3F7;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(44, 44, 44, 0.15);
  border-radius: 6px;
  z-index: 5;
  cursor: default;

  >.mobile-header {
    display: none;
    @include breakpoint-mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 22px 28px;
      border-bottom: 1px solid #E1E5E9;

      >.title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #162533;
      }

      .close {
        color: black;
      }
    }
  }

  @include breakpoint-mobile {
    flex-direction: column;
    position: absolute;
    top: 16px;
    left: 0px;
    z-index: 100;
  }

  >.left-part {
    width: 152px;
    height: 100%;
    padding: 12px 0px;
    @include breakpoint-mobile {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    >.time-option {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
      text-align: center;
      color: #162533;

      @include breakpoint-mobile {
        width: 50%;
      }

      &.selected {
        background: #D5F2F0;
        font-weight: bold;
      }
    }
  }

  >.right-part {
    padding: 24px 0px;
    min-width: 330px;
    border-left: 1px solid #E1E5E9;
    @include breakpoint-mobile {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      align-items: center;
    }
    .calendar-container {
      box-shadow: none;
      padding: 0px;
      font-family: Lato, sans-serif;
    }
  }
}
