@import '../../../sass/colors';

.eg-dynamic-segments-predicate-group {
  .condition {
    width: 210px;
  }

  .predicate-item {
    position: relative;

    .close {
      position: absolute;
      top: 30px;
      right: 5px;

      &:first-child {
        top: -30px;
      }
    }
  }

  .predicate-container {
    display: flex;
    align-items: center;
  }

  .or-border {
    display: flex;
    margin-top: 12px;
    margin-bottom: 30px;
    width: 100%;
    align-items: center;

    >.box {
      height: 20px;
      width: 35px;
      border-radius: 8px;
      background-color: #D5F2F0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
    }

    >.border {
      flex-grow: 1;
      height: 1px;
      border-bottom: 1px solid #E1E5E9;
    }
  }

  .row {
    display: flex;
    background-color: $eg-fade;
    padding: 16px;
    border-radius: 4px;
    margin-top: 12px;
    align-items: center;
    padding-bottom: 0px;
    max-width: 90%;
    >.cell {
      color: $eg-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      margin-right: 24px;
    }
  }

  .predicate-container .or-add {
    width: 62px;
    height: 38px;
    background: #2BBFB3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #ffffff;
    margin-left: 12px;
    cursor: pointer;

    &::before {
      content: ' ';
      width: 24px;
      height: 24px;
      background-image:  url('../asset/add.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 7px;
      display: block;
    }

  }

  .text-node {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}
