@import '../../sass/colors';

.orderEvents {
  background-color: $eg-green;
  border-color: $eg-green;
}

.completed {
  background-color: $eg-green;
  border-color: $eg-green;
}

.pending {
  background-color: $eg-yellow;
  border-color: $eg-yellow;
  color: $eg-black;
}

.cancelled {
  background-color: $eg-light-gray;
  border-color: $eg-light-gray;
  color: $eg-black;
}
