@import '../../../sass/colors.scss';
@import '../../../sass/mixins';

.eg-campaign-list {
  margin-top: 24px;
  min-height: 254px;
  border-radius: 4px;

  .loader {
    padding-top: 120px;
  }

  @include breakpoint-tablet {
    width: 100%;
  }

  .total{
    color: #030A3F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  &>.title {
    margin: auto;
    width: fit-content;
    padding-top: 100px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    color: $eg-black;
  }

  &>.no-result {
    margin: auto;
    width: fit-content;
    padding-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-gray;
  }

  &>.table {
    margin-right: 8px;
    background-color: $eg-white;
  }


  &>.table::-webkit-scrollbar {
    width: 3px;
  }

  &>.table::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
  }

  &>.table >.column {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    height: 62px;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: $eg-medium-black;
    border-bottom: 1px solid #E1E5E9;
  }

  &>.table >.column >.name {
    width: 40%;
    padding-right: 16px;
    @include breakpoint-desktop-small {
      width: 40%;
    }
  }

  &>.table >.column >.status {
    width: 20%;
    @include breakpoint-desktop-small {
      width: 20%;
    }
  }

  &>.table >.column >.delivered {
    width: 20%;
  }

  &>.table >.column >.clicked {
    width: 20%;
  }
}

