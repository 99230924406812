.eg-steps-widget {
  display: flex;

  .step-item {
    position: relative;
    display: flex;
    font-size: 14px;

    &.prev {
      cursor: pointer;
    }

    >.step-symbol {
      border: 1px solid #a4aeb6;
      color: #a4aeb6;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      font-size: 14px;
      font-weight: 700;
      padding-left: 7px;
      padding-top: 2px;
      margin-right: 10px;

      &.prev {
        position: relative;
        padding-left: 4px;
        padding-top: 6px;
        background: #162533;

        .prev-icon {
          width: 24px;
          height: 24px;
          background-image: url(./assets/check.svg);
          background-size: 14px;
          background-repeat: no-repeat;
        }
      }
    }

    >.step-label {
      padding-top: 2px;
      font-weight: 700;
    }

    >.line {
      width: 30px;
      height: 50%;
      border-bottom: 1px solid #a4aeb6;
      margin: 0 10px;
    }

    &:last-child {
      >.line {
        border-bottom: none;
      }
    }

    &.selected {
      >.step-symbol {
        background-color: #22998f;
        border: 1px solid #22998f;
        color: #fff;
      }
    }
  }
}
