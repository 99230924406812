.eg-custom-tooltip {
  padding: 8px;
  width: 113px;
  background: #FFFFFF;
  border: 1px solid #E1E5E9;
  border-radius: 4px;

  >.top-row {
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    color: #3AB5AB;
    padding-bottom: 12px;
    border-bottom: 0.5px solid #C1C9D1;
  }
}