.eg-confirmation-modal {
  max-width: 380px;

  >.modal-content {
    margin: 24px 70px 24px 24px;
    padding: 0;

    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }

    .text {
      margin-top: 12px;
    }
  }

  >.confirmation-actions {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e1e5e9;
    padding: 0 24px;

    >.action-button {
      margin: 16px 0;
      width: 47%;

      &.submit:enabled {
        background-color: #22998f;
      }
    }
  }
}
