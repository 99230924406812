@import '../../sass/colors';
@import '../../sass/mixins';

.eg-reports {
  .content {
    position: relative;
    padding: 48px 80px;
    align-items: flex-start;

    >.top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin: 0;
      }
    }
  }
}
