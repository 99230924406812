@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.container {
  .header {
    display: flex;
    border-bottom: 1px solid $eg-light-gray;
    padding-bottom: 15px;

    > div {
      color: $eg-gray;
      text-transform: uppercase;
      font-size: 11px;
    }
  }

  .body {
    .itemRow {
      display: flex;
      border-bottom: 1px solid $eg-light-gray;
      padding-top: 15px;
      padding-bottom: 15px;

      > div {
        span {
          display: block;
          font-size: 12px;
        }
      }
    }
  }

  .qtyField {
    text-align: left;
    width: 70px;
  }

  .itemField {
    text-align: left;
    width: 292px;
  }

  .priceField {
    text-align: right;
    width: 108px;
  }

  .summaryWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    .summary {
      width: 200px;

      .summaryRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .summaryLabel {
        }

        .summaryValue {
        }
      }

      .total {
        font-weight: 600;
      }
    }
  }

  @include breakpoint-mobile {
    width: 90%;
    margin: auto;
  }
}
