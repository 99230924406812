@import '../../../../sass/mixins';

.eg-create-journey-design {
  .step-body {
    padding-top: 0;
    display: flex;

    .msg-wrapper {
      padding: 0 48px;
      overflow-y: scroll;
      min-height: 440px;
      height: calc(100vh - 420px);

      @include breakpoint-desktop-small {
        padding: 0 10px;
      }

      >div {
        margin-bottom: 20px;
      }
    }

    .panel {
      padding-top: 40px;
      width: 66%;

      @include breakpoint-desktop-small {
        width: 50%;
      }

      >.title {
        padding-left: 48px;
      }

      >.subtitle {
        padding-left: 48px;
      }
    }

    .preview {
      background-color: #bfece8;
      width: 34%;

      @include breakpoint-desktop-small {
        width: 50%;
      }

      .phone {
        margin: 0 auto;
        position: relative;

        .preview-body {
          overflow-y: scroll;
          height: 700px;
        }

        .msg-time-label {
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          color: #a4aeb6;
          text-align: center;
          margin-top: 15px;
        }

        .preview-test-btn {
          width: 100%;
          height: 52px;
          font-size: 20px;
          color: #34a39a;
          border: 1px solid #34a39a;
          border-radius: 4px;
          padding: 14px;

          &:disabled {
            color: #a4aeb6;
            border-color: #a4aeb6;
            cursor: auto;
          }
        }
      }
    }

    .message-wrapper {
      font-family: 'SF Pro Text';
      width: 300px;
      background-color: #e8e8e8;
      border-radius: 12px;
      padding: 15px;
      margin-left: 12px;
      margin-top: 17px;

      p {
        font-size: 18px;
        font-weight: 500;
        color: #000;
        line-height: 1.4;

        &.link {
          color: #2585c5;
          text-decoration: underline;
          overflow-wrap: anywhere;
        }
      }
    }

    .message-wrapper-img {
      border-radius: 12px;
      margin-left: 12px;
      margin-top: 17px;

      >img {
        border-radius: 12px;
      }
    }
  }
}
