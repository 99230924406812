@import '../../../sass/colors';

.reviewList {
  width: 100%;
  background-color: #fff;
  border: 1px solid $eg-pale-gray;
}

.reviewError {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: $eg-white;
}
