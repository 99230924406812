.eg-chip-input {
  .chip-root {
    width: 100%;
    border: 1px solid #e1e5e9;
    border-radius: 4px;
    padding: 4px 12px;
  }

  input[type=text] {
    border: none;

    &:focus {
      border: none!important;
    }
  }

  .chip-container {
    display: flex;
    align-items: center;
  }

  .chip {
    margin: 0 5px 0 0;
    border-radius: 8px;
    font-size: 16px;
  }

  .delete-icon {
    margin-left: 0;
  }
}
