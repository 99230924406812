@import '../../../../sass/colors.scss';

.eg-campaign-stat {
  display: flex;

  >.stat-item >.stat-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    margin-right: 84px;
    color: $eg-black;
  }

  >.stat-item >.stat-value {
    margin-top: 10px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    color: $eg-black;
  }

  >.stat-item >.stat-percentage {
    margin-top: 2px;
    color: $eg-dark-gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}