@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.container {
  width: 270px;
  border: 1px solid $eg-light-gray;
  margin-left: 40px;
  padding-top: 16px;
  border-radius: 3px;

  .title {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: $eg-black;
  }

  .titleDark {
    padding-left: 16px;
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    color: $eg-black;
  }

  .customerSnippet {
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding-left: 56px;
    margin-top: 20px;
    height: 40px;
    background-image: url(./assets/user.svg);
    background-repeat: no-repeat;
    background-position: center left;

    span {
      display: block;
      letter-spacing: 0.3px;
      font-size: 12px;
      color: $eg-black;
    }
  }

  .sectionTitle {
    padding-left: 16px;
    color: $eg-gray;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 30px;
  }

  .phoneSection {
    margin-top: 20px;
    border-top: 1px solid $eg-light-gray;
  }

  p {
    padding-left: 16px;
    font-size: 14px;
    color: $eg-black;
    font-weight: normal;
  }

  @include breakpoint-mobile {
    width: 90%;
    margin: auto;
  }
}
