@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.container {
  width: 270px;
  border: 1px solid $eg-light-gray;
  margin-top: 16px;
  margin-left: 40px;
  padding-top: 16px;
  border-radius: 3px;

  .title {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: $eg-black;
  }

  .sectionTitle {
    padding-left: 16px;
    color: $eg-gray;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 30px;
  }

  p {
    padding-left: 16px;
    font-size: 14px;
    color: $eg-black;
    font-weight: normal;
  }

  section {
    margin-top: 20px;
    border-top: 1px solid $eg-light-gray;
  }

  @include breakpoint-mobile {
    width: 90%;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 46px;
  }
}
