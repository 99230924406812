@import '../../sass/colors';
@import '../../sass/mixins';

.bannerContainer{
  width: 100%;
  padding: 24px;
  background-color: #34a39a;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.textContent{
  width: 60%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
}

.title{
  font-family: sofia-pro,sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #ffffff;
}

.description{
  margin-top:12px;
  font-family: sofia-pro, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.boldText{
  font-weight: bold;
}

// responsiveness
@include breakpoint-mobile {
  .bannerContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: fit-content;
  }

  .googleSyncButton {
    margin-top: 24px;
  }
}
