@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.eg-customer-list-item {
  display: flex;
  padding: 16px 58px 20px 16px;
  background-color: $eg-white;
  border-top: dashed 1px $eg-light-gray;
  min-height: 96px;
  &:hover {
    background-color: $eg-light-gray;
    cursor: pointer;
  }

  &:first-child {
    border-top: solid 1px $eg-light-gray;
  }

  @include breakpoint-mobile {
    flex-direction: column;
    height: fit-content;
    padding: 16px 16px 24px 16px;
  }

  // list items
  >div {
    width: 33%;
    @include breakpoint-mobile {
      width: 100%;
    }
  }

  .mobile {
    display: none;
    @include breakpoint-mobile {
      display: flex;
      word-wrap: pre;
      padding-left: 54px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.45;
      letter-spacing: 0.5px;
      color: $eg-dark-gray;
      margin-top: 12px;
      margin-bottom: 0px;
    }
  }

  >.user >.avatar {
    width: 32px;
    height: 32px;
    background-image: url(./../assets/avatar.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: $eg-light-gray2;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: -3px;
    @include breakpoint-mobile {
      margin-top: 4px;
    }
  }

  >.user {
    display: flex;
    display: flex;
    justify-content: flex-start;

    >.info > div {
      font-size: 12px;
      line-height: 1.33;
      color: $eg-dark-gray;
      margin-bottom: 4px;
    }

    >.info >:first-child {
      font-size: 14px;
      line-height: 1.5;
      color: $eg-black;
    }
  }

  >.total {
    padding-left: 5%;
    font-size: 14px;
    line-height: 1.5;
    color: $eg-black;
    @include breakpoint-mobile {
      padding-left: 54px;
    }
  }

  >.last-interaction {
    padding-left: 5%;
    display: flex;
    @include breakpoint-mobile {
      padding-left: 54px;
      margin-top: 2px;
    }
  }

  >.last-interaction {
    > div {
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    >.clover {
      background-image: url(../../../../asset/integration-sources/clover.png);
    }

    >.constant_contact {
      background-image: url(../../../../asset/integration-sources/constant-contact.svg);
    }

    >.facebook {
      background-image: url(../../../../asset/integration-sources/facebook.svg);
    }

    >.google {
      background-image: url(../../../../asset/integration-sources/google.png);
    }

    >.loyalty_ivr {
      background-image: url(../../../../asset/integration-sources/loyalty_ivr.svg);
    }

    >.opendining {
      background-image: url(../../../../asset/integration-sources/opendining.png);
    }

    >.voice {
      background-image: url(../../../../asset/integration-sources/phone.svg);
    }

    >.revel {
      background-image: url(../../../../asset/integration-sources/revel.png);
    }

    >.square {
      background-image: url(../../../../asset/integration-sources/square.svg);
    }

    >.toast {
      background-image: url(../../../../asset/integration-sources/toast.png);
    }

    >.text-privy, >.text {
      background-image: url(../../../../asset/integration-sources/popup.svg);
    }

    >.paytronix {
      background-image: url(../../../../asset/integration-sources/paytronix.svg);
    }

    >.twilio {
      background-image: url(../../../../asset/integration-sources/twilio.svg);
    }

    >.zenreach {
      background-image: url(../../../../asset/integration-sources/zenreach.png);
    }

    >.doordash {
      background-image: url(../../../../asset/integration-sources/doordash.png);
    }

    >.olo {
      background-image: url(../../../../asset/integration-sources/olo.svg);
    }

    >.mailchimp {
      background-image: url(../../../../asset/integration-sources/mailchimp.png);
    }
  }

  >.last-interaction >.info {
    margin-left: 16px;
    width: fit-content;
  }

  >.last-interaction >.info >.source {
    font-size: 14px;
    line-height: 1.5;
    color: $eg-black;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  >.last-interaction >.info >.date {
    font-size: 12px;
    line-height: 1.33;
    color: $eg-dark-gray;
  }
}
