@import '../../../sass/colors';

.reviewModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .loader {
    margin-top: 180px;
    margin-bottom: 200px;
  }

  .modalHeader {
    padding: 0 32px;
    border-bottom: 1px solid $eg-light-gray;
    background-color: $eg-fade;
    display: flex;
    align-items: center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 72px;

    .reviewSource {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-left: 0;
      padding-left: 40px;

      .reviewDate {
        font-size: 12px;
        color: $eg-dark-gray;
        margin-left: 10px;
      }
    }

    .reviewLocation {
      width: calc(100% - 120px);
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .modalBody {
    padding: 35px 16px 0 32px;
    margin-bottom: 35px;
    overflow-y: scroll;
    min-height: 300px;
    max-height: calc(100vh - 260px);

    .userInfoWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-right: 32px;
    }

    .user {
      display: flex;
      font-size: 20px;
      font-weight: 600;

      img {
        width: 40px;
        height: 40px;
      }

      .username {
        margin-left: 24px;
      }
    }

    .modalRatingWrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .starsWrapper {
        font-size: 25px;
        letter-spacing: 4px;
      }

      .reviewDate {
        font-size: 12px;
        color: $eg-dark-gray;
        margin-left: 10px;
      }
    }

    .ratingComment {
      margin-top: 15px;
      font-size: 16px;
    }

    a.reviewAction {
      font-size: 12px;
      color: $eg-dark-gray;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }

    .respondWrapper {
      margin-top: 30px;
      margin-bottom: 30px;
      border-left: 3px solid $eg-aqua;
    }
  }

  .modalFooter {
    height: 70px;
    border-top: 1px solid $eg-light-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

  }
}
