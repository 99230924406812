@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-image: linear-gradient(to bottom, rgba(195, 241, 241, 0.55), $eg-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include breakpoint-mobile {
    justify-content: unset;
    width: 100%;
    padding: 24px;
    padding-top: 48px;
  }

  .zsfLogo {
    width: 258px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 56px;
    @include breakpoint-mobile {
      margin-bottom: 36px;
    }
  }

  h2 {
    font-family: Lato, sans-serif;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $eg-black;
  }

  .loginWrapper {
    width: 448px;
    margin: 0 auto;
    border-radius: 8px;
    border: solid 1px #e0e6ef;
    background-color: $eg-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 38px 0px;
  }

  .formContainer {
    margin-top: 36px;
  }

  @include breakpoint-mobile {
    .loginWrapper{
      width: 100%;
    }
    .formContainer {
      width: 100%;
      padding: 16px;
    }
  }
}
