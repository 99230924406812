@import '../../../../../sass/colors';

.container {
  .header {
    padding: 24px;
    border-bottom: 1px solid $eg-light-gray;

    h3 {
      font-size: 24px;
    }
  }

  .body {
    padding: 0 24px 24px;

    .formRow {
      display: flex;
      align-items: center;
      min-height: 50px;
      margin-top: 30px;

      .label {
        width: 130px;
        color: $eg-dark-gray;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .field {
        width: 80%;
      }
    }

    .categoryWrapper {
      display: flex;
      flex: 1 0 calc(50% - 20px);
      flex-wrap: wrap;
      margin-top: 10px;

      .categoryCheck {
        display: flex;
        align-items: center;
        width: 50%;
        margin-top: 16px;

        span {
          margin-left: 10px;
        }
      }
    }

  }

  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    border-top: 1px solid $eg-light-gray;
    padding-top: 15px;

    > button {
      margin-right: 8px;
      height: 40px;
      padding-top: 12px;
    }
  }
}
