@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  background-color: $eg-white;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-image: linear-gradient(to bottom, rgba(195, 241, 241, 0.55), $eg-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include breakpoint-mobile {
    justify-content: unset;
    width: 100%;
    padding: 24px;
    padding-top: 48px;
  }

  .zsfLogo {
    width: 258px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 56px;
    @include breakpoint-mobile {
      margin-bottom: 36px;
    }
  }

  h2 {
    font-family: Lato, sans-serif;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $eg-black;
  }

  p {
    font-family: Lato, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: $eg-gray;
    margin: 16px 0px;
    margin-bottom: 32px;
  }

  .resetPassWrapper {
    width: 448px;
    margin: 0 auto;
    border-radius: 8px;
    border: solid 1px #e0e6ef;
    background-color: $eg-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 36px;
    @include breakpoint-mobile {
      width: 100%;
      padding: 36px 16px;
    }
  }

  .formWrapper {
    width: 384px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    @include breakpoint-mobile {
      width: 100%;
      padding: 16px;
    }


    .textField {
      label {
        font-weight: 600;
        font-size: 11px;
        letter-spacing: 0.5px;
      }
    }

    .buttonContainer {
      width: 100%;
      .submitBtn {
        width: 100%;
        margin-top: 30px;
      }
      .backBtn {
        margin: 0 auto;
        background-color: $eg-white;
        color: $eg-light-green;
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }

}
