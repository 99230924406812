@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins.scss';

.eg-channel-Insight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid #E1E5E9;
  box-sizing: border-box;
  border-radius: 8px;
  width: fit-content;
  margin-bottom: 24px;
  width: 100%;
  @include breakpoint-tablet {
    width: fit-content;
    padding: 20px;
  }

  >.connect-card {
    border: 1px solid #e1e5e9;
  }

  >.title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .insight-cards {
    display: flex;
    @include breakpoint-tablet {
      flex-direction: column;
    }
    >.card {
      min-width: 465px;
      background: #FFFFFF;
      border: 1px solid #E1E5E9;
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 24px;
      padding: 24px;
      @include breakpoint-desktop-small {
        min-width: unset;
        width: 40%;
      }
      @include breakpoint-tablet {
        margin-bottom: 24px;
        margin-right: 0px;
        width: 100%;
      }

      >.positive {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #EAF9F7;
        display: flex;
        justify-content: center;
        align-items: center;

        >.logo {
          height: 20px;
          width: 20px;
          object-fit: contain;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(./asset/positive.svg);
        }
      }


      >.negative {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #FFCFCF;
        display: flex;
        justify-content: center;
        align-items: center;

        >.logo {
          height: 20px;
          width: 20px;
          object-fit: contain;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url(./asset/negative.svg);
        }
      }

      >.card-info {
        margin-top: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: $eg-black;

        >.channel-name {
          text-transform: capitalize;
        }
      }

      >.card-detail {
        margin-top: 8px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: $eg-black;
      }
    }

    >.card:last-child {
      margin-right: 0px;
    }
  }
}
