@import '../../sass/colors.scss';


.file-progress-bar {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 288px;

  &>.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &>.row >.name {
    display: flex;
  }

  &>.row >.name >.file-logo {
    padding-left: 20px;
    background-image: url(../../asset/file.svg);
    background-repeat: no-repeat;
    background-position: left center;
    width: 10px;
    height: auto;
  }

  &>.row >.name >.file {
    font-size: 12px;
    line-height: 1.5;
    color: $eg-black;
    margin: 0px;
    padding: 0px;
    font-weight: 700;
  }

  &>.row >.percent {
    font-size: 12px;
    line-height: 1.5;
    color: $eg-dark-gray;
  }

  &>.percent-bar {
    width: 100%;
    height: 8px;
    border-radius: 4.5px;
    background-color: $eg-light-gray;

    &>div {
      height: 100%;
    }
  }
}
