@import '../../../../sass/colors';
@import '../../../../sass/mixins';

.eg-fivetran-connect {
  padding: 80px 64px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include breakpoint-mobile {
    padding: 60px 24px;
  }

  >.logo {
    position: relative;
    height: 100px;
    width: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    >.check {
      position: absolute;
      bottom: 14px;
      right: -14px;
      width: 32px;
      height: 32px;
      background: $eg-green;
      border-radius: 50%;
      border: 4px solid white;
      background-image: url(../../assets/check.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }
  }

  >.info {
    .description {
      margin-top: 32px;
      list-style: none;
      font-family: sofia-pro, sans-serif;
      font-size: 16px;
      line-height: 1.625;
      color: $eg-black;
      padding: 0;
      display: grid;
      grid-gap: 16px;

      >li {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .list-icon {
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: 16px;

        &.icon-sync {
          background-image: url('../../assets/icon-sync.png');
          background-size: cover;
          border-radius: 50%;
        }
      }

      .list-title {
        width: fit-content;
        max-width: 400px;
      }
    }

    .action {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      min-width: 300px;
      min-height: 48px;

      .connect-btn {
        width: 100%;
        color: $eg-white;
        background-color: $eg-black;
        font-family: sofia-pro, sans-serif;
        text-transform: uppercase;
      }
    }
  }

  .info-title {
    margin-top: 28px;
    font-family: sofia-pro, sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.333;
    letter-spacing: normal;
    color: $eg-black;
  }
}
