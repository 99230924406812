@import '../../sass/colors';

.chkContainer {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #c1c9d1;
  border-radius: 2px;
  background-color: $eg-white;

  &.checked {
    display: flex;
    align-items: center;
    padding-left: 1px;
    background-color: $eg-green;
    border: 2px solid $eg-green;

    i {
      color: $eg-white;
    }
  }
}
