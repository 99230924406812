.eg-loyalty-card {
  border-radius: 14px;
  max-width: 328px;
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.1);

  .card-head {
    width: 100%;
    background-image: url(./assets/bg.svg);
    background-repeat: no-repeat;
    background-position: -30px -30px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom: 8px solid #1ea99d;

    .card-title {
      padding: 20px 24px;
      color: #fff;
      font-size: 40px;
      font-weight: bold;
    }
  }

  .card-body {
    padding: 24px;

    .customer-name {
      font-size: 16px;
      font-weight: 600;
    }

    .customer-phone {
      color: #6b7681;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
