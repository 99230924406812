@import '../../sass/mixins';
@import '../../sass/colors';

.eg-dashboard {
  >.container {
    position: relative;
    padding: 48px 80px;
    align-items: flex-start;
    @include breakpoint-tablet {
      padding: 24px 20px;
      // width: 100%;
    }
  }

  >.container >.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 5%;
    align-items: center;
  }

  >.container >.content {
    margin-top: 26px;
    display: flex;
    width: 100%;

  @include breakpoint-tablet {
    flex-direction: column-reverse;
  }
  }

  >.container >.content >.cards {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    @include breakpoint-tablet {
      flex-wrap: unset;
      flex-direction: column;
      width: 100%;
      margin-bottom: 64px;
    }
  }
}
