@import '../../../sass/colors';

.eg-forwarding {
  display: flex;
  background-color: $eg-white;
  margin: 32px 80px;
  border-radius: 8px;

  .header-number {
    font-size: 16px;
    font-weight: 600;
  }

  >.numbers {
    width: 240px;
    height: 100vh;
    background-color: $eg-light-gray2;
  }

  >.section-wrapper {
    flex-grow: 1;

    >.body {
      margin: 88px auto 0;
      width: 440px;

      >.title {
        font-size: 24px;
        font-weight: 600;
      }

      >.subtitle, .description {
        font-size: 14px;
        color: $eg-dark-gray;
      }

      >.twocols {
        display: flex;

        >.left {
          margin-top: 34px;
          margin-right: 15px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          >.phoneicon {
            width: 32px;
            height: 32px;
            background-image: url(./assets/phone.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-color: $eg-green;
            border-radius: 50%;
          }

          >.vertical-line {
            width: 50%;
            height: 90px;
            border-left: 1px solid #979797;
            border-bottom: 1px solid #979797;
            position: relative;

            >.vertical-indicator {
              position: absolute;
              background-color: $eg-white;
              width: 12px;
              height: 12px;
              bottom: -6px;
              right: -6px;
              display: flex;
              align-items: center;

              >span {
                display: block;
                width: 8px;
                height: 8px;
                background-color: $eg-green;
                border-radius: 50%;
                margin: 0 auto;
              }
            }
          }
        }

        >.form {
          margin-top: 20px;

          >.smartnumber {
            font-size: 20px;
            font-weight: 600;
            margin-top: 2px;
            margin-bottom: 28px;
          }

          >.row {
            margin-bottom: 24px;

            >.label {
              margin-bottom: 10px;
            }

            .phone-input {
              position: relative;
              &:before {
                content: "+1";
                position: absolute;
                left: 16px;
                top: 13px;
                font-size: 16px;
              }

              .input {
                padding-left: 40px;
              }
            }

            >.error {
              color: $eg-red;
              font-size: 14px;
            }
          }

          >.actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            .button {
              margin-left: 20px;

              &.-primary {
                background-color: $eg-green;
              }

              &.-disabled {
                background-color: #75cbc4;
              }
            }
          }
        }
      }
    }
  }

  .modal {
    text-align: center;
    width: 408px;
    >.title {
      background-image: url(./assets/success.png);
      background-repeat: no-repeat;
      background-size: 56px;
      background-position: center top;
      padding-top: 72px;
      margin-top: 65px;
      height: 100px;
      font-size: 20px;
      font-weight: 600;
    }

    >.subtitle {
      font-size: 14px;
      color: #6b7681;
      margin-top: 16px;
      padding: 0 40px;
    }

    >.phone {
      font-size: 24px;
      font-weight: 600;
      margin-top: 26px;
    }

    .button {
      width: 112px;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      margin: 24px auto 56px;

      &.-primary {
        background-color: $eg-green;
      }

      &.-secondary {
        background-color: $eg-light-gray2;
        border: none;
      }

      &.-disabled {
        background-color: #75cbc4;
      }
    }

    .modalclose {
      display: none;
    }
  }
}
