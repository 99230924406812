@import '../../../sass/colors';

.storeDetailsContainer {
  padding: 15px 80px;
  font-size: 14px;
  font-weight: 400;
  min-height: 400px;

  .mainWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;

    .leftPanel {
      width: 27%;
    }

    .rightPanel {
      width: 70%;
    }
  }
}

.modal {
  padding: 0;
}
