@import '../../../sass/colors.scss';
@import '../../../sass/mixins.scss';

.eg-channel-overview {
  margin-top: 32px;
  .spinner {
    margin-top: 180px;
  }

  >.stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    background: #FFFFFF;
    border: 1px solid #E1E5E9;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    min-height: 444px;

    &.notfound {
      min-height: 200px;
      align-items: center;
      padding-top: 64px;

      >.title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: $eg-black;
      }

      >.message {
        color: #6B7681;
        font-size: 14px;
        margin: 10px;
      }
    }

    @include breakpoint-desktop-small {
      width: 100%;
    }
    @include breakpoint-tablet {
      padding: 20px;
    }
  }

  >.stats >.error {
    margin: auto;
  }

  >.notfound >.message {
    margin: auto;
  }

  >.stats >.brief {
    display: flex;
    @include breakpoint-tablet {
      flex-direction: column;
    }

    >.item {
      padding-right: 84px;
      @include breakpoint-tablet {
        margin-bottom: 40px;
        margin-right: 0px;
        margin-top: 0px;
      }
    }

    >.item >.label {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #162533;
    }

    >.item >.value {
      margin-top: 12px;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      color: #162533;
    }
  }

  >.stats >.channel-results {
    display: flex;
    margin-top: 56px;
    width: 100%;
    @include breakpoint-tablet {
      flex-direction: column;
      margin-top: 0px;
    }

    >.card {
      width: 49%;
      background: #FFFFFF;
      border: 1px solid #E1E5E9;
      box-sizing: border-box;
      border-radius: 6px;
      margin-right: 24px;
      padding: 24px;
      @include breakpoint-tablet {
        margin-bottom: 24px;
        width: 100%;
      }

      >.title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: $eg-black;
      }
    }

    >.card:last-child {
      margin-right: 0px;
    }
  }
}
