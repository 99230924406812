@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  display: flex;
  align-items: center;
  width: 220px;
  height: 8px;
  position: relative;
  border-radius: 3px;

  @include breakpoint-desktop-small {
    width: 300px;
  }

  @include breakpoint-tablet {
    width: 400px;
  }

  @include breakpoint-mobile {
    width: 150px;
  }

  .fixedValue {
    color: $eg-black;
    font-size: 12px;
    margin-left: 8px;
  }

  .percentageValue {
    color: $eg-dark-gray;
    font-size: 12px;
    margin-left: 8px;
  }

  .coloredBar {
    padding: 0;
    margin: 0;
    height: 8px;
    background-color: $eg-green;
    border-radius: 3px;
  }
}
