@import '../../../../sass/colors';

.editableSection {
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: $eg-black;
  }

  .editableSectionRow {
    display: flex;
    align-items: center;
    margin-top: 25px;

    .editableItemLabel {
      width: 23%;
      min-width: 152px;
      color: $eg-dark-gray;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .editableItemValue {
      overflow: hidden;
      a {
        max-width: 424px;
        display: block;
        overflow: hidden;
        color: $eg-green;
        text-decoration: none;
        white-space: nowrap;
        font-size: 14px;
        text-overflow: ellipsis;
      }

      .addItemLink {
        color: #c1c9d1;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .mediaRow {
    margin-top: 16px
  }
}

