@import '../../sass/colors';

.statusContainer {
  text-transform: capitalize;
  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &.completed, &.all {
    &:before {
      background-color: $eg-green;
    }
  }

  &.pending {
    &:before {
      background-color: $eg-yellow;
    }
  }

  &.cancelled {
    &:before {
      background-color: $eg-gray;
    }
  }
}

