@import '../../../../../../sass/colors.scss';

.feedback-positive-card {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.logo {
    background-image: url(./asset/baloon.svg);
    background-size: cover;
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: 64px;
  }

  >.title {
    width: 264px;
    height: 64px;
    margin: 14px 55px 16px 56px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: $eg-black;
    text-align: center;
    margin-top: 24px;
  }

  >.info {
    width: 80%;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $eg-dark-gray;
    margin-bottom: 56px;
  }

  .social-link {
    width: 328px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: $eg-white;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-decoration: none;

    &::before {
      content: '';
      background-size: cover;
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 16px;
    }

    &.facebook {
      background-color: #1877f2;
      &::before {
        background-image: url(./asset/facebook.svg);
      }
    }

    &.google {
      background-color: #4285f4;
      &::before {
        background-image: url(./asset/google.png);
      }
    }

    &.yelp {
      background-color: #d20000;
      &::before {
        background-image: url(./asset/yelp.png);
      }
    }
  }
}
