@import '../../../../../sass/colors';
@import '../../../../../sass/mixins';

.eg-last-interaction-filter-content {
  width: 384px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px;
  @include breakpoint-mobile {
    flex-direction: column;
  }

  >.item {
    display: flex;
    align-items: center;
    width: 50%;

    >.label {
      font-size: 14px;
      color: $eg-black;
      background-repeat: no-repeat;
      background-position: 5px center;
      padding-left: 32px;
      cursor: pointer;

      &.square {
        background-image: url(../../../../../asset/integration-sources/square.svg);
        background-size: 16px;
      }

      &.voice {
        background-image: url(../../../../../asset/integration-sources/phone.svg);
        background-size: 16px;
      }

      &.paytronix {
        background-image: url(../../../../../asset/integration-sources/paytronix.svg);
        background-size: 16px;
      }

      &.loyalty_ivr {
        background-image: url(../../../../../asset/integration-sources/loyalty_ivr.svg);
        background-size: 16px;
      }

      &.constant_contact {
        background-image: url(../../../../../asset/integration-sources/constant-contact.svg);
        background-size: 16px;
      }

      &.clover {
        background-image: url(../../../../../asset/integration-sources/clover.png);
        background-size: 16px;
      }

      &.zenreach {
        background-image: url(../../../../../asset/integration-sources/zenreach.png);
        background-size: 16px;
      }

      &.revel {
        background-image: url(../../../../../asset/integration-sources/revel.png);
        background-size: 16px;
      }

      &.doordash {
        background-image: url(../../../../../asset/integration-sources/doordash.png);
        background-size: 16px;
      }

      &.zero_storefront {
        background-image: url(../../../../../asset/zsf-logo.svg);
        background-size: 16px;
      }

      &.toast {
        background-image: url(../../../../../asset/integration-sources/toast.png);
        background-size: 16px;
      }

      &.text {
        background-image: url(../../../../../asset/integration-sources/popup.svg);
        background-size: 18px;
      }

      &.olo {
        background-image: url(../../../../../asset/integration-sources/olo.svg);
        background-size: 16px;
      }
    }
  }
}
