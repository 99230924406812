@import '../../../sass/colors';
@import '../../../sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-right: 24px;
  width: 500px;
  min-height: 264px;
  height: fit-content;
  border-radius: 4px;
  padding-bottom: 16px;
  background-color: $eg-white;
  @include breakpoint-desktop {
    width: 450px;
  }

  @include breakpoint-desktop-small {
    width: 550px;
  }

  @include breakpoint-tablet {
    width: 100%;
    margin-right: 0px;
  }

  .noResult {
    margin: auto;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: $eg-gray;
  }

  &>.title {
    padding: 14px 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: $eg-black;
    border-bottom: solid 1px $eg-pale-gray;
  }

  .percentBarWrapper {
    margin-left: 10px;

    .percentBar {
      width: 330px;
    }
  }


  .sourcesWrapper {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;

    .sourcesRow {
      margin-top: 16px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      .sourceName {
        @include breakpoint-tablet {
          flex-grow: unset;
          min-width: 120px;
        }
      }

      .leftPart {
        display: flex;
        align-items: center;
        @include breakpoint-tablet {
          flex-grow: 1;
        }
      }

      .sourceStarWrapper {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }

  .yellowStar {
    color: $eg-yellowish-orange;
    font-size: 15px;
    margin-left: 5px;
  }
}
