@import '../../../sass/colors.scss';
@import '../../../sass/mixins.scss';

.eg-channels-connect-card {
  width: 100%;
  padding: 46px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $eg-white;
  margin-top: 12px;

  .card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: $eg-black;
    margin-bottom: 8px;
  }

  >.description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 16px;
    color: $eg-dark-gray;
  }

  >.connect-button {
    width: 180px;
    height: 40px;
    background: #3AB5AB;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: center;
  }
}
