@import '../../../sass/mixins';
@import '../../../sass/colors';

.container {

  .optionContainer {
    /* Customize the label (the container) */
    display: flex;
    font-size: 16px;
    padding: 17px 5px;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    &:hover {
      .checkmark {
        background-color: #c6c7c8;
      }
    }

    &.withBorder {
      box-shadow: 0 1px 0 0 $eg-light-gray;
    }
    
    input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;

      // When the radio button is checked, add a blue background and remove border
      &:checked {
        // + Selector looks for the adjacent sibling (the first element without anything in-between).
        + .checkmark {
          background-color: $eg-aqua;
          height: 20px;
          width: 20px;
          border: none;

          &:after {
            display: block;
          }
        }
      }
    }

    /* Create a custom checkbox */
    .checkmark {
      height: 20px;
      width: 20px;
      border: 2px solid #c6c7c8;
      border-radius: 3px;
      flex-shrink: 0;

      /* Style the checkmark/indicator */
      &:after {
        display: none;
        content: ' ';
        position: relative;
        left: 6px;
        top: 1px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.error {
        border-color: $eg-red;
      }
    }

    .label {
      flex-grow: 1;
      margin-left: 25px;
    }
  }
}
