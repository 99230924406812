.eg-total-interactions-filter-content {
  padding: 10px 16px;
  width: 250px;

  .row {
    margin-bottom: 10px;
    .radiobutton {
      margin: 0;

      .content {
        flex-direction: row;
        align-items: center;

        .title {
          font-weight: normal;
          font-size: 14px;
        }
      }

      .selectedLogo, .unSelectedLogo {
        margin-right: 8px;
      }
    }

    .input-wrapper {
      margin-left: 35px;

      >.input {
        input {
          height: 32px;
          font-size: 14px;
        }
      }
    }
  }
}
