@import '../../sass/colors';

.eg-store-detail {
  font-size: 14px;
  font-weight: 400;
  min-height: 400px;
  background-color: #eff3f7;
  min-height: fit-content;

  &>.spinner {
    margin-top: 15%;
  }

  &>.header {
    margin-top: 0.5px;
    padding: 40px 80px 0px 80px;
    background-color: $eg-white;
  }

  &>.header >.back {
    width: fit-content;
    color: $eg-dark-gray;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    margin-left: -8px;
  }

  &>.header >.back >.arrow-container {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &>.arrow {
      width: 24px;
      height: 24px;
      background-image: url('../../asset/arrow-back.svg');
    }
  }

  &>.header >.back:hover {
    .arrow-container{
      border-radius: 20px;
      background-color: $eg-light-gray;
    }
  }

  &>.header >.name {
    height: 40px;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.25;
    color: $eg-black;
    margin-top: 20px;
    margin-bottom: 50px;
    text-transform: capitalize;
  }

  &>.header >.tab {
    border-bottom: none;
    height: unset;
  }

  &>.header >.tab >.item {
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.86;
    color: $eg-dark-gray;
    margin-right: 24px;
  }

  &>.header >.tab >.selected {
    font-size: 14px;
    font-weight: 600;
    color: $eg-black;
    border-bottom: 4px solid $eg-aqua;
  }


}
