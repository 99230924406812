@import '../../sass/colors';

.eg-account-settings {
  >.body {
    width: 615px;
    margin: 48px auto;

    >.card {
      width: 100%;
      background-color: $eg-white;
      border-radius: 8px;
      padding: 28px 0 30px;
      margin-top: 32px;
      border-color: 1px solid $eg-pale-gray;
      height: 640px;

      >.head {
        padding: 0 34px;
        border-bottom: 1px solid $eg-light-gray;

        >.tabs {
          border-bottom: none;
          font-size: 14px;
          font-weight: 600;
          height: 32px;
        }
      }

      >.content {
        padding: 45px 40px;
        height: 100%;
      }
    }
  }

  .switch-modal {
    margin-top: 100px;
    padding: 24px 0;
    width: 400px;

    .content {
      padding: 0 24px;;

      .title {
        color: $eg-black;
        margin: 0 0 16px 0;
        padding: 0;
      }

      .description {
        font-size: 16px;
        font-weight: normal;
        color: $eg-black;
      }
    }

    .actions {
      display: flex;
      border-top: 1px solid $eg-light-gray;
      margin-top: 27px;
      padding: 16px 24px 0;
      justify-content: flex-end;

      >.button {
        margin-left: 8px;
      }
    }

    .switch-modal-close {
      display: none;
    }
  }
}
