@import '../../sass/colors';
@import '../../sass/mixins';

.eg-review-overview {
  padding: 38px 80px;

  @include breakpoint-desktop {
    padding: 38px 40px;
  }
  @include breakpoint-desktop-small {
    padding: 38px 80px;
  }
  @include breakpoint-tablet {
    padding: 70px 16px;
  }
  &> h2 {
    margin-bottom: 28px;
  }

  &>.spinner {
    margin-top: 200px;
  }

  &>.filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px $eg-light-gray;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }

  &>.filter-row >.filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &>div {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &>.filter-row >.filter >.storeFilter {
    height: 40px;
  }

  &>.filter-row >.filter >.clear {
    margin-left: 12px;
    font-size: 12px;
    font-weight: 600;
    color: $eg-dark-gray;
    cursor: pointer;
  }

  &>.overview {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1050px;

    @include breakpoint-desktop {
      max-width: 800px;
    }

    @include breakpoint-tablet {
      flex-direction: column;
    }
  }

  &>.overview >.averageCard {
    min-width: 238px;
    height: 112px;
    padding: 22px 28px;
    margin-right: 24px;

    @include breakpoint-tablet {
      width: 100%;
      margin-right: 0px;
    }

    .averageWrapper {
      display: flex;
  
      &>.averageValue{
        font-size: 40px;
        font-weight: 600;
        margin-top: 10px;
      }

      .starsWrapper {
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  &>.overview >.totalWrapper {
    min-width: 170px;
    height: 112px;
    padding: 22px 22px;
    margin-right: 24px;

    @include breakpoint-desktop {
      max-width: 800px;
      margin-bottom: 10px;
    }

    @include breakpoint-tablet {
      width: 100%;
    }
    .averageValue {
      font-size: 32px;
      font-weight: 600;
      color: $eg-black;
      margin-top: 10px;
    }
  }

  &>.breakDown {
    display: flex;
    @include breakpoint-desktop-small {
      flex-direction: column;
    }
  }
}
