.eventSlot {
  height: 34px;
  box-sizing: border-box;
  padding: 7px 11px;
  margin-bottom: 2px;
  border-radius: 0;
  font-weight: 600;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  .slotTitle {
    display: block;
    font-weight: 300;
  }
}

.calendar {
  background-color: #fff;
}
