@import '../../../../../sass/colors';


.container {
  padding: 24px;

  h3 {
    margin-bottom: 0px;
  }

  p {
    margin-top: 4px;
  }
}

.photoWrapper {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}

.uploaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 137px;
  margin-right: 8px;
  margin-bottom: 12px;
  border: 1px dashed $eg-pastel-aqua;
  border-radius: 4px;
  background-color: $eg-white;

  .label {
    color: $eg-green;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .addLogo {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $eg-aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;

      >div {
        background-image: url(./asset/add.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-color: $eg-green;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.thumbnail {
  width: 152px;
  height: 137px;
  margin-right: 8px;
  margin-bottom: 16px;
}
