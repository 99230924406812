@import '../../../../sass/colors.scss';

.eg-filter-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  >.clear {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: $eg-black;
    margin-left: 8px;
    text-transform: uppercase;
    cursor: pointer;
  }

  >.apply {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.25px;
    color: $eg-dark-aqua;
    margin-right: 16px;
    cursor: pointer;
  }
}
