@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  margin: 32px 75px;
  font-size: 14px;
  font-weight: 400;

  .tabContainer {
    margin-top: 50px;
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .pagination {
    margin-top: 20px;
  }

  .emptyView {
    width: 100%;
    height: 354px;
    border: 1px solid $eg-pale-gray;
    background-color: #fff;
    display: flex;
    align-items: center;
    margin-top: 30px;

    .emptyViewContainer {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin: 0 auto;;

      .emptyViewTitle {
        font-size: 20px;
        display: block;
        font-weight: 600;
        color: $eg-dark-gray;
        text-align: center;
      }

      p {
        display: block;
        color: $eg-dark-gray;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }
    }
  }

  .search {
    width: 330px;
  }

  .spinner {
    margin-top: 40px;
  }

  .searchWrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 20px;
  }

  .leftPart {
    display: flex;
  }

  .viewActions {
    display: flex;

    .btnView {
      width: 40px;
      height: 40px;
      border: 1px solid $eg-light-gray;
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
      cursor: pointer;

      &.active {
        background-color: #fff;
        cursor: auto;
      }

      &.btnCalendar {
        background-image: url(./assets/calendar_icon.svg);
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.btnList {
        background-image: url(./assets/list_icon.svg);
        border-left: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  .googleConnectCard {
    width: 100%;
    background-color: #fff;
    padding: 73px 0 94px;
    border: 1px solid $eg-pale-gray;

    .googleConnectWrapper {
      width: 510px;
      margin: 0 auto;
      text-align: center;

      h4 {
        margin-bottom: 16px;
        margin-top: 0;
      }

      p {
        color: $eg-dark-gray;
      }

      .googleConnectButton {
        margin: 32px auto 0;
      }
    }
  }
}

// responsiveness
@include breakpoint-tablet {
  .container {
    margin: 48px 24px;
    margin-top: 94px;
  }
}

// responsiveness
@include breakpoint-mobile {
  .container {
    .search {
      width: 280px;
      input {
        height: 40px;
      }
    }

    .googleConnectCard {
      
      .googleConnectWrapper{
        width: 90%;
      }
    }

    .listContainer{
      margin-top: 0px;
    }

    .pagination {
      display: flex;
      justify-content: center;
    }
  }
  
  .listHeader {
    display: none
  }
}



.googleConnectError {
  color: #cc3333;
  width: 350px;
  margin: 20px auto;
}
