@import '../../../../sass/colors.scss';

.eg-store-row {
  width: 100%;
  display: flex;
  height: 136px;
  border-bottom: solid 1px $eg-pale-gray;
  padding: 16px;
  text-decoration: none;

  &.clickable {
    &:hover {
      background-color: $eg-light-gray;
      cursor: pointer;
    }
  }

  &>.name-wrapper {
    width: 25%;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: $eg-black;
    display: flex;
  }

  &>.address {
    width: 25%;
    padding-right: 8px;
    color: $eg-black;
    font-size: 14px;
    line-height: 1.5;
  }

  &>.manager {
    width: 25%;
    padding-right: 8px;
    color: $eg-black;
    display: flex;
    flex-direction: column;
  }

  &>.manager >.name {
    font-size: 14px;
    line-height: 1.5;
  }

  &>.manager >.email {
    font-size: 12px;
    line-height: 1.33;
    color: $eg-dark-gray;
  }
}
