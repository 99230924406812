@import '../../../../../sass/colors';
@import '../../../../../sass/mixins';

.eg-source-medium-filter-content {
  width: 384px;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 16px;

  @include breakpoint-mobile {
    flex-direction: column;
  }

  >.item {
    display: flex;
    align-items: center;
    width: 50%;

    >.label {
      font-size: 14px;
      color: $eg-black;
      background-repeat: no-repeat;
      background-position: 5px center;
      // padding-left: 32px; // -> used if we display logos
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
