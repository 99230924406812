@import '../../../../../../sass/colors.scss';

.collageWrapper {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  margin-right: 12px;
  margin-bottom: 12px;

  &>img {
    width: 190px;
    height: 176px;
    cursor: pointer
  }
}

.lastImage {
  position: relative;
  background: rgba(22, 37, 51, 0.7);
  cursor: pointer;

  &>img {
    opacity: 0.2;
  }

  .etcContentText {
    position: absolute;
    top: 40%;
    left: 30%;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: $eg-white;
  }
}
