.eg-logo-field {
  .logo-default {
    background-color: #ffde8b;
    width: 112px;
    height: 112px;
    padding: 14px;
  }

  .frm-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 24px;

    .image {
      width: 112px;
    }

    .btn-update {
      margin-left: 24px;
      padding: 11px;
      width: 104px;
      height: 40px;
      cursor: pointer;
  
      &.loading {
        background-color: #eee;
      }
    }
  }
}
