
.eg-copy-container {
  position: relative;

  >.copy-logo {
    position: absolute;
    fill-opacity: 0.3;
    top: 8px;
    right: 8px;
    width: 18px;
    height: 18px;
    background-image: url('./asset/copy.svg');
    background-repeat: no-repeat;
    cursor: pointer;

    &.copy-gray {
      background-image: url('./asset/copy-gray.svg');
      top: 6px;
      width: 20px;
      height: 20px;
      cursor: unset;
    }
  }
}
