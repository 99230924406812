@import '../../sass/colors';
@import '../../sass/mixins.scss';

.sidebarContainer {
  .container {
    width: 250px;
    height: 100%;
    background-color: #162533;
    color: #fff;

    .title {
      padding: 40px 20px 25px 30px;

      >.logo {
        width: 36px;
        height: 36px
      }

      .restaurantName {
        font-size: 16px;
        font-weight: 600;
        margin-top: 25px;
        background-image: url(./assets/caret-left.svg);
        background-repeat: no-repeat;
        background-position: center right;

        &.singleChain {
          background-image: none;
        }

        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      padding: 28px 0 0 0;
      padding-bottom: 16px;
      border-top: 0.5px solid #35414E;


      .sectionTitle {
        text-transform: uppercase;
        font-size: 12px;
        margin-left: 32px;
        margin-bottom: 20px;
        opacity: 0.5;
      }

      .menuItems {
        margin-top: 0px;

        .menuItem {
          font-size: 14px;
          padding-left: 30px;
          padding-top: 10px;
          box-sizing: border-box;
          height: 40px;
          cursor: pointer;
          color: #fff;
          text-decoration: none;
          opacity: 0.5;
          transition: all 0.5s ease-out;

          &.active {
            background-color: #13202d;
            border-left: 3px solid $eg-green;
            color: $eg-green;
            opacity: 1;
          }
        }

        .itemOrders {
          a {
            color: #fff;
            background-image: url(./assets/icon-order.svg);
            background-position: left -1px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-order-active.svg);
            }
          }
        }

        .dashboard {
          a {
            color: #fff;
            background-image: url(./assets/icon-dashboard.svg);
            background-position: left -1px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-dashboard-active.svg);
              opacity: 1;
            }
          }
        }

        .customers {
          a {
            color: #fff;
            background-image: url(./assets/icon-customers.svg);
            background-position: left -1px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-customers-active.svg);
              opacity: 1;
            }
          }
        }

        .reviews {
          div {
            color: #fff;
            background-image: url(./assets/icon-reviews.svg);
            background-position: left -2px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            div {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-reviews-active.svg);
            }
          }
        }

        .calls {
          a {
            color: #fff;
            background-image: url(./assets/icon-call.svg);
            background-position: left 2px;
            background-repeat: no-repeat;
            background-size: contain;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-call-active.svg);
            }
          }
        }

        .stores {
          a {
            color: #fff;
            background-image: url(./assets/icon-stores.svg);
            background-position: left -1px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
            opacity: 1;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-stores-active.svg);
            }
          }
        }

        .integrations {
          a {
            color: #fff;
            background-image: url(./assets/integration-sync.svg);
            background-repeat: no-repeat;
            background-position: 0px -1px;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/integration-sync-active.svg);
            }
          }
        }

        .channels {
          a {
            color: #fff;
            background-image: url(./assets/icon-channels.svg);
            background-repeat: no-repeat;
            background-position: left 0px;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-channels-active.svg);
              background-position: left 0px;
            }
          }
        }

        .segments {
          a {
            color: #fff;
            background-image: url(./assets/icon-segment.svg);
            background-repeat: no-repeat;
            background-position: left 0;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-segment-active.svg);
              background-position: left 0;
            }
          }
        }

        .campaigns {
          div {
            color: #fff;
            background-image: url(./assets/icon-campaigns.svg);
            background-repeat: no-repeat;
            background-position: left -1px;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            div {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-campaigns-active.svg);
              background-position: left -1px;
            }
          }
        }

        .journeys {
          a {
            color: #fff;
            background-image: url(./assets/icon-journeys.svg);
            background-repeat: no-repeat;
            background-position: left -1px;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-journeys-active.svg);
              background-position: left -1px;
            }
          }
        }

        .report  {
          a {
            color: #fff;
            background-image: url(./assets/report.svg);
            background-repeat: no-repeat;
            background-position: left 1px;
            padding-left: 30px;
            text-decoration: none;
            margin-left: 3px;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/report-active.svg);
              background-position: left 1px;
            }
          }
        }

        .plan {
          a {
            color: #fff;
            background-image: url(./assets/icon-plan.svg);
            background-repeat: no-repeat;
            background-position: left -1px;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-plan-active.svg);
              background-position: left -1px;
            }
          }
        }

        .settings {
          a {
            color: #fff;
            background-image: url(./assets/icon-settings.svg);
            background-position: left -1px;
            background-repeat: no-repeat;
            padding-left: 30px;
            text-decoration: none;
          }

          &.active {
            a {
              color: $eg-light-green;
              opacity: 1;
              background-image: url(./assets/icon-settings-active.svg);
              opacity: 1;
            }
          }
        }
      }

      .reviewOption {
        height: fit-content;
        margin-bottom: 24px;
        width: 180px;
        font-size: 14px;
        line-height: 1.5;
        float: right;

        &>.option {
          border-left: solid 3px rgba(63, 65, 68, 0.56);
          height: 48px;
          padding-left: 13px;
          display: flex;
          align-items: center;
          color: $eg-gray;
          text-decoration: none;
          cursor: pointer;
          transition: all 0.5s ease-out;
        }

        &>.selectedOption {
          border-left: solid 3px #2bbfb3;
          font-weight: 600;
          line-height: 1.5;
          color: $eg-white;
        }
      }
    }
  }

  .burger {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 95;
  }
}

// Responsiveness
@include breakpoint-tablet {
  .sidebarContainer {
    position: absolute;

    .container {
      position: fixed;
      top: 0;
      left: -250px;
      z-index: 100;
      height: 100%;
      transition: left 0.3s ease;
    }

    .burger {
      display: block;
      background-image: url(./assets/burger.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 24px;
      background-color: $eg-black;
      padding: 20px;
      width: 56px;
      height: 56px;
    }

    &.mobileActive {
      width: 100%;
      height: 100%;
      .container {
        left: 0;
      }

      .burger {
        display: none;
      }
    }
  }

  .background {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $eg-black;
    opacity: 0.6;
    z-index: 90;
  }

  &.mobileActive {
    .background {
      display: block;
    }
  }
}
