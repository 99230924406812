.reviewSource {
  display: flex;
  background-repeat: no-repeat;
  text-transform: capitalize;
  padding-left: 30px;
  padding-top: 2px;

  &.google {
    background-image: url(./assets/google.png);
  }

  &.google_my_business {
    background-image: url(./assets/google.png);
  }

  &.yelp {
    background-image: url(./assets/yelp.png);
  }

  &.tripadvisor {
    background-image: url(./assets/tripadvisor.png);
  }

  &.facebook {
    background-image: url(./assets/facebook.png);
  }

  &.zerostorefront {
    background-image: url(../../../asset/zsf-logo.svg);
  }
}
