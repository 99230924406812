@import '../../../../../sass/colors';
@import '../../../../../sass/mixins';

.container {
  padding: 80px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  position: relative;
  height: 64px;
  width: 71px;
  background-image: url('../assets/bank.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.check{
  position: absolute;
  bottom: -12px;
  right: -12px;
  width: 32px;
  height: 32px;
  background: $eg-green;
  border-radius: 50%;
  border: 4px solid white;
  background-image: url('../assets/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

.infoTitle{
  margin-top: 32px;
  font-family: sofia-pro, sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.333;
  letter-spacing: normal;
  color: $eg-black;
}

.userInfo {
  margin-top: 32px;
  border: 1px solid $eg-light-gray;
  border-radius: 4px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  grid-gap: 24px;
  width: 100%;
}

.userDetails {
  display: grid;
  align-items: center;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $eg-black;

  .cardNumber {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
  }
}

.smallInfo {
  font-size: 12px;
  color: $eg-dark-gray;
}

.actionContainer {
  position: relative;
  display: flex;
  align-items: center;

  .popOverStyle {
    top: 48px;
    left: 0px;
  }
}

.differentBankLink {
  width: 100%;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: $eg-black;
  cursor: pointer;
  height: 48px;
  padding: 0px !important; // because of inline stying in plaid component.
  background-color: $eg-fade !important;
  border: none !important;
}

.dropDownMenu {
  background-image: url(../assets/dots.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $eg-light-gray;
  }

  &:active {
    box-shadow: 1px 1px 3px 0 $eg-light-gray inset;
  }
}

.infoDescription{
  margin-top: 32px;
  list-style: none;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  line-height: 1.625;
  color: $eg-black;
  padding: 0;
  display: grid;
  grid-gap: 16px;
}

.infoDescription > li {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 24px;
  align-items: center;
}

.listIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d8f0ee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.syncWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSync {
  background-image: url('../assets/watch.svg');
}

.iconSecure {
  background-image: url('../assets/icon-secure.png');
}

.boldText{
  font-weight: 600;
}

.plaidLinkButton {
  margin-top: 32px;
  width: 216px;
  height: 48px;
  border-radius: 4px !important;
  background-color: #004f80 !important;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: $eg-white;
  cursor: pointer;
}

.plaidFooter{
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .footerText {
    position: absolute;
    display: flex;
    top: 24px;
  }

  .logoWrapper {
    margin-left: 10px;
    
    .plaidLogo {
      background-image: url('../assets/plaid.svg');
      width: 62px;
      height: 23px;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: contain;
    }
  }
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

@include breakpoint-mobile {
  .plaidFooter{
    justify-content: center;
    margin-bottom: 24px;

    .footerText {
      top: 16px;
    }
  }

  .actionContainer {  
    .popOverStyle {
      left: -170px;
    }
  }
}
