@import '../../sass/colors';

.storeDetailsContainer {
  margin: 60px 75px;
  font-size: 14px;
  font-weight: 400;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  .mainWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    width: 650px;
    min-height: 650px;
    border: 1px solid $eg-light-gray;

    >div {
      width: 100%;
      padding: 24px;
    }

    .spinner {
      margin-top: 45%;
    }
  }
}

.modal {
  padding: 0;
}
