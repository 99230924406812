@import '../../../../../../sass/colors';

.container {
  .itemRow {
    display: flex;
    align-items: center;
    height: 55px;

    .itemLabel {
      width: 125px;
      text-transform: capitalize;
    }
  }

  .inputField {
    input {
      height: 40px;
    }
  }
}
