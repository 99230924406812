@import '../../../sass/colors';
@import '../../../sass/mixins';
@import '../../../sass/main';

.button {
  position: relative;
  padding: 16px 35px;
  height: 48px;
  min-width: 130px;
  border-radius: 3px;
  color: white;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 800;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &.small {
    height: 40px;
    font-size: 14px;
    padding: 13px 35px;
  }

  &.xsmall {
    height: 32px;
    font-size: 14px;
    padding: 9px 15px;
  }

  &.disabled, &.loading {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.loading {
    padding: 10px 35px;
    .spinner {
      @include spinner;
    }
  }

  &.primary {
    background-color: $primary-color;

    &.disabled, &.loading {
      background-color: $btn-disabled-background;
      background-image: none;
    }
  }

  &.primaryAqua {
    background-color: $eg-green;

    &.disabled, &.loading {
      background-color: #8fd3cd;
    }

    &:active {
      background-color: #3da39a;
    }
  }

  &.primaryRed {
    background-color: $eg-red;

    &.disabled, &.loading {
      background-color: #df6d6b;
    }

    &:active {
      background-color: #df312f;
    }
  }
  
  &.secondary {
    background-color: #fff;
    border: 1px solid rgba(193, 201, 209, 0.8);
    font-size: 14px;
    color: $eg-black;
  }

  &.secondaryDark, &.secondaryOrange {

    &.disabled, &.loading {
      opacity: 0.3;
    }

    font-weight: 800;
    color: $eg-dark-gray;
    border: solid 1px $eg-gray;
  }

  &.secondaryOrange {
    color: $eg-orange;
    border-color: $eg-orange;
  }
   
}
