@import '../../../sass/colors';

.eg-update-user-password {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  >.fields {
    >.field {
      width: 100%;
      margin-bottom: 25px;

      >.label {
        font-size: 11px;
        color: $eg-medium-black;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .error {
        input {
          border: 1px solid #e85654;
        }
      }

      .error-msg {
        color: #e85654;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  >.actions {
    display: flex;
    justify-content: flex-end;

    >.button {
      width: 180px;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
}
