@import '../../sass/colors.scss';

.eg-filter-background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.eg-filter-modal {
  width: 384px;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  background-color: $eg-white;
  color: $eg-black;
}
