.ratingFilterContainer {
  .itemWrapper {
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }

    .starsWrapper {
      letter-spacing: 4px;
    }
  }
}
