@import '../../../../../../sass/colors';

.eg-character-counter {
  position: relative;
  font-size: 12px;
  font-weight: 400;
  color: $eg-medium-black;
  display: flex;

  >.help-icon {
    background-image: url('../../../../../../asset/help.svg');
    width: 15px;
    height: 15px;
    background-size: contain;
    margin-left: 8px;
    cursor: pointer;
  }

  >.info-text {
    display: none;
    position:absolute;
    width: 289px;
    bottom: 20px;
    left: 20px;
    background: #162533;
    border-radius: 4px;
    color: $eg-white;
    padding: 8px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  >.help-icon:hover + div {
    display: block;
  }
}
