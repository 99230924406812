@import '../../../sass/colors.scss';

.eg-custom-textarea {
  .textarea {
    border: 1px solid #e1e5e9;
    border-radius: 4px;
    padding: 12px;
    max-width: 515px;
    background-color: #fff;

    &.error {
      border: 1px solid #e10404;
    }

    textarea {
      border: none;
      height: 144px;
      resize: none;
      box-sizing: border-box;
      width: 100%;
      font-family: Lato, sans-serif;
      font-size: 16px;

      &:focus {
        border: 1px solid #22998f;
      }
    }

    >a {
      text-decoration: none;

      >.short-link {
        padding: 4px;
        background-color: $eg-pale-gray;
        width: fit-content;
        height: 26px;
        color: $eg-aqua;
        border-radius: 4px;
        margin-bottom: 16px;
      }
    }

    .utils-wrapper {
      display: flex;
      border-top: 1px solid #e1e5e9;
      padding-top: 10px;

      .shortlink-wrapper {
        display: flex;
        cursor: pointer;
        margin-right: 10px;

        >.shortlink-icon {
          width: 26px;
          height: 26px;
          background-image: url(./assets/link.svg);
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid #c1c9d1;
          border-radius: 4px;
        }

        .shortlink-title {
          font-size: 12px;
          font-weight: 700;
        }

        .shortlink-subtitle {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
