@import '../../../sass/colors.scss';
@import '../../../sass/mixins';

.eg-store-breakdown {
  margin-top: 24px;
  width: 500px;
  min-height: 264px;
  height: fit-content;
  border-radius: 4px;
  padding-bottom: 16px;
  background-color: $eg-white;
  @include breakpoint-desktop {
    width: 450px;
  }

  @include breakpoint-desktop-small {
    width: 550px;
  }

  @include breakpoint-tablet {
    width: 100%;
  }

  &>.no-result {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: $eg-gray;
  }

  &>.title {
    padding: 14px 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: $eg-black;
    border-bottom: solid 1px $eg-pale-gray;
  }

  &>.table {
    padding: 12px 24px;
    height: 216px;
    overflow-y: scroll;
    margin-right: 8px;
  }


  &>.table::-webkit-scrollbar {
    width: 3px;
  }

  &>.table::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
  }

  &>.table >.column-name {
    display: flex;
    font-size: 12px;
    line-height: 1.33;
    color: $eg-dark-gray;
    justify-content: space-between;
  }

  &>.table >.column-name >.store {
    width: 50%;
    @include breakpoint-desktop-small {
      width: 60%;
    }
  }

  &>.table >.column-name >.avg {
    width: 25%;
    @include breakpoint-desktop-small {
      width: 25%;
    }
  }

  &>.table >.column-name >.review {
    width: 17%;
    text-align: right;
    @include breakpoint-desktop-small {
      width: 15%;
    }
  }
}

