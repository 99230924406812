@import '../../sass/colors';
@import '../../sass/mixins';

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(3px);
  overflow-y: auto;
  background-color: rgba(22, 37, 51, 0.75);

  .modal {
    position: relative;
    z-index: 110;
    width: 700px;
    background-color: #fff;
    border: 1px solid $eg-pale-gray;
    border-radius: 8px;
    margin: 50px auto;
    margin-top: 124px;
    margin-bottom: 150px;


    .close {
      position: absolute;
      right: 38px;
      top: 20px;
      font-size: 32px;
      opacity: 0.3;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// responsiveness
@include breakpoint-tablet {
  .modalBackground {
    .modal {
      width: 95%;
      margin-top: 50px;
    }
  }
}
