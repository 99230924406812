.eg-dynamic-segment-predicate-template {
  display: flex;
  flex-wrap: wrap;

  .text-node {
    margin-bottom: 16px;
  }

  .predicate-cell {
    margin-bottom: 16px;
  }
}
