@import '../../sass/colors';

.container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $eg-light-gray;
  height: 40px;

  .tabItem {
    margin: 0 25px 0 0;
    color: $eg-gray;
    cursor: pointer;

    &.selected {
      color: $eg-black;
      height: 100%;
      border-bottom: 3px solid $eg-green;
    }
  }
}
