@import '../../../../sass/colors.scss';

.eg-store-options {
  flex-grow: 1;
  width: 384px;
  max-height: 384px;
  display: flex;
  flex-direction: column;

  &>.search {
    margin: 0px 16px 16px 16px;
    border-radius: 3px;
    background-color: $eg-white;
  }

  &>.content {
    flex-grow: 1;
    color: $eg-black;
    padding-right: 12px;
    overflow: hidden;
    overflow-y: scroll;
  }


  &>.spinner {
    margin-top: 10%;
  }
}
