@import '../../sass/mixins';
@import '../../sass/colors';

.eg-notification {
  position: fixed; /* Stay in place */
  top: 32px;
  right: 56px;
  z-index: 101;
  opacity: 1;

  &.fade {
    -webkit-transition: all 2s ease-out 0s;
    -moz-transition: all 2s ease-out 0s;
    -ms-transition: all 2s ease-out 0s;
    -o-transition: all 2s ease-out 0s;
    transition: all 2s ease-out 0s;
    opacity: 0;
  }

  .notification {
    width: 352px;
    border-radius: 8px;
    padding: 16px 24px 16px 16px;
    display: flex;

    .icon {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      align-self: center;
      background-position: center;
      background-size: contain;
    } 

    .message {
      flex-grow: 1;
      color: white;
      align-self: center;
      font-size: 14px;
      font-weight: 500;
      padding-right: 10px;

      a {
        color: $eg-white;
        text-decoration: none;
        border-bottom: 1px solid $eg-white;
        padding-bottom: 3px;
      }
    }

    .close-btn {
      right: 16px;
      top: 16px;
      font-size: 26px;
      opacity: 0.6;
      display: flex;
      align-items: center;
      color: $eg-white;

      &:hover {
        cursor: pointer;
      }
    }

    &.success {
      background-color: $eg-green;

      .icon {
        background-image: url('./assets/success.svg');
      }
    }

    &.error {
      background-image: linear-gradient(to right, #f46d6c, #f55151);

      .icon {
        background-image: url('./assets/error.svg');
      }
    }

    &.info {
      background-image: linear-gradient(to right, $eg-blue, #1a99dd);

      .icon {
        background-image: url('./assets/info.svg');
      }
    }

    &.warning {
      background-color: #f0b924;

      .icon {
        background-image: url('./assets/warning.svg');
      }
      
      .message {
        color: $eg-white;
      }

      .closeBtn {
        background-image: url('./assets/close-black.svg');
      }
    }

  }

  /***********************
  **    RESPONSIVE     **
  ***********************/

  @include breakpoint-mobile {
    width: 100%;
    display: flex; 
    justify-content: center;

    .notification {
      // 100% minus the padding
      width: 80%;

      .icon {
        width: 32px;
        height: 32px;
      } 

      .message {
        margin: 0 10px;
      }

      .closeBtn {
        width: 24px;
        height: 24px;
      }
    }
  }

}
