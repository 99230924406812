@import '../../../../../../sass/colors.scss';

.eg-negative-feedback {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow-back {
    width: 28px;
    height: 24px;
    background-image: url(../assets/back-arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    left: 20px;
    cursor: pointer;
  }

  >.store-logo {
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 24px;
    margin-top: 64px;
    max-width: 148px;
    height: auto;
  }

  >.title {
    max-width: 350px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $eg-black;
  }

  >.subtitle {
    max-width: 350px;
    height: 96px;
    margin: 9px 39px 46px 24px;
    font-family: Lato;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: $eg-dark-gray;
  }

  >.feedback-input {
    width: 328px;
    height: 160px;
    margin: 24px 23px 16px 24px;
    padding: 16px 16px 120px 16px;
    border-radius: 8px;
    border: solid 1.2px $eg-light-gray;
    background-color: $eg-white;
    font-size: 16px;
    line-height: 1.5;
    color: $eg-gray;
    overflow-y: hidden;
  }


  >.submit {
    width: 328px;
    height: 48px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: #2bbfb3;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $eg-white;
    cursor: pointer;
  }

  >.disabled {
    opacity: 0.48;
    background-color: #55ccc2;
    cursor: unset;
  }
}
