.eg-popover-dropdown {
  position: absolute;
  border-radius: 4px;
  top: 57px;
  right: 30px;
  width: 200px;
  box-shadow: 2px 2px 18px 0 rgba(199, 199, 199, 0.5);
  background: #fff;
  color: #2c2c2c;
  z-index: 10;
}

.eg-menu-items > div {
  border-bottom: 1px solid #dcdedf;
}

.eg-menu-items a {
  color: #2c2c2c!important;
  margin-left: 0!important;
  margin-top: 0!important;
}

@media (max-width: 768px) {
  .eg-popover-dropdown {
    right: 8px;
  }
}

.eg-popover-dropdown.hide {
  display: none;
}

.eg-popover-dropdown:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}

.eg-popover-dropdown:before {
  border-color: rgba(250, 250, 250, 0);
  border-bottom-color: rgba(199, 199, 199, 0.1);
  border-width: 17px;
  margin-left: -17px;
}

.eg-popover-dropdown .eg-menu-title {
  font-size: 14px;
}

.eg-popover-dropdown .eg-menu-items div {
  padding: 20px;
  cursor: pointer;
}

.eg-popover-dropdown .eg-menu-items div:hover {
  background-color: #f5f7f8;
}

.eg-popover-trigger {
  cursor: pointer;
}
