@import '../../../../../../sass/colors';

.eg-loyalty-ivr-container {
  position: relative;
  max-width: 750px;
  height: 700px;
  overflow-y: scroll;
  margin: 0px auto;

  .disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #fff;
    opacity: 0.5;
  }

  .eg-loyalty-ivr {
    max-width: 100%;
    margin: 30px;

    >.fp-logo {
      background-size: cover;
      margin: 0 auto 50px;
      width: 164px;

      &.frank-pepe {
        background-image: url(./assets/frank_pepe.png);
        height: 24px;
      }
    }

    >.title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      line-height: 32px;

      span {
        text-transform: capitalize;
      }
    }

    >.subtitle {
      color: #6b7681;
      font-size: 14px;
      text-align: center;
      margin-top: 16px;
      line-height: 21px;
    }

    >.form {
      width: 100%;
      margin-top: 25px;

      > .email {
        width: 100%;
        height: 48px;
        border: solid 1.2px $eg-light-gray;
        height: 48px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0);
        padding-left: 16px;
        font-size: 16px;
        line-height: 1.63;
        color: $eg-black;

        &::placeholder {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $eg-gray;
        }
      }

      >.error-boundary {
        border: solid 1px #e56060;
      }
    }

    >.card {
      margin-top: 24px;
      margin-bottom: 28px
    }

    >.service-info {
      margin-top: 40px;
      p {
        font-size: 12px;
        color: #a4aeb6;

        span {
          text-transform: capitalize;
        }
      }
    }

    >.join {
      margin-top: 33px;

      >.join-btn {
        border: none;
        width: 100%;
      }
    }
  }
}
