@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.eg-channels-container {
  margin-bottom: 224px;
  @include breakpoint-tablet {
    margin-bottom: 100px;
  }

  >.header {
    width: 100%;
    display: flex;
    padding: 0 80px;
    height: 126px;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    @include breakpoint-tablet {
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 20px;
      h2 {
        font-size: 24px;
      }
    }
    >.filters {
      display: flex;
      @include breakpoint-tablet {
      flex-direction: flex;
      margin-top: 24px;

      >div {
        width: 48%;
      }
     }
    }

    >.filters .store-filter {
      .count {
        color: #ffffff;
      }
      >.button {
        color: $eg-black;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
    }
  }

  >.body {
    position: relative;
    padding: 0px 80px;
    align-items: flex-start;
    @include breakpoint-tablet {
      padding: 0px;
    }

    >.spinner {
      margin-top: 200px;
    }
  }
}
