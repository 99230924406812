@import '../../../../sass/colors.scss';

.eg-create-segment-review {
  color: $eg-black;
  min-height: 400px;
  font-family: Lato;

  .loader {
    display: flex;
    align-items: center;
    height: 300px;
  }
  .step-body {
    padding: 40px 48px 60px;

    .head {
      display: flex;
      justify-content: space-between;

      .edit-button {
        width: 120px;
        height: 32px;
        font-size: 12px;
        padding: 9px 0;
      }
    }
  }

  >.step-body >.segment-details {
    margin-top: 36px;

    >.label {
      margin-top: 24px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.25px;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 12px;
    }

    >.name {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E1E5E9;
    }

    >.description {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 24px;
      border-bottom: 1px solid #E1E5E9;
    }

    .and-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0px;
      padding: 16px 0px;
      color: #6B7681;
      margin: 0;
    }
    .predicate-condition-container {
      padding-left: 24px;
      border-left: 1px solid #a4aeb6;

      .or-label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        padding: 10px 0px;
        margin: 0;
        color: #6B7681;
      }

      >.predicate-conditions {
        display: flex;
        white-space: pre;
        >.predicate-string {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0px;
        }
      }
    }
  }
}
