@import '../../sass/colors';

.editableCardContainer {
  width: 100%;
  position: relative;
  background-color: $eg-white;
  padding: 32px 24px;
  border-radius: 4px;

  .editAction {
    position: absolute;
    right: 32px;
    top: 32px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $eg-dark-aqua;
    cursor: pointer;
  }

  .cardBody {
  }
}
