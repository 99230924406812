@import '../../sass/mixins';
@import '../../sass/colors';


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 100000000;

  .modal {
    background-color: white;
    padding: 40px 40px 24px 40px;
    width: 600px;
    height: 300px;
    height: fit-content;
    display: block;
    border-top-right-radius: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 10px;
    overflow: hidden;

    &>.title {
      color: $eg-black;
      font-size: 24px;
    }

    .text {
      overflow: auto;
      font-weight: normal;
      margin-top: 14px;
      color: $eg-black;
    }

    .buttonsWrapper {
      width: fit-content;
      margin-left: auto;
      margin-top: 50px;
      display: flex;
      text-align: right;

      .leaveButton {
        margin-left: 16px;
        background-color: $eg-red;
      }
    }
  }

  /***********************
  **    RESPONSIVE     **
  ***********************/

  @include breakpoint-mobile {
    .modal {
      width: 80%;

      .buttonsWrapper {
        width: 100%;
        flex-direction: column;

        .leaveButton {
          margin-left: 0;
          margin-top: 16px;
          background-color: $eg-red;
        }
      }
    }
  }

  @include breakpoint-mobile-small {

    .modal {
      padding: 20px;
      border-radius: 0;
      height: 100%;
      width: 100%;
    }
  }


  @include breakpoint-vertical-max(666px) {
    
    .modal {
      height: 100%;
    }
  } 


}
