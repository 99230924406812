@import '../../../sass/colors.scss';

.eg-store-logo {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $eg-yellow;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &> div {
    width: 27px;
    height: 27px;
    background-image: url('./asset/store.svg');
    object-fit: contain;
  }
}

.eg-store-logo.eg-yellow {
  background-color: $eg-yellow;
}
  
.eg-store-logo.eg-aqua {
    background-color: $eg-aqua;
  }
  
.eg-store-logo.eg-blue {
  background-color: $eg-blue;
}
  
.eg-store-logo.eg-medium-orange {
  background-color: $eg-medium-orange;
}
  
.eg-store-logo.eg-light-moss-green {
  background-color: $eg-light-moss-green;
}



