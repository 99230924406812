@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins.scss';

.eg-channel-section {
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  color: $eg-dark-gray;
  box-shadow: 2px 2px 18px 0 rgba(199, 199, 199, 0.5);
  background-color: $eg-white;
  border-radius: 8px;

  &.fade-channel-section {
    opacity: 0.4;
    >.summary {
      cursor: unset;
    }
  }

  .summary {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid $eg-body-gray;
    cursor: pointer;
    color: $eg-black;
    font-weight: 700;
     @include breakpoint-tablet {
        flex-wrap: wrap;
      }

    >div {
      @include breakpoint-tablet {
        font-size: 12px;
      }
    }

    >.title {
      padding-left: 24px;
      font-size: 16px;
      font-weight: bold;
      color: $eg-black;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include breakpoint-tablet {
        width: 100%;
        padding-left: 20px;
      }

      >.type-color {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 12px;
      }
    }
  }

  >.row {
    border-bottom: 1px solid $eg-body-gray;
    color: $eg-black;
    font-weight: 400;
    >.channel:hover {
      >.eg-channel-hover {
        display: flex;
      }
    }

    >.channel {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 56px;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      background-color: $eg-white;
      @include breakpoint-tablet {
        flex-wrap: wrap;
        align-items: flex-start;
        padding-bottom: 12px;
      }

      >div {
         @include breakpoint-tablet {
          font-size: 12px;
        }
      }

      >.title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;
        @include breakpoint-tablet {
          width: 100%;
          padding-left: 20px;
          height: 44px;
        }

        >.arrow {
          width: 16px;
          height: 16px;
          &.has-children {
            background-image: url(./assets/arrow.svg);
            background-repeat: no-repeat;
          }

          &.selected {
            transform: rotate(90deg);
          }
        }

        >.name {
          display: flex;
          align-items: center;
          padding-left: 52px;
          background-size: 24px;
          background-position: 23px 1px;
          font-size: 14px;
          color: $eg-black;
          font-weight: bold;
          height: 24px;
          text-transform: capitalize;
          height: 17px;
          width: 18px;
          object-fit: contain;
          background-repeat: no-repeat;
          background-size: contain;
          @include breakpoint-tablet {
            width: fit-content;
          }

          &.google {
            background-image: url(../../../../asset/integration-sources/google.png);
          }

          &.facebook {
            background-image: url(../../../../asset/integration-sources/facebook.svg);
          }

          &.paytronix {
            background-image: url(../../../../asset/integration-sources/paytronix.svg);
          }

          &.phone {
            background-image: url(../../../../asset/integration-sources/phone.svg);
          }

          &.zenreach {
            background-image: url(../../../../asset/integration-sources/zenreach.png);
          }

          &.doordash {
            background-image: url(../../../../asset/integration-sources/doordash.png);
          }

          &.grubhub {
            background-image: url(../../../../asset/integration-sources/grubhub.png);
          }

          &.toast {
            background-image: url(../../../../asset/integration-sources/toast.png);
          }

          &.constant_contact {
            background-image: url(../../../../asset/integration-sources/constant-contact.svg);
          }

          &.mailchimp {
            background-image: url(../../../../asset/integration-sources/mailchimp.png);
          }

          &.text {
            background-image: url(../../../../asset/integration-sources/sms.svg);
          }

          &.open_table {
            background-image: url(../../../../asset/integration-sources/open_table.png);
          }

          &.square {
            background-image: url(../../../../asset/integration-sources/square.svg);
          }

          &.campaigner {
            background-image: url(../../../../asset/integration-sources/campaigner.png);
          }

          &.revel {
            background-image: url(../../../../asset/integration-sources/revel.png);
          }

          &.clover {
            background-image: url(../../../../asset/integration-sources/clover.png);
          }

          &.voice {
            background-image: url(../../../../asset/integration-sources/phone.svg);
          }


        }
      }
    }

    .subchannels {
      display: flex;

      >.left-edge {
        margin: 16px 0;
      }

      >.items {
        width: 100%;
        @include breakpoint-tablet {
          padding-bottom: 32px;
        }

        >.clickable {
          cursor: pointer;
        }

        .border-top {
          display: flex;
          height: 1px;
          width: 100%;
          border-top: 1px solid #E1E5E9;

          @include breakpoint-tablet {
            margin-left: 20px;
            width: calc(100% - 20px);
          }
        }

        .item {
          min-height: 56px;
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 8px;
          background-color: $eg-white;
          @include breakpoint-tablet {
            flex-wrap: wrap;
            margin: 12px 0px;
          }

          >div {
             @include breakpoint-tablet {
              justify-content: flex-start;
              font-size: 12px;
            }
          }

          >.title {
            width: 25%;
            padding-right: 8px;
            padding-left: 92px;
            padding-bottom: 5px;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            color: $eg-black;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .channel-item-date {
              color: #6b7681;
              font-size: 12px;
              font-weight: 400;
            }

            @include breakpoint-tablet {
              width: 100%;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}
