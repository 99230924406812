@import '../../sass/colors';

.sortingHeaders {
  display: flex;

  .field {
    display: flex;
    justify-content: flex-start;
    color: $eg-dark-gray;
    cursor: pointer;

    .fieldState {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      i {
        height: 7px;
        font-size: 20px;
        color: $eg-gray;
      }

      &.sortAsc {
        i:first-child {
          color: $eg-black;
        }
      }

      &.sortDesc {
        i:last-child {
          color: $eg-black;
        }
      }
    }
  }
}
