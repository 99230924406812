@import '../../../../sass/colors.scss';

.eg-campaign-graph {
    padding: 16px;
    >.top-row {
      display: flex;
      justify-content: space-between;
    }

    >.top-row >.title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      color: $eg-black;
      margin-right: 24px;
    }

    >.top-row >.number-click {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0px;
      >.circle {
        width: 12px;
        height: 12px;
        background: #FFFFFF;
        border: 2px solid #3AB5AB;
        border-radius: 10px;
        margin-right: 10px;
      }

    }

    >.chart {
      margin-top: 28px;

     .recharts-cartesian-axis-tick {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        white-space:pre;
      }
    }
}

.campaign-chart-error {
  display: flex;
  justify-content: center;
  align-items: center;
}