@import '../../../sass/colors.scss';

.eg-store-list {
  min-height: 136px;
  margin-top: 16px;
  width: 100%;
  background-color: $eg-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &>.spinner {
    width: fit-content;
    height: fit-content;
  }
}
