@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  margin-top: 16px;
  margin-left: 80px;
  margin-bottom: 16px;
  @include breakpoint-mobile {
    margin-left: 24px;
  }
}
