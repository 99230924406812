@import '../../sass/colors';
@import '../../sass/mixins';

.eg-integrations-container {
  .body {
    margin: 32px 75px;

    h1 {
      margin-bottom: 30px;
    }
  }

  .body > .integrations {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint-mobile {
      flex-direction: column;
    }
  }

  .body > .integrations > div {
    margin-bottom: 32px;
  }

  .eg-integration-model-content {
    width: fit-content;
  }

}
