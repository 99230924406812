@import '../../../sass/colors';
@import '../../../sass/mixins';

.container {
  width: 270px;
  margin-left: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 3px;
  background-color: $eg-white;

  .title {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: $eg-black;
  }

  .titleDark {
    padding-left: 16px;
    margin-top: 15px;
    font-size: 11px;
    text-transform: uppercase;
    color: $eg-black;
  }

  .sectionTitle {
    padding-left: 16px;
    padding-right: 16px;
    color: $eg-dark-gray;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 15px;
  }

  .itemSection {
    margin-top: 20px;
    border-top: 1px solid $eg-light-gray;
  }

  .sectionContent {
    margin-top: 5px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    color: $eg-black;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 10px;

    .address {
      background-image: url(./assets/address.svg);
      background-repeat: no-repeat;
      background-position: left 4px;
      padding-left: 30px;
      min-height: 40px;
    }
  }

  a {
    color: $eg-green;
    text-decoration: none;
  }

  // responsiveness
  @include breakpoint-mobile {
    width: 100%;
    margin-left: 0px;
  }
}
