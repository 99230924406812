@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins';

.eg-campaign-item {
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 92px;
  border-bottom: 1px dashed #E1E5E9;
  position: relative;

  &:hover {
    background-color: $eg-light-gray;
  }

  &.clickable {
    cursor: pointer;
  }

  >.name {
    width: 40%;
    display: flex;
    align-items: center;
    padding-right: 16px;
    @include breakpoint-desktop-small {
      width: 40%;
    }

    >.icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background: $eg-pale-gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url(../../asset/icon.svg);
        background-size: cover;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    >.details {
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0px;
        color: $eg-black;
      }

      >.date {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: $eg-dark-gray;
      }
    }
  }

  >.type {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
    @include breakpoint-desktop-small {
      width: 20%;
    }

    >.status {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-right: 8px;

      &.delivered {
        background-color: #22998f;
      }

      &.scheduled {
        background-color: #bfece8;
      }

      &.canceled {
        background-color: #ffde8b;
      }

      &.draft {
        background-color: #e1e5e9;
      }
    }
  }

  >.delivered {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
  }

  >.clicked {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;

    >.percentage {
      color: $eg-dark-gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  >.options {
    position: absolute;
    top: 40%;
    right: 24px;
    width: 16px;
  }
}
