@import '../../../../sass/colors.scss';

.eg-bar-result {
   >.no-bars {
    width: 90%;
    padding: 46px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    >.description {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
      color: $eg-dark-gray;
    }

    >.footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    >.footer >.connect-button {
      width: fit-content;
      height: 40px;
      color: #3AB5AB;
      background-color: #ffffff;
      border-radius: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      justify-content: center;
      padding: 0px;
      margin-right: 12px;
    }
  }

  >.result-item {
    display: flex;
    align-items: center;
    margin-top: 24px;

    >.roi {
      width: 52px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
    }

    .bar {
      margin: 0 8px;
      height: 8px;
      border-radius: 3px;
    }

    >.return {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
    }

    .channel-logo {
      height: 18px;
      width: 18px;
      object-fit: contain;
      margin-right: 12px;
      background-repeat: no-repeat;
      background-size: contain;

      &.mailchimp {
        background-image: url('../../../../asset/integration-sources/mailchimp.png')
      }

      &.facebook {
        background-image: url('../../../../asset/integration-sources/facebook.svg');
      }

      &.text {
        background-image: url('../../../../asset/integration-sources/sms.svg');
      }

      &.google {
        background-image: url('../../../../asset/integration-sources/google.png');
      }

      &.open_table {
        background-image: url('../../../../asset/integration-sources/open_table.png');
      }

      &.spotify {
        background-image: url('../../../../asset/integration-sources/spotify.png');
      }

      &.yelp {
        background-image: url('../../../../asset/integration-sources/yelp.png');
      }

      &.unknown {
        background-image: url('../../../../asset/integration-sources/unknown.png');
      }

      &.campaigner {
        background-image: url('../../../../asset/integration-sources/campaigner.png');
      }
    }
  }

}
