.eg-fivetran-integration {

  .mailchimp {
    background-image: url('../assets/mailchimp.png');
    width: 60px;
    height: 56px;
    background-size: contain;
  }

  .facebook_ad_account {
    background-image: url('../assets/facebook.svg');
    width: 60px;
    height: 56px;
    background-size: contain;
  }

  .adwords {
    background-image: url('../assets/google-ads.png');
    width: 60px;
    height: 56px;
    background-size: contain;
  }

  .shopify{
    background-image: url('../assets/shopify.svg');
    width: 60px;
    height: 56px;
    background-size: contain;
  }

  .square {
    background-image: url('../assets/square.svg');
    width: 60px;
    height: 56px;
    background-size: contain;
  }

  .klaviyo {
    background-image: url('../assets/klaviyo.png');
    width: 100px;
    height: 60px;
    background-size: contain;
  }

  .instagram_business {
    background-image: url('../assets/instagram-business.png');
    width: 100px;
    height: 60px;
    background-size: contain;
  }

  .facebook_ads {
    background-image: url('../assets/facebook-ads.png');
    width: 100px;
    height: 60px;
    background-size: contain;
  }

  .google_sheets {
    background-image: url('../assets/google_sheet.png');
    width: 100px;
    height: 60px;
    background-size: contain;
  }

  .light_speed_retail {
    background-image: url('../assets/lightspeed.svg');
    width: 100px;
    height: 60px;
    background-size: contain;
  }

  .eg-fivetran-modal {
    width: fit-content;
  }
}
