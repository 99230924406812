.eg-create-campaign-design {
  .step-body {
    padding-top: 0;
    display: flex;

    .form {
      max-width: 600px;
      height: 440px;
      padding: 0 48px;

      >.row {
        margin-bottom: 24px;
      }

      label {
        margin-bottom: 8px;
        display: block;
      }
    }

    .panel {
      padding-top: 40px;
      width: 50%;

      >.title {
        padding-left: 48px;
      }

      >.subtitle {
        padding-left: 48px;
      }
    }

    .preview {
      background-color: #bfece8;

      .phone {
        margin: 0 auto;
        position: relative;

        .preview-test-btn-wrapper {
          position: absolute;
          bottom: 30px;
          left: 70px;
          right: 70px;
          transform: scale(1.4);

          .preview-test-btn {
            width: 100%;
            height: 40px;
            font-size: 14px;
            color: #34a39a;
            border: 1px solid #34a39a;
            border-radius: 4px;
            padding: 12px;

            &:disabled {
              color: #a4aeb6;
              border-color: #a4aeb6;
              cursor: auto;
            }
          }
        }
      }
    }

    .container {
      margin: 0;
      padding: 0;
      overflow-y: scroll;
      display: block;
      height: 600px;
      position: unset;

      .message-wrapper {
        font-family: 'SF Pro Text';
        width: 300px;
        background-color: #e8e8e8;
        border-radius: 12px;
        padding: 15px;
        margin-left: 12px;
        margin-top: 17px;

        p {
          font-size: 16px;
          font-weight: 500;
          color: #000;
          line-height: 1.4;

          &.link {
            color: #2585c5;
            text-decoration: underline;
            overflow-wrap: anywhere;
          }
        }
      }

      .message-wrapper-img {
        border-radius: 12px;
        margin-left: 12px;
        margin-top: 17px;

        >img {
          border-radius: 12px;
        }
      }
    }
  }

  .url-warning-modal {
    button {
      padding: 16px 0;
    }
  }
}
