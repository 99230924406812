@mixin keyboard-focus {
  .eg-a11y-touch-navigation & {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  .eg-a11y-keyboard-navigation &:focus,
  .eg-a11y-touch-navigation &:active {
    @content;
  }
}
