@import '../../../sass/colors';

.container {
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid $eg-light-gray;
  border-radius: 3px;

  &.active {
    border: 1px solid $eg-green;
  }

  .timeContainer {
    position: relative;
    flex-grow: 2;
    height: 100%;
    width: 100px;
    padding: 0 15px;
  }

  .timeInput {
    text-align: center;
    cursor: pointer;
    height: 100%;
    padding-top: 9px;
  }

  .toLabel {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;
  }

  .timeActive {
    border-bottom: 2px solid $eg-green;
  }
  
  .placeholder {
    color: #c1c9d1;
  }
}

.disabled {
  background-color: $eg-light-gray2;
  cursor: not-allowed;

  .timeInput {
    cursor: not-allowed;
    color: #c1c9d1;
  }
}
