@import '../../sass/colors';

.mainContainer {
  display: flex;
  background-color: $eg-body-gray;
  min-height: 100vh;
  width: 100%;

  .viewWrapper {
    width: 100%;
    background-color: $eg-body-gray;
  }
}
