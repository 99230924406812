@import '../../sass/colors.scss';
@import '../../sass/mixins.scss';

.eg-time-selector {
  position: relative;
  cursor: pointer;
  min-width: 156px;
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1.2px solid #E1E5E9;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #162533;
  @include breakpoint-mobile {
    position: unset;
  }

  &::before {
    margin-right: 12px;
    content: '';
    width: 15px;
    height: 15px;
    background-image: url(../../asset/date.svg);
    background-repeat: no-repeat;
  }

  &::after {
    margin-left: 12px;
    content: '';
    width: 12px;
    height: 7px;
    background-image: url(../../asset/caret-down.svg);
    background-repeat: no-repeat;
  }

  &.selected {
    border: 1.2px solid #3AB5AB;

    &::after {
      transform: rotate(180deg);
    }
  }

  >.right-part {
    flex-grow: 1;
  }
}
