.eg-filter-date {
  .action {
    width: 176px;
    padding-left: 40px;
    background-image: url(./assets/calendar.svg);
    background-repeat: no-repeat;
    background-position: 13px center;

    &.active {
      background-image: url(./assets/calendar-white.svg);
    }
  }

  .item {
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }
  }
}
