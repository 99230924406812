  .mobileLogoContainer{
      margin-top: 8px;
      object-fit: contain;
  }

  .infoTitle{
    margin-top: 24px;
    font-family: sofia-pro,sans-serif;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #162533;
  }

  .infoDescription{
    margin-top: 16px;
    font-family: sofia-pro, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #162533;
      
    .boldText{
    font-weight: bold;
    }
  }

  .gConnectButton{
      margin-top: 30px;
      width: fit-content;
  }
