@import '../../../../sass/colors.scss';

.eg-uploadcsvsegment {
  padding: 24px 0px;
  color: $eg-medium-black;

  >.title {
    padding: 0px 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E1E5E9;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
  }

  >.form {
    padding: 0px 24px;

    >.label {
      margin-top: 24px;
      margin-bottom: 4px;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .file-upload {
      height: 149px;
      width: 100%;
      left: 0px;
      top: 27px;
      border-radius: 4px;
      background: #F4F6F9;
      border: 1.2px solid #E1E5E9;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >label {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0px;
        margin-bottom: 4px;
      }

      >.info {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        color: $eg-gray;
      }

      >.upload {
        margin-top: 12px;
        height: 40px;
        min-width: 124px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      >.progress-bar {
        width: 90%;
      }
    }

      .action-wrapper {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #e1e5e9;

      .action-button {
        // margin: 16px 24px 24px 0;
        margin-top: 16px;
        margin-left: 16px;

        &.submit:enabled {
          background-color: #22998f;
        }
      }
    }

  }
}
