@import '../../../sass/colors';
@import '../../../sass/mixins';


.pac-container {
  margin-top: 9px;
  border: 1px solid $eg-gray;
  border-radius: 6px;
  box-shadow: 0 1px 0 0 #dcdedf;

  .pac-item {
    height: 50px;
    display: flex;
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10pt;
    font-weight: 800;
    line-height: 1;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.5px;
    align-items: center;
    overflow-x: auto;

    .pac-icon {
      flex-shrink: 0;
      width: 16px;
      margin-top: 0;
      margin-bottom: 3px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('./assets/gray-pin.svg')
    }
  }
}
/*
// Google Address default classes.

// pac-container	The visual element containing the list of predictions returned by the Place Autocomplete service. This list appears as a dropdown list below the Autocomplete or SearchBox widget.
// pac-icon	The icon displayed to the left of each item in the list of predictions.
// pac-item	An item in the list of predictions supplied by the Autocomplete or SearchBox widget.
// pac-item:hover	The item when the user hovers their mouse pointer over it.
// pac-item-selected	The item when the user selects it via the keyboard. Note: Selected items will be a member of this class and of the pac-item class.
// pac-item-query	A span inside a pac-item that is the main part of the prediction. For geographic locations, this contains a place name, like 'Sydney', or a street name and number, like '10 King Street'. For text-based searches such as 'pizza in New York', it contains the full text of the query. By default, the pac-item-query is colored black. If there is any additional text in the pac-item, it is outside pac-item-query and inherits its styling from pac-item. It is colored gray by default. The additional text is typically an address.
// pac-matched	The part of the returned prediction that matches the user’s input
*/