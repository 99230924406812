.eg-automation-card {
  max-width: 1140px;
  background-color: #fff;
  margin: 40px auto;
  border-radius: 8px;

  .tabs-container {
    padding-left: 24px;
    text-transform: uppercase;
  }

  .tabs-wrapper {
    padding-top: 20px;
  }
}
