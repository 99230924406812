@import '../../../../../sass/colors';

.linksContainer {
  padding: 24px;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: $eg-dark-gray;
    margin-top: 10px;
  }

  .linksFormContainer {
    .linksFormRow {
      display: flex;

      span {
        font-size: 12px;
        font-weight: 600;
        color: $eg-dark-gray;
        text-transform: uppercase;
        width: 200px;
        margin-top: 20px;
      }
    }
  }

  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 55px;

    > button {
      margin-right: 8px;
      height: 40px;
      padding-top: 12px;
    }
  }

  .inputField {
    input {
      height: 40px;
    }
  }
}
