@import '../../sass/colors.scss';

.eg-import-content {
  width: 100%;
  height: 529px;
  border: solid 0.5px $eg-pale-gray;
  background-color: $eg-white;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(44, 44, 44, 0.15);
  display: flex;
  flex-direction: column;

  &> h3 {
    padding: 24px;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: $eg-black;
    border-bottom: solid 1px $eg-pale-gray;
  }

  &>.progress{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>.title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.33;
      color: $eg-black;
      margin: auto;
      margin-bottom: 36px;
    }
  }
}

