.eg-phone-preview-header-sms {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  height: 78px;
  font-size: 15pt;
  font-weight: bold;
  border-bottom: 1px solid #ddd;

  >.back {
    background-image: url(../assets/back.svg);
    background-size: cover;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 25px;
    left: 26px;
  }

  span {
    width: 100%;
    text-align: center;
  }
}
