@import '../../sass/colors';
@import '../../sass/mixins';

.container {
  display: flex;
  margin-left: 16px;
  color: $eg-dark-gray;

  .columnName {
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
  }
  .columnName:hover{
    cursor: pointer
  }

  .right {
    text-align: right;
  }


  // responsiveness
  @include breakpoint-mobile {
    .mobileWrapper {
      display: none
    }  
  }

}

.ArrowContainer{
 padding-left: 4px;   
 padding-top: 1px;
}
