
.eg-store-integration {
  flex-wrap: wrap;
  padding: 0px 80px;
  padding-bottom: 40px;
  display: flex;

  >div {
    margin-top: 40px;
  }

  .gmail {
    background-image: url('./asset/gmail.svg');
    width: 70px;
    height: 64px;
    object-fit: contain;
  }

  .square {
    background-image: url('./asset/square.svg');
    width: 54px;
    height: 54px;
    object-fit: contain;
  }

  .bank {
    background-image: url('./asset/bank.svg');
    width: 70px;
    height: 64px;
    object-fit: contain;
  }

  .calendar {
    background-image: url('./asset/calendar.svg');
    width: 60px;
    height: 56px;
    object-fit: contain;
  }

  .shopify {
    background-image: url('./asset/shopify.svg');
    width: 60px;
    height: 56px;
    object-fit: contain;
  }

  .facebook {
    background-image: url('./asset/facebook.png');
    width: 60px;
    height: 56px;
    object-fit: contain;
    background-size: cover;
  }

  .constant-contact {
    background-image: url('./asset/constant-contact.svg');
    width: 60px;
    height: 56px;
    object-fit: contain;
    background-size: cover;
  }

  &>.modal-background {
    display: flex;
    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(22, 37, 51, 0.995);
    margin: 0px;

    &>.modal {
      max-width: 608px;
    }
  }
}
