.eg-automation-header {
  background-color: #fff;
  padding: 33px 40px;
  height: 96px;
  box-shadow: 0 0 8px 1px rgba(44,44,44,0.1);
  display: flex;
  justify-content: space-between;

  .section {
    display: flex;
    align-items: center;

    .close {
      background-image: url(../../../../asset/close.svg);
      background-size: cover;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    .title {
      margin-left: 76px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
