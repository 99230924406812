@import '../../../sass/colors.scss';

.eg-store-filter {
  position: relative;
  width: 128px;
  border: solid 1.2px $eg-light-gray;
  background-color: $eg-white;
  margin-right: 8px;
  z-index: 10;

  &>.button {
    width: 128px;
    height: 100%;
    font-size: 14px;
    line-height: 1.5;
    color: $eg-black;  
    border-radius: 4px;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 13px;
    cursor: pointer;
  }

  &>.button >.count {
    padding-bottom: 1px;
    width: 20px;
    height: 20px;
    background-color: #3da39a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }

  &>.button >.logo {
    margin-top: 1px;
    background-image: url('../../../asset/gray_store.svg');
    width: 16px;
    height: 16px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 12px;
  }

  &>.filter {
    position: absolute;
    left: 0px;
    top: 120%;
    padding: 24px 0px;
  }


}

.eg-store-filter.eg-selected {
  background-color: $eg-aqua;

  &>.button {
    color: $eg-white;
  }

  &>.button >.logo {
    background-image: url('../../../asset/store.svg');
  }
}
