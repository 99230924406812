@import '../../../../sass/colors.scss';
@import '../../../../sass/mixins';

.eg-journey-item {
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 92px;
  border-bottom: 1px dashed #E1E5E9;
  width: 100%;

  &:hover {
    background-color: $eg-light-gray;
  }

  >.name {
    width: 40%;
    display: flex;
    align-items: center;
    padding-right: 16px;
    cursor: pointer;

    >.icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      background: $eg-pale-gray;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: '';
        background-image: url(../../asset/icon.svg);
        background-size: cover;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    >.details {
      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0px;
        color: $eg-black;
      }

      >.info {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        color: $eg-dark-gray;
        white-space: pre;
        span {
          text-transform: capitalize;
        }

        &.triggerid {
          display: flex;
          min-width: 220px;

          span {
            display: block;
            width: 155px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  >.status {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
    // @include breakpoint-desktop-small {
    //   width: 40%;
    // }

    >.active{
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #22998F;
      margin-right: 8px;
    }

    >.draft {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #E1E5E9;
    }

    >.inactive {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: #FFDE8B;
    }
  }

  >.options {
    justify-content: flex-end;
    flex-grow: 1;
    display: flex;
    margin-right: 34px;
    width: 24px;
  }

  >.sent {
    width: 20%;
  }

  // >.in_progress {
  //   width: 30%;
  // }

  >.opted_out {
    width: 20%;
  }

   >.sent {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
    // @include breakpoint-desktop-small {
    //   width: 40%;
    // }
  }

   >.in_progress {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
    // @include breakpoint-desktop-small {
    //   width: 40%;
    // }
  }

   >.opted_out {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    color: $eg-black;
    text-transform: capitalize;
    // @include breakpoint-desktop-small {
    //   width: 40%;
    // }
  }
}
