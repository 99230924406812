.opDetailsContainer {
  .sectionRow {
    align-items: flex-start;
  }

  .itemGroup {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      text-transform: capitalize;
      margin-bottom: 5px;
    }

    .dow {
      width: 125px;
    }
  }
}
