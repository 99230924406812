
@import '../../../sass/colors';
@import '../../../sass/mixins';

.addressInputContainer {
  width: 100%;
  padding: 10px 0;

  .label {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: 0.5px;
    color: $eg-black;
    margin-bottom: 9px;
    text-transform: uppercase;
  }

  .iconsInputWrapper { 
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: #fafcfd;
    border: 1px solid #dcdedf;
    border-radius: 3px;
    padding: 0 5px;

    .splitter {
      width: 1px;
      height: 25px;
      margin-left: 12px;
      margin-right: 4px;
      flex-shrink: 0;
      background-color: #c6c7c8;
    }

    &.focus {
      border-color: $eg-aqua;
    }

    &.error {
      border-color: $eg-red;
    }

    .input {
      width: 100%;
      height: 46px;
      padding: 12px;
      background-color: #fafcfd;
      border: none;

      &:disabled {
        color: $eg-gray;
        cursor: not-allowed;
      }
    }

    .icon {
      position: relative;
      right: 0;
      top: 0;
      flex-shrink: 0;
      height: 24px;
      width: 24px;
      z-index: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      
      &.locationIcon {
        margin-left: 8px;
        cursor: pointer;
        background-image: url('./assets/aqua-pin.svg');

        &:hover {
          // background-image: url('./assets/orange-pin-filled.svg');
        }

        &.loading {
          cursor: not-allowed;
          @include spinner;
          margin-left: 8px;

          &:hover {
            @include spinner;
            margin-left: 8px;
          }
        }
      }

      &.clearIcon {
        cursor: pointer;
        margin-left: 5px;
        background-image: url('./assets/close-gray.svg');

        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
    }
  }


  .errorMessage {
    margin-top: 9px;
    color: $eg-red;
  }

  &disabled {
    .label {
      color: #c6c7c8;
    }

    .input {
      background-color: $eg-light-gray;
      border-color: $eg-light-gray;
    }
  }
}
