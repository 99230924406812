@import '../../../../../sass/colors.scss';

.eg-store-option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  border-bottom: solid 1px $eg-pale-gray;
  cursor: pointer;
  padding: 0px 16px;

  &>.logo {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    background-image: none;

    &>div {
      width: 16px;
      height: 16px;
      background-size: cover;
    }
  }

  &>.detail {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 75%;
    color: $eg-black;
  }

  &>.detail >.name {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }

  &>.detail >.address {
    font-size: 12px;
    line-height: 1.33;
    color: $eg-dark-gray;
  }

  &>.checkbox {
    align-self: flex-end;
  }
}

.eg-store-option:hover {
  background-color: $eg-light-gray;
}
