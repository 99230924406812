@import '../../../../../../sass/colors';

.container {
  .itemRow {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .itemInputWrapper {
    margin-left: 20px;
  }

  .dateWrapper {
    width: 107px;

    input {
      cursor: pointer;
    }
  }

  .btnRemove {
    width: 24px;
    height: 24px;
    background-image: url(../assets/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 5px;
    margin-top: 4px;
  }

  .addSpecialSchedule {
    color: $eg-green;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
  }
}
