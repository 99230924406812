@import '../../../../../sass/colors';

.eg-automation-sms {
  display: flex;

  &.hidden {
    display: none;
  }

  >.form-wrapper {
    padding: 40px 73px;
    width: 50%;

    >.title {
      font-size: 24px;
      font-weight: bold;
    }

    >.form {
      margin-top: 20px;

      .label {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: 10px;
        display: block;
      }

      .textarea-wrapper {
        >div {
          padding: 0;
        }

        .textarea {
          height: 100px;
          background-color: #fff;
        }
      }

      .field {
        margin-bottom: 24px;
      }

      .error-msg {
        color: $eg-red;
      }

      .error {
        input {
          border: 1px solid $eg-red;
        }
      }
      
      textarea.error {
        border: 1px solid $eg-red;
      }
    }

    .optimized-opts {
      display: flex;

      .radio {
        margin: 0;

        .selectedLogo, .unSelectedLogo {
          margin: 10px;
        }

        .description {
          font-size: 16px;
          font-weight: 600;
          color: #35414e;
          margin-top: 13px;
        }
      }
    }

    .note {
      font-size: 12px;
      color: #a4aeb6;
      line-height: 1.33;
      margin-top: 8px;
    }
  }

  >.preview {
    background-color: #bfece8;
    border-bottom-right-radius: 8px;
    padding-top: 40px;
    width: 50%;

    >.title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.5px;
    }

    >.phone {
      margin: 40px auto;
    }

    .iphone-font-mini {
      margin-top: 20px;
      font-size: 12px;
      text-align: center;
      color: #666;
    }

    .message-wrapper {
      font-family: 'SF Pro Text';
      width: 300px;
      background-color: #e8e8e8;
      border-radius: 12px;
      padding: 15px;
      margin-left: 12px;
      margin-top: 17px;

      p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        line-height: 1.4;

        &.link {
          color: #2585c5;
          text-decoration: underline;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
