@import '../../../../sass/mixins.scss';

.eg-popup-footer {
  display: flex;
  padding: 0px 40px;
  justify-content: space-between;

  @include breakpoint-mobile {
    width: 100%;
    border-top: 1px solid #E1E5E9;
    border-bottom: 1px solid #E1E5E9;
    padding-top: 12px;
    padding-bottom: 16px;
  }

  :first-child {
    margin-right: 24px;
  }

  label {
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #A4AEB6;
  }

  .date {
    font-family: Lato, sans-serif;
    margin-top: 2px;
    width: 124px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1.2px solid #E1E5E9;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #162533;
    padding-left: 12px;
  }

  .error {
    color: #e85654;
    font-size: 12px;
    margin-top: 2px;
  }

  input::-webkit-calendar-picker-indicator{
    display: none;
  }
}
