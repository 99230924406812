@import '../../sass/mixins';
@import '../../sass/colors';

.eg-customers {
  .container {
    position: relative;
    padding: 48px 80px;
    align-items: flex-start;

    @include breakpoint-desktop {
      padding: 48px 48px;
    }
    @include breakpoint-mobile {
      padding: 48px 24px;
    }

    &>.top-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      @include breakpoint-mobile {
        margin-bottom: 30px;
        width: 100%;
        flex-direction: column;
        >div {
          margin-bottom: 16px;
        }
      }

      .filters {
        display: flex;
        justify-content: space-around;

        >div {
          margin-right: 10px;
        }
      }
    }

    &>.top-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      >.search {
        width: 300px;
        margin-right: 16px;
        @include breakpoint-mobile {
          width: 100%;
          margin-right: 0px;
        }
      }
    }

    &>.total {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 24px;
      flex-grow: 1;
      color: $eg-black;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      @include breakpoint-mobile {
        margin-bottom: 16px;
        margin-top: 0px;
      }
    }
  }
}
