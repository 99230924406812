@import '../../../../../sass/colors';

.linksContainer {
  padding: 24px;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    color: $eg-dark-gray;
    margin-top: 10px;
  }

  .linksFormContainer {
    .linksFormRow {
      display: flex;
      margin-top: 10px;

      span {
        font-size: 12px;
        font-weight: 600;
        color: $eg-dark-gray;
        text-transform: uppercase;
        width: 200px;
      }
    }
  }

  .actionsWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 55px;

    > button {
      margin-right: 8px;
      height: 40px;
      padding-top: 12px;
    }
  }

  .inputField {
    input {
      height: 40px;
    }
  }
}

.leftPart {
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 160px;
}

.logoWrapper {
  margin-right: 8px;
  
  >div {
    height: 24px;
    width: 24px;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
}

.facebook{
  background-image: url('./asset/facebook.jpg');
}
.google{
  background-image: url('./asset/google.jpg');
}
.instagram{
  background-image: url('./asset/instagram.jpg');
}
.yelp{
  background-image: url('./asset/yelp.jpg');
}
.tripadvisor{
  background-image: url('./asset/tripadvisor.jpg');
}
.linkedin{
  background-image: url('./asset/linkedin.svg');
}
.twitter{
  background-image: url('./asset/twitter.svg');
}
